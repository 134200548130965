import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ProdottiVariantiService } from '../prodotti-varianti.service';


@Component({
  selector: 'app-prodotti-varianti',
  templateUrl: './prodotti-varianti.component.html',
  styleUrls: ['./prodotti-varianti.component.scss']
})
export class ProdottiVariantiComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  titoloScheda: string = "Caricamento...";
  backUrl: string;
  idPadre = 0;
  myItem: any;
  $numVarianti: BehaviorSubject<number>;
  mostraFormVarianti: boolean = true;
  variantiCreate: boolean = false;
  pulsanteGestisci: any;
  actNumVarianti: number = 0;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService,
    private prodottiVariantiService: ProdottiVariantiService
  ) {
    this.arrayCampi = {
      'idPadre' : {'id' : 'idPadre', 'type': 'NUM'},
      'numVarianti' : {'id' : 'numVarianti', 'nome': 'Numero varianti', 'items': {1: 1 ,2 : 2}, 'type': 'RAD', 'required': true},
      'titoloVar1' : {'id' : 'titoloVar1', 'nome': 'Titolo variante principale', 'required': true},
      'titoloVar2' : {'id' : 'titoloVar2', 'nome': 'Titolo variante secondaria', 'required': true}
    };
    this.$numVarianti = new BehaviorSubject(0);

    this.pulsanteGestisci = {
      type: 'custom',
      testo: 'Gestisci elementi',
      colorClass: 'info',
      icon: 'palette',
      mainClass: 'btn-sm',
      actionTooltip: 'Gestisci gli elementi di questa variante',
    }
  }


  getData() {
    this.formComponentsService.activateLoader(true);
    this.route.params.subscribe(
      (params) => {
        this.idPadre = params.idPadre;
        this.backUrl = "/prodotti/lastItem/"+this.idPadre;
        this.myForm.controls.idPadre.setValue(this.idPadre);

        this.prodottiVariantiService.getData(this.idPadre)
        .subscribe(
          (data) => {
            if (data.numVarianti != '0') {
              this.mostraFormVarianti = false;
              this.variantiCreate = true;
              this.myItem = data;
              this.myItem.numVarianti = ""+this.myItem.numVarianti;
              this.$numVarianti.next(this.myItem.numVarianti);
              this.myForm.patchValue(this.myItem);

              this.titoloScheda = "Modifica tipologia varianti - '" + data._titoloScheda + "'";
            }
            else {
              this.titoloScheda = "Inserimento tipologia varianti - '" + data._titoloScheda + "'";
            }
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
            this.router.navigate([this.backUrl]);
          });
          this.formComponentsService.activateLoader(false);
    });
  }

  ngOnInit(): void {

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);

    this.myForm.controls['numVarianti'].valueChanges.subscribe(value => {
      if (+value == 2) {
        this.myForm.get('titoloVar2').setValidators([Validators.required]);
      } else {
        this.myForm.get('titoloVar2').clearValidators();
      }
      this.myForm.get('titoloVar2').updateValueAndValidity();
      this.$numVarianti.next(value);
    });

    this.getData();
  }


  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.prodottiVariantiService.sendData(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.myForm.markAsPristine();
        this.getData();
        this.mostraFormVarianti = false;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    if (!this.mostraFormVarianti || !this.variantiCreate) {
      this.componentsService.onCancel(this.backUrl);
    }
    else {
      this.$numVarianti.next(this.actNumVarianti);
      this.mostraFormVarianti = false;
    }
  }

  onBack() {
    if (!this.mostraFormVarianti || !this.variantiCreate) {
      this.componentsService.onBack(this.backUrl);
    }
    else {
      this.mostraFormVarianti = false;
    }

  }

  getNumVarianti(): number {
    return this.$numVarianti.value;
  }

  editVarianti(): void {
    this.mostraFormVarianti = true;
    this.actNumVarianti = this.getNumVarianti();
  }

}
