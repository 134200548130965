import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CodiciScontoService } from '../codici-sconto.service';

@Component({
  selector: 'app-codici-sconto-elenco',
  templateUrl: './codici-sconto-elenco.component.html',
  styleUrls: ['./codici-sconto-elenco.component.scss']
})
export class CodiciScontoElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;
  idPadre = 0;

  constructor(
    private service: CodiciScontoService,
    private route: ActivatedRoute,
  ) {
    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'codice', header: 'Codice',    cell: (element: any) => `${element.codice}` },
          { columnDef: 'ADD_validita', header: 'Validità',    cell: (element: any) => `${element.ADD_validita}` },
        ];
        this.options = {
          linkBase: this.myService.linkBase,
        }
      });
  }

  ngOnInit(): void {
  }

}
