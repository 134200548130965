import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { MovimentoMagazzino } from './movimento-magazzino';

@Injectable({
  providedIn: 'root'
})
export class ProdottiMagazzinoService {
  apiUrl: string;
  idProdotto: number = 0;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + "magazzinoProdotti/";
  }

  setIdProdotto(idProdotto: number): void {
    this.idProdotto = idProdotto;
  }

  getData(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "op/getData?idProdotto=" + this.idProdotto);
  }

  sendData(formValue: any[]) {
    const body = formValue;

    return this.http.put<any>(this.apiUrl , body);
  }

  getElenco(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "?idProdotto=" + this.idProdotto)
  }

  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + 'op/opzioni?idProdotto=' + this.idProdotto)
  }

  deleteItem(id: number) {
    return this.http.delete<any>(this.apiUrl + id)
  }
}
