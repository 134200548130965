import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ProdottiService } from '../prodotti.service';
@Component({
  selector: 'app-prodotti-elenco',
  templateUrl: './prodotti-elenco.component.html',
  styleUrls: ['./prodotti-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ProdottiElencoComponent implements OnInit {
  myService: any;
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  mostraFiltriDedicati: boolean = false;
  arrayCampiRadio: any[any];
  arrayPulsanti: any[any];
  arrayCampi: any[any];
  myForm: FormGroup;
  arrayPadri: any[any];
  arraySottocategorie: any[any];

  constructor(
    private prodottiService: ProdottiService,
    private route: ActivatedRoute,
    private formComponentsService: FormInputService,
    private notificationsService: NotificationsService,
    private mainEngineService: MainEngineService
  ) {

    let that = this;

    this.arrayCampiRadio = {
      'N': '-',
      '1': 'Si',
      '0': 'No',
    };

    this.arrayCampi = {
      'idPadre' : {'id' : 'idPadre', 'nome': 'Sito di appartenenza'},
      'idSottocategoria' : {'id' : 'idSottocategoria', 'nome': 'Sottocategoria'},
      'titolo' : {'id' : 'titolo', 'nome': 'Titolo', 'required': false},
      'editore' : {'id' : 'editore', 'nome': 'Editore', 'required': false},
      'ean' : {'id' : 'ean', 'nome': 'Ean', 'required': false},
      'visibile' : {'id' : 'visibile', 'nome': 'Visibile', 'items': this.arrayCampiRadio, 'type': 'RAD', 'required': true, 'inline': true},
      'hasVariante' : {'id' : 'hasVariante', 'nome': 'Prodotti con variante', 'items': this.arrayCampiRadio, 'type': 'RAD', 'required': true, 'inline': true},
      'hasVariazionePrezzo' : {'id' : 'hasVariazionePrezzo', 'nome': 'Prodotti con variazione di prezzo', 'items': this.arrayCampiRadio, 'type': 'RAD', 'required': true, 'inline': true},
    };

    this.arrayPulsanti = {
      'cerca' : {'type' : 'cerca', 'ML' : true},
      'reset' : {'type' : 'reset'},
    };


    this.prodottiService.getElencoSitiAppartenenza()
    .subscribe(data => {
      this.arrayPadri = data.items;
      that.myForm.patchValue({ 'idPadre': 0 });
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });

    this.prodottiService.getElencoSottocategorie()
    .subscribe(data => {
      this.arraySottocategorie = data.items;
      that.myForm.patchValue({ 'idSottocategoria': 0 });
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
          this.prodottiService.keepBackupRicerca = true;
        }
        else {
          this.prodottiService.keepBackupRicerca = false;
        }

      this.myService = this.prodottiService;

      this.columns = [
        { columnDef: 'codice', header: 'Codice',    cell: (element: any) => `${element.codice}` },
        { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo}` },
        { columnDef: 'editore', header: 'Editore',   cell: (element: any) => `${element.editore}` , hideOnSmall: true },
        { columnDef: 'shop', header: 'Shop',   cell: (element: any) => `${element.shop}` , hideOnSmall: true },
        { columnDef: 'prezzo', header: 'Prezzo',   cell: (element: any) => `${element.prezzo}` },
        { columnDef: 'giacenza', header: 'Giac.',   cell: (element: any) => `${element.giacenza}` },
//        { columnDef: 'varianti', header: 'Varianti',   cell: (element: any) => `${element.varianti}` },
      ];

      this.options = {
        fotogallery: {active: 1}
        ,nascondiPulsanteNuovo: true
        ,filtriDedicati: this.showFiltriDedicati.bind(this)
        ,linkBase: this.prodottiService.linkBase
        ,customButtons: {
          a3_prezzi: {
            type: 'custom',
            testo: 'Prezzi',
            colorClass: 'info',
            icon: 'mdi mdi-currency-eur',
            actionTooltip: 'Gestione variazioni prezzo',
            routerLink: 'prezzi',
            condizione: 'abilitaPrezzi',
            hideText: true,
            ML: true
          },
          a2_creavarianti: {
            type: 'custom',
            testo: 'Crea var.',
            colorClass: 'warning',
            icon: 'mdi mdi-palette',
            actionTooltip: 'Varianti non ancora create',
            routerLink: 'varianti',
            condizione: 'creaVarianti',
            hideText: true,
            ML: true
          },
          a2_varianti: {
            type: 'custom',
            testo: 'Var.',
            colorClass: 'info',
            icon: 'mdi mdi-palette',
            actionTooltip: 'Gestione varianti per prodotti con varianti attive',
            routerLink: 'varianti',
            condizione: 'gestisciVarianti',
            hideText: true,
            ML: true
          },
          a1_magazzino: {
            type: 'custom',
            testo: 'Mag.',
            colorClass: 'info',
            icon: 'bx bxs-store',
            actionTooltip: 'Gestione magazzino (consultazione, carico e scarico)',
            routerLink: 'magazzino',
            condizione: 'abilitaMagazzino',
            hideText: true,
            ML: true
          }
        }
      }
    });
  }


  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.myForm.patchValue({ 'visibile': 'N' });
    this.myForm.patchValue({ 'hasVariante': 'N' });
    this.myForm.patchValue({ 'hasVariazionePrezzo': 'N' });
  }


  showFiltriDedicati() {
    this.mostraFiltriDedicati = true;
    this.myForm.patchValue(this.myService.getParametriRicerca());
  }


  onBack() {
    this.mostraFiltriDedicati = false;
  }

  onSearch() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      this.notificationsService.show('warning', '', 'Compilare tutti i campi obbligatori');
      return false;
    }

    this.myService.setParametriRicerca(this.myForm.getRawValue());
    this.onReset();

    this.mostraFiltriDedicati = false;
  }

  onReset() {
    this.myForm.reset();
  }
}
