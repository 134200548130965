import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { TipologiaConsegna } from '../../../gestioneConsegne/tipologieConsegna/tipologia-consegna';
import { OrdiniService } from '../../ordini.service';

@Component({
  selector: 'app-modal-base-spedizione',
  templateUrl: './modal-base-spedizione.component.html',
  styleUrls: ['./modal-base-spedizione.component.scss']
})
export class ModalBaseSpedizioneComponent implements OnInit {
  @ViewChild('modal') modal: any;
  @Output() onCancelEmitter = new EventEmitter();
  @Output() onSubmitEmitter = new EventEmitter();

  modalReference: any;
  arrayMetodiSpedizione: TipologiaConsegna[];
  arrayCampi: any[any];
  myForm: FormGroup;
  itemSpedito: boolean = false;
  checkedSpedito: boolean = false;
  waitModalContent: boolean = true;
  loading: boolean = false;
  titoloModal: string = "";

  constructor(
    private modalService: NgbModal,
    private service: OrdiniService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private buttonService: ButtonService,
    private formComponentsService: FormInputService
    ) { }

  ngOnInit(): void {
  }

  openModal(idOrdine: number): void {
    if (idOrdine < 0) {
      return;
    }
    this.modalReference = this.modalService.open(this.modal, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});

    this.titoloModal = " - Ordine #" + idOrdine;

    this.service.getElencoMetodiSpedizione(idOrdine)
    .subscribe(data => {
      this.arrayMetodiSpedizione = data.items;

      this.arrayCampi = {
        'metodoSpedizione' : {'id' : 'metodoSpedizione', 'nome': 'Metodo spedizione', 'items': this.arrayMetodiSpedizione, 'type': 'RAD', 'required': true, 'inline': false},
        'spedito' : {'id' : 'spedito', 'nome': 'Spedito', 'type': 'BOL'},
        'dataSpedizione' : {'id' : 'dataSpedizione', 'nome': 'Data spedizione', 'type': 'DTA', 'required': true},
        'trackingSpedizione' : {'id' : 'trackingSpedizione', 'nome': 'Url tracking spedizione', 'type': 'TXT'},
        'inviaEmail' : {'id' : 'inviaEmail', 'nome': 'Invia email di notifica', 'type': 'BOL'},
        'noteSpedizione' : {'id' : 'noteSpedizione', 'nome': 'Note ad uso interno', 'type': 'TXA', 'note' : 'Non visibili dal cliente'},
        'id' : {'id' : 'id'},
        'action' : {'id' : 'action'},
      }

      this.myForm = this.formComponentsService.createForm(this.arrayCampi);
      this.myForm.patchValue({ 'metodoSpedizione': 0 });
      this.myForm.patchValue({ 'action': 'spedizione' });

      this.service.getItem(idOrdine)
      .subscribe(data => {
        data.metodoSpedizione = data.metodoSpedizione.toString();
        let item = data;
        this.itemSpedito = item.spedito;
        this.onClickSpedito(item.spedito);
        this.myForm.patchValue(item);
        this.waitModalContent = false;
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });

    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });
  }


  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendSpedizione(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.onSubmitEmitter.emit(null);
        this.notificationsService.show('not_OpOk');
        this.onCancel();
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.waitModalContent = true;
    this.loading = false;
    this.onCancelEmitter.emit(null);
    this.modalReference.close();
  }

  onClickSpedito(checked) {
    this.checkedSpedito = checked;
    if (checked) {
      this.myForm.get('dataSpedizione').setValidators([Validators.required]);
    }
    else {
      this.myForm.get('dataSpedizione').clearValidators();
    }
    this.myForm.get('dataSpedizione').updateValueAndValidity();
  }

  annullaSpedizione(idOrdine: number) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        this.loading = true;
        that.service.annullaSpedizione(idOrdine)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.onSubmitEmitter.emit(null);
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenerico(obj, idOrdine);
  }
}

