import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Articolo } from './articolo';
import { ElementoOrdine } from './elemento-ordine';

@Injectable({
  providedIn: 'root'
})
export class ArticoliService {
  apiUrl: string;
  linkBase: string = "/ordini";

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
  }

  getElencoElementiOrdine(idOrdine: number): Observable<ElementoOrdine[]> {
    return this.http.get<ElementoOrdine[]>(this.apiUrl + 'op/elementiOrdine?idOrdine=' + idOrdine)
  }

  getItem(id: number): Observable<Articolo> {
    return this.http.get<Articolo>(this.apiUrl + 'op/elementoArticolo?id=' + id)
  }

  deleteElementoOrdine(id: number) {
    return this.http.delete<void>(this.apiUrl + id + '/?type=elementoOrdine')
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }
}
