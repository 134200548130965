import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/service/loader.service';

@Injectable({
  providedIn: 'root'
})
export class FormInputService {
  emailPattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$";
  numberPatter = "^[0-9]*$";
  datePattern = "^(19|20)\\d\\d([- /.])(0[1-9]|1[012])\\2(0[1-9]|[12][0-9]|3[01])$";
  cfPattern = "^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$";
  passwordPattern = "^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)[A-Za-z\\d$@$!%*#?&]{8,}$"; // 8 caratteri, obbligatorio 1 maiuscola, 1 minuscola, 1 numero (simboli ammessi ma non obbligatori)
//  passwordPattern = "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[$@$!%*#?&])[A-Za-z\\d$@$!%*#?&]{8,}$"; // 8 caratteri, obbligatorio 1 maiuscola, 1 minuscola, 1 numero 1 simbolo
  linkPattern = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s\/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-\/]*)?$/;
  gpsPattern = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

  inputClass ="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-8";
  noteClass = "col-xs-12 col-sm-12 col-md-2 col-lg-4 col-xl-4 note";
  shortInputClass ="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3";
  shortNoteClass = "col-xs-12 col-sm-12 col-md-6 col-lg-8 col-xl-9";
  fullInputClass ="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-11";
  fullNoteClass = "col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-1";

  constructor(
    private formBuilder: FormBuilder,
    private loader: LoaderService
  ) { }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  createForm(arrayCampi: any, myForm: FormGroup = null) {
    if (!myForm) {
      myForm = this.formBuilder.group({});
    }

    for (let key of Object.keys(arrayCampi)) {
      let myObj = arrayCampi[key];
      let validators = [];

      if (myObj.required) {
       validators.push(Validators.required);
      }
      if (!myObj.hasOwnProperty('defValue')) {
       myObj.defValue = '';
      }
      if (!myObj.hasOwnProperty('disabled')) {
       myObj.disabled = '';
      }
      if (!myObj.hasOwnProperty('type')) {
       myObj.type ='TXT';
      }

      myForm.addControl("_typeCode|" + myObj.id, new FormControl(myObj.type));

     switch (myObj.type) {
       case 'IMG':
         myForm.addControl(myObj.id + '_attuale', new FormControl(''));
         myForm.addControl(myObj.id + '_presente', new FormControl('', validators));
         myForm.addControl(myObj.id + '_cropped', new FormControl(''));
         myForm.addControl(myObj.id + '_elimina', new FormControl(''));
         myForm.addControl(myObj.id, new FormControl(myObj.defValue));
         break;

       case 'URL':
           validators.push(Validators.pattern(this.linkPattern));
           myForm.addControl(myObj.id, new FormControl({value: myObj.defValue, disabled: myObj.disabled}, validators));
           break;

        case 'EML':
          validators.push(Validators.pattern(this.emailPattern));
          myForm.addControl(myObj.id, new FormControl({value: myObj.defValue, disabled: myObj.disabled}, validators));
          break;

        case 'NUM':
          validators.push(Validators.pattern(this.numberPatter));
          myForm.addControl(myObj.id, new FormControl({value: myObj.defValue, disabled: myObj.disabled}, validators));
          break;

        case 'GPS':
          validators.push(Validators.pattern(this.gpsPattern));
          myForm.addControl(myObj.id, new FormControl({value: myObj.defValue, disabled: myObj.disabled}, validators));
          break;

        case 'PER':
          myForm.addControl(myObj.id + '_dal', new FormControl({value: myObj.defValue, disabled: myObj.disabled}, validators));
          myForm.addControl(myObj.id + '_al', new FormControl({value: myObj.defValue, disabled: myObj.disabled}, validators));
          break;

       default:
         myForm.addControl(myObj.id, new FormControl({value: myObj.defValue, disabled: myObj.disabled}, validators));
         break;
     }
   }

   return myForm;
  }


  checkLoader(activeItems) {
    if (activeItems <= 0) {
      this.loader.stopSpinner();
    }
  }

  activateLoader(activate: boolean) {
    this.loader.setSpinner(activate);
  }


  patchImageField(myItem: any, myForm: FormGroup, arrayCampi: any[any]) {
    for (let key of Object.keys(arrayCampi)) {
      let myObj = arrayCampi[key];

      if (myObj.type == 'IMG') {
        if (myItem[myObj.id]) {
          myItem[myObj.id + '_attuale'] = myItem[myObj.id];
          delete(myItem[myObj.id]);

          myForm.get(myObj.id + '_presente').setValue(1);
        }
      }
    }

    return myItem;
  }

  getInputValue(myForm: FormGroup, idItem: string) {
    return myForm.controls[idItem].value;
  }

  checkEmptyInput(myForm: FormGroup, idItem: string) {
    let value = this.getInputValue(myForm, idItem);
    return this.isEmptyValue(value);
  }

  isEmptyValue(value) {
    return ((value == '') || (value == null)) && (value != '0');
  }


  setDisabled(myForm: FormGroup, idItem: string, disable: boolean = true ) {
    if (disable) {
      myForm.get(idItem).disable();
    }
    else {
      myForm.get(idItem).enable();
    }
  }
}
