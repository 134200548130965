export class Ordine {
  id: number;
  idAnagrafica: number;
  _titoloScheda: string;
  metodoPagamento: any;
  pagato: boolean;
  dataPagamento: string;
  notePagamento: string;
  metodoSpedizione: any;
  spedito: boolean;
  dataSpedizione: string;
  noteSpedizione: string;
  totaleArticoli: number;
  numeroArticoli: number;
  totaleOrdine: number;
  costoSpedizione: number;
  costoPagamento: number;
  importoSconto: number;
  idCodiceSconto: any;
  confermato: number; // opposto di "bozza", usato per condizionare i pulsanti sull'elenco
  completo: boolean; // indica se l'ordine è completo e può essere quindi confermato
  annullato: boolean;
  noteReso: string;
}
