import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { ContenutiService } from 'src/app/service/contenuti.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';

@Component({
  selector: 'app-opzioni-home',
  templateUrl: './opzioni-home.component.html',
  styleUrls: ['./opzioni-home.component.scss']
})
export class OpzioniHomeComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: any;
  titoloScheda: string = "Caricamento...";

  THIS_ID: number = 51;

  constructor(
    private formComponentsService: FormInputService,
    private contenutiService: ContenutiService,
    private mainEngineService: MainEngineService,
    private router: Router,
    private componentsService: ComponentsService,
    private notificationsService: NotificationsService
  ) {
    this.arrayCampi = {
      'mostraPulsanteMusei' : {'id' : 'mostraPulsanteMusei', 'nome': 'Mostra pulsante Shop Musei', 'type': 'BOL0'},
      'mostraPulsanteMostre' : {'id' : 'mostraPulsanteMostre', 'nome': 'Mostra pulsante Shop Mostre', 'type': 'BOL0'},
      'testoBarraSuperiore' : {'id' : 'testoBarraSuperiore', 'nome': 'Testo barra superiore'},
      'linkBarraSuperiore': {'id' : 'linkBarraSuperiore', 'nome': 'Link barra superiore', 'type': 'URL', 'required': false},
    };
  }

  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);

    this.contenutiService.getItem(this.THIS_ID)
    .subscribe(data => {
      this.myItem = data;
      this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

      this.formComponentsService.activateLoader(false);
      this.titoloScheda = "Modifica elemento";
      this.myForm.patchValue(this.myItem);
      this.myForm.markAsPristine();
    },
    (error) => {
      this.loading = false;
      this.mainEngineService.errorHandler(error);
    });
  }



  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.contenutiService.sendItem(this.THIS_ID, this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.router.navigate(['/']);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel('/');
  }
}
