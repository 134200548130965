import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SliderOpzioni } from 'src/app/components/slider/slider-opzioni';

@Component({
  selector: 'app-musei-slider',
  templateUrl: './musei-slider.component.html',
  styleUrls: ['./musei-slider.component.scss']
})
export class MuseiSliderComponent implements OnInit {
  apiUrl = '';
  options: SliderOpzioni

  constructor(
    private route: ActivatedRoute,
  ) {
    this.options = new SliderOpzioni;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.options.id = params.id;
        }
        this.options.idPadre = params.idPadre;
        this.options.backUrl = "/musei/lastItem/"+this.options.idPadre;

        this.options.dimensione = "1153x483";
        this.options.campoTitolo = true;
        this.options.campoSottotitolo = true;
        this.options.tipologia = "musei";
      }
    );
  }

  ngOnInit(): void {
  }

}
