import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { Maxipulsante } from '../maxipulsante';
import { MaxipulsantiOpzioni } from '../maxipulsanti-opzioni';
import { MaxipulsantiService } from '../maxipulsanti.service';

@Component({
  selector: 'app-maxipulsanti-dettaglio',
  templateUrl: './maxipulsanti-dettaglio.component.html',
  styleUrls: ['./maxipulsanti-dettaglio.component.scss']
})
export class MaxipulsantiDettaglioComponent implements OnInit {
  @Input() options: MaxipulsantiOpzioni;

  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: Maxipulsante;
  titoloScheda: string;
  arrayData: any[any];
  elencoLink: string;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private service: MaxipulsantiService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    let parametriImmagine = {'id' : 'immagine', 'nome': 'Immagine', 'immagineAttuale': '', 'type': 'IMG','required': true};
    if (this.options.dimensione) {
      const dummy = this.options.dimensione.split("x");
      parametriImmagine['ratio'] = +dummy[0]/+dummy[1];
      parametriImmagine['minW'] = +dummy[0];
      parametriImmagine['minH'] = +dummy[1];
    }

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'titolo': {'id' : 'titolo', 'nome': 'Titolo', 'type': 'TXA', 'required': false},
      'link': {'id' : 'link', 'nome': 'Link', 'type': 'URL', 'required': false},
      'immagine' : parametriImmagine,
    };

    if (this.options.idPadre != 0) {
      this.arrayCampi['idPadre'] = {'id' : 'idPadre', 'type': 'NUM'};
    }

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);

    this.route.params.subscribe(
      (params) => {

        this.myForm.controls.idPadre.setValue(params.idPadre);
        this.elencoLink = '/' + this.options.tipologia + '/' + params.idPadre + '/maxipulsanti';

        if (params.id != '0') {
          this.service.getItem(params.id)
          .subscribe(data => {
            this.myItem = data;

            this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

            this.myForm.patchValue(this.myItem);
            this.formComponentsService.activateLoader(false);
          });
          this.titoloScheda = "Modifica elemento";
        }
        else {
          this.titoloScheda = "Inserimento elemento";
          this.formComponentsService.activateLoader(false);
        }
      });

  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.elencoLink]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.elencoLink + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
        this.router.navigate([this.elencoLink]);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel(this.elencoLink);
  }

  onBack() {
    this.componentsService.onBack(this.elencoLink);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }
}
