import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ButtonService } from '../buttons/button.service';
import { ComponentsService } from '../component.service';
import { FormInputService } from '../scheda/formInput/form-input.service';
import { NotificationsService } from '../service/notification.service';
import { ProfiloService } from './profilo.service';

@Component({
  selector: 'app-profilo',
  templateUrl: './profilo.component.html',
  styleUrls: ['./profilo.component.scss']
})
export class ProfiloComponent implements OnInit {
  arrayCampi1: any[any];
  arrayCampi2: any[any];
  myForm1: FormGroup;
  myForm2: FormGroup;
  myItem: any;
  loading = false;
  titoloScheda: string = "Gestione profilo";
  arrayPulsanti: any[any];

  constructor(
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private componentsService: ComponentsService,
    private router: Router,
    private buttonService: ButtonService,
    private service: ProfiloService
  ) {
    this.arrayCampi2 = {
      'passwordAttuale' : {'id' : 'passwordAttuale', 'nome': 'Password attuale', 'required': true},
      'passwordNuova' : {'id' : 'passwordNuova', 'nome': 'Nuova password', 'required': true},
      'passwordRipeti' : {'id' : 'passwordRipeti', 'nome': 'Ripeti nuova password', 'required': true},
    };

    this.arrayPulsanti = {
      'annullaRichiestaEmail' : {'type' : 'custom', 'testo' : 'Annulla richiesta cambio email', 'colorClass' : 'light', 'icon': 'fas fa-window-close', 'actionTooltip' : 'Annulla la richiesta di modifica email'},
      'reinviaCodiceEmail' : {'type' : 'custom', 'testo' : 'Reinvia codice conferma', 'colorClass' : 'light', 'icon': 'fas fa-paper-plane', 'actionTooltip' : 'Richiedi un nuovo invio del codice di conferma', 'ML' : 1}
    };

  }

  ngOnInit(): void {
    this.myForm2 = this.formComponentsService.createForm(this.arrayCampi2);

    this.getData();
  }


  getData() {
    this.formComponentsService.activateLoader(true);
    this.service.getItem()
    .subscribe(data => {
      if (data.newEmail == '') {
        this.arrayCampi1 = {
          'email' : {'id' : 'email', 'nome': 'Nuovo indirizzo email', 'required': true, 'type': 'EML'},
        };
      }
      else {
        this.arrayCampi1 = {
          'codice' : {'id' : 'codice', 'nome': 'Codice di verifica', 'required': true, 'type': 'NUM'},
        };
      }
      this.myForm1 = this.formComponentsService.createForm(this.arrayCampi1);
      this.myItem = data;
      this.loading = false;
      this.formComponentsService.activateLoader(false);
    },
    (error) => {
      this.formComponentsService.activateLoader(false);
      this.mainEngineService.errorHandler(error);
    });
  }

  onSubmitEmail() {
    if (!this.myForm1.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm1);
      return false;
    }

    this.loading = true;

    this.service.sendEmail(this.myForm1.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.loading = false;
        this.getData();
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  onSubmitCode() {
    if (!this.myForm1.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm1);
      return false;
    }

    this.loading = true;

    this.service.sendVerificationCode(this.myForm1.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.loading = false;
        this.ngOnInit();
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  onCancelRequestEmail() {
    const that = this;
    const obj = {
      onSuccess(arg) {
        that.loading = true;
        that.service.cancelRequestEmail()
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.ngOnInit();
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenerico(obj, null);
  }


  onResendCodeEmail() {
    this.loading = true;
    this.service.resendCodeEmail()
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.getData();
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  onSubmitPassword() {

    if (!this.myForm2.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm2);
      return false;
    }

    let formValues = this.myForm2.getRawValue();
    if (formValues.passwordNuova != formValues.passwordRipeti) {
      this.notificationsService.show('warning', 'Nuova password e ripeti nuova password non coincidono');
      return false;
    }

    this.loading = true;

    this.service.sendPassword(this.myForm2.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.router.navigate(['/']);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  onCancel() {
    this.componentsService.onCancel('/');
  }

}
