import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Articolo } from './ordini-scheda/articoli/articolo';
import { ElementoOrdine } from './ordini-scheda/articoli/elemento-ordine';
import { Ordine } from './ordine';

@Injectable({
  providedIn: 'root'
})
export class OrdiniService {
  apiUrl: string;
  linkBase: string = "/ordini";
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;
  arrayRicerca: any[any] = [];
  arrayBackupRicerca: any[any] = [];
  keepBackupRicerca: boolean = false;
  fromRicerca: boolean = false;
  filtraPerAnagrafica: number = 0;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');
  }

  setParametriRicerca(formValue: any[]) {
    this.arrayRicerca = formValue;
    this.fromRicerca = true;
    this.keepBackupRicerca = false;
  }

  getParametriRicerca() {
    return this.arrayBackupRicerca;
  }

  getElenco(): Observable<Ordine[]> {
    let dummy = "";
    if (this.keepBackupRicerca) {
      dummy = this.arrayBackupRicerca;
    }
    else {
      if (this.fromRicerca) {
        dummy = this.arrayRicerca;
        this.fromRicerca = false;
        this.keepBackupRicerca = true;
        this.arrayBackupRicerca = this.arrayRicerca;
        this.arrayRicerca = [];
      }
      else {
        dummy = null;
        this.arrayBackupRicerca = [];
      }
    }
    return this.http.patch<Ordine[]>(this.apiUrl, dummy)
  }

  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + 'op/opzioni')
  }


  getItem(id: number): Observable<Ordine> {
    return this.http.get<Ordine>(this.apiUrl + id)
  }


  deleteItem(id: number) {
    return this.http.delete<Ordine>(this.apiUrl + id)
  }

  sendPagamento(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  annullaPagamento(id: number) {
    let action = "annullaPagamento";
    return this.http.post<any>(this.apiUrl ,{id, action})
  }

  annullaSpedizione(id: number) {
    let action = "annullaSpedizione";
    return this.http.post<any>(this.apiUrl ,{id, action})
  }


  getElencoAnagraficaOrdini(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elencoAnagraficaOrdini');
  }

  getElencoStatiOrdine(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elencoStatiOrdine');
  }

  getElencoMetodiPagamento(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elencoMetodiPagamento');
  }

  getElencoCodiciSconto(idOrdine: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elencoCodiciSconto?idOrdine=' + idOrdine);
  }

  sendSpedizione(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  sendReso(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  sendSconto(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  getSpedizione(idOrdine: number) {
    return this.http.get<any>(this.apiUrl + 'op/statoSpedizione?idOrdine=' + idOrdine);
  }

  getPagamento(idOrdine: number) {
    return this.http.get<any>(this.apiUrl + 'op/statoPagamento?idOrdine=' + idOrdine);
  }

  getSconto(idOrdine: number) {
    return this.http.get<any>(this.apiUrl + 'op/statoSconto?idOrdine=' + idOrdine);
  }

  aggiornaAnagrafica(data, id) {
    let action = "aggiornaAnagrafica";
    return this.http.post<any>(this.apiUrl , {data, id, action});
  }

  getElencoMetodiSpedizione(idOrdine: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elencoMetodiSpedizione?idOrdine=' + idOrdine);
  }

  getRiassuntoOrdine(idOrdine: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/riassuntoOrdine?idOrdine=' + idOrdine);
  }

  clickItemButton(button, item) {
    button.value.onClickFunction(item.id);
  }

  confermaOrdine(id) {
    let action = "confermaOrdine";
    return this.http.post<any>(this.apiUrl , {id, action});
  }

  annullaOrdine(id, annulla) {
    let action = "annullaOrdine";
    return this.http.post<any>(this.apiUrl , {id, annulla, action});
  }

  reinviaEmail(id) {
    let action = "reinviaEmail";
    return this.http.post<any>(this.apiUrl , {id, action});
  }
}
