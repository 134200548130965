import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssegnaProdottiOpzioni } from '../../common/assegna-prodotti/assegna-prodotti-opzioni';

@Component({
  selector: 'app-mostre-prodotti',
  templateUrl: './../../common/assegna-prodotti/main-assegna-prodotti.component.html',
  styleUrls: ['./mostre-prodotti.component.scss']
})
export class MostreProdottiComponent implements OnInit {
  options: AssegnaProdottiOpzioni
  azione: string = '';

  constructor(
    private route: ActivatedRoute,
  ) {
    this.options = new AssegnaProdottiOpzioni;
  }


  ngOnInit(): void {
    this.route.params.subscribe(
      (params) => {
        this.azione = params.azione;
        this.options.idPadre = params.idPadre;
        this.options.backUrl = "/mostre/lastItem/"+this.options.idPadre;
        this.options.tipologia = "mostre";
      }
    );
  }

}
