import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from '../blog.service';

@Component({
  selector: 'app-blog-elenco',
  templateUrl: './blog-elenco.component.html',
  styleUrls: ['./blog-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlogElencoComponent implements OnInit {
  myService: any;
  columns: any[any];
  options: any[any];
  lastId: number = 0;

  constructor(
    private blogService: BlogService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.myService = this.blogService;

        this.columns = [
          { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo}` },
        ];
        this.options = {
          linkBase: this.blogService.linkBase,
        }
      });
  }

  ngOnInit(): void {
  }

}
