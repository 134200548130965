import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { TipologiaConsegna } from '../../../gestioneConsegne/tipologieConsegna/tipologia-consegna';
import { ArticoliService } from '../../ordini-scheda/articoli/articoli.service';
import { ElementoOrdine } from '../../ordini-scheda/articoli/elemento-ordine';
import { OrdiniService } from '../../ordini.service';
import { forkJoin } from 'rxjs';
import { Ordine } from '../../ordine';

@Component({
  selector: 'app-modal-base-reso',
  templateUrl: './modal-base-reso.component.html',
  styleUrls: ['./modal-base-reso.component.scss']
})
export class ModalBaseResoComponent implements OnInit {
  @ViewChild('modal') modal: any;
  @Output() onCancelEmitter = new EventEmitter();
  @Output() onSubmitEmitter = new EventEmitter();

  modalReference: any;
  arrayMetodiSpedizione: TipologiaConsegna[];
  arrayCampi: any[any];
  myForm: FormGroup;
  itemSpedito: boolean = false;
  checkedSpedito: boolean = false;
  waitModalContent: boolean = true;
  loading: boolean = false;
  elementiOrdine: ElementoOrdine[];
  arrayResi = [];
  myOrdine: Ordine;
  titoloModal: string = "";
  maxQuantita = [];

  constructor(
    private modalService: NgbModal,
    private articoliService: ArticoliService,
    private service: OrdiniService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private buttonService: ButtonService,
    private formComponentsService: FormInputService
  ) { }


  ngOnInit(): void {
  }


  openModal(idOrdine: number): void {
    if (idOrdine < 0) {
      return;
    }
    this.modalReference = this.modalService.open(this.modal, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});

    this.titoloModal = " - Ordine #" + idOrdine;

    forkJoin([
      this.service.getItem(idOrdine),
      this.articoliService.getElencoElementiOrdine(idOrdine)
    ])
    .subscribe(
      ([myOrdine, elementiOrdine]) => {
      this.elementiOrdine = elementiOrdine;
      this.myOrdine = myOrdine

      this.arrayCampi = {
        'noteReso' : {'id' : 'noteReso', 'nome': 'Note ad uso interno', 'type': 'TXA', 'note' : 'Non visibili dal cliente', 'defValue' : this.myOrdine.noteReso},
        'id' : {'id' : 'id'},
        'action' : {'id' : 'action'},
      }

      Object.entries(this.elementiOrdine).forEach(
        ([key, value]) => {
          let testo = value.titolo + " (€ " + value.prezzoTxt + " - q.tà: " + value.q + ")";
          if (value.sottotitolo != '') {
            testo += "<br>" + value.sottotitolo;
          }

          this.arrayCampi['id_'+key] = {'id' : 'id_'+key, 'defValue' : value.id };
          this.arrayCampi['reso_'+key] = {'id' : 'reso_'+key, 'type': 'BOL', 'nome': testo, 'defValue' : value.reso  };
          this.arrayCampi['importo_'+key] =  {'id' : 'importo_'+key, 'nome': 'importo reso', 'required': true, 'defValue' : value.importoReso };
          this.arrayCampi['quantita_'+key] = {'id' : 'quantita_'+key, 'nome': 'Qauntità resa', 'required': true, 'type': 'NUM', 'defValue' : parseInt(value.quantitaReso)};
          this.maxQuantita[key] = [];
          for (let i = 1; i <= value.q; i++) {
            this.maxQuantita[key].push({'id': i, 'denominazione' : i});
          }
        }
      );
      this.arrayCampi['numElementi'] = {'id' : 'numElementi', 'defValue' : this.elementiOrdine.length };

      this.myForm = this.formComponentsService.createForm(this.arrayCampi);
      this.myForm.patchValue({ 'id': idOrdine });
      this.myForm.patchValue({ 'action': 'reso' });

      Object.entries(this.elementiOrdine).forEach(
        ([key, value]) => {
          this.onClickReso(key, value.reso);
        }
      );

      this.waitModalContent = false;
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });
  }


  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendReso(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.onSubmitEmitter.emit(null);
        this.notificationsService.show('not_OpOk');
        this.onCancel();
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.waitModalContent = true;
    this.loading = false;
    this.onCancelEmitter.emit(null);
    this.modalReference.close();
  }

  onClickReso(key, checked) {
    if (checked) {
      this.arrayResi[key] = true;
      this.myForm.get('importo_'+key).setValidators([Validators.required]);
    }
    else {
      this.arrayResi[key] = false;
      this.myForm.get('importo_'+key).setValidators([Validators.required]);
    }
  }
}

