import { Component, Input, OnInit } from '@angular/core';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ConfigParams } from '../../class/config-params';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  isCondensed = false;
  configParams: ConfigParams;

  constructor(
    private mainEngineService: MainEngineService
  ) { }


  ngOnInit(): void {
    this.mainEngineService.getConfigParamsSubject().subscribe((value) =>  {
      this.configParams = value;
    });

    document.body.removeAttribute('data-layout');
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    this.isCondensed = !this.isCondensed;
    document.body.classList.toggle('sidebar-enable');
  }

}
