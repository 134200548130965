import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MatTableDataSource } from '@angular/material/table';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ProdottiPrezziService } from '../prodotti-prezzi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { first } from 'rxjs/operators';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { ButtonService } from 'src/app/components/buttons/button.service';

@Component({
  selector: 'app-prodotti-prezzi',
  templateUrl: './prodotti-prezzi.component.html',
  styleUrls: ['../../../components/elenco/elenco.component.scss', './prodotti-prezzi.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProdottiPrezziComponent implements OnInit {
  backUrl = '';
  titoloScheda: string = "Caricamento...";
  columns: any[any];
  dataSource: MatTableDataSource<any>;
  displayedColumns: any[];
  idPadre: number = 0;
  data: any;
  prezziAttuali: any;
  numVarianti: number = 0;
  loading: boolean = false;
  arrayCampi: any[any];
  myForm: FormGroup;
  arrayPulsanti: any[any];

  operazione: number = 0;
  tipologia: number = 0;
  oneSelected: boolean = false;

  @ViewChild('navBar') nav;

  constructor(
    private router: Router,
    private componentsService: ComponentsService,
    private formComponentsService: FormInputService,
    private myService: ProdottiPrezziService,
    private mainEngineService: MainEngineService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private buttonService: ButtonService
  ) {
    this.arrayCampi = {
      'idPadre' : {'id' : 'idPadre', 'type': 'NUM'},
      'operazione' : {'id' : 'operazione', 'nome': 'Operazione', 'items': {1: 'Variazione di prezzo a partire da una data (valida fino ad altre variazioni)', 2:'Promozione / variazione per un periodo specifico'}, 'type': 'RAD', 'required': true},
      'tipologia' : {'id' : 'tipologia', 'nome': 'Tipologia', 'items': {1: 'Inserimento diretto del nuovo prezzo', 2:'Sconto in % del prezzo attualmente in vigore', 3:'Sconto in € sul prezzo attualmente in vigore'}, 'type': 'RAD', 'required': false},
      'promoPrezzo' : {'id' : 'promoPrezzo', 'nome': 'Nuovo prezzo', 'note': 'Nuovo prezzo da applicare nel periodo indicato', 'required': false},
      'scontoP' : {'id' : 'scontoP', 'nome': 'Sconto in %', 'note': 'Sconto da applicare al prezzo in vigore nel periodo indicato', 'required': false},
      'scontoE' : {'id' : 'scontoE', 'nome': 'Sconto in €', 'note': 'Sconto da applicare al prezzo in vigore nel periodo indicato', 'required': false},
      'nuovoPrezzo' : {'id' : 'nuovoPrezzo', 'nome': 'Nuovo prezzo', 'required': false},
      'validoDal' : {'id' : 'validoDal', 'nome': 'Prezzo valido dal', 'type': 'DTA', 'minToday': true, 'required': false},
      'periodoValidita' : {'id' : 'periodoValidita', 'nome': 'Periodo di validità', 'type': 'PER', 'minToday': true, 'required': false},
    };

    this.arrayPulsanti = {
      'delete' : {'type' : 'delete', 'hideText': false},
    };

  }

  ngOnInit(): void {
    this.initSection();
  }


  initSection() {
    this.route.params.subscribe(
      (params) => {
        this.idPadre = params.idPadre;
        this.backUrl = "/prodotti/lastItem/"+this.idPadre;

        this.myService.setIdProdotto(this.idPadre)

        this.myService.getOpzioni()
        .subscribe(
          (data) => {
            this.data = data;

            this.titoloScheda = "Gestione prezzi '" + data._titoloScheda + "'";
            this.numVarianti = data.numVarianti;

            switch (this.numVarianti) {
              case 0:
                this.arrayCampi['0'] = {'id' : 'check_0'};
                break;

              case 1:
                for (let key1 in data.variante_1.elementi) {
                    this.arrayCampi[data.variante_1.elementi[key1].id] = {'id' : 'check_' + data.variante_1.elementi[key1].id};
                 }
                 break;

              case 2:
                for (let key1 in data.variante_1.elementi) {
                  for (let key2 in data.variante_2.elementi) {
                    this.arrayCampi[data.variante_1.elementi[key1].id + '_' + data.variante_2.elementi[key2].id] = {'id' : 'check_' + data.variante_1.elementi[key1].id + '_' + data.variante_2.elementi[key2].id};
                  }
                }
                break;
            }

            this.myForm = this.formComponentsService.createForm(this.arrayCampi);
            this.myForm.controls.idPadre.setValue(this.idPadre);

            this.columns = [

            ];

            for (let i = 1; i <= this.numVarianti; i++) {
              this.columns.push({ columnDef: 'variante' + i, header: this.data['variante_'+i].titolo, cell: (element: any) => `${element['variante' + i]}` });
            }
            this.columns.push({ columnDef: 'prezzo', header: 'Prezzo',    cell: (element: any) => `${element.prezzo}` });

            this.displayedColumns = this.columns.map(c => c.columnDef);
            this.displayedColumns.push('actions');


            this.getElenco();
            this.onClickCheckBox();

          })
      });
  }


  selectAll(newStatus) {
    let that = this;
    $( ".allItems" ).each(function() {
      let id = $(this).attr("id");
      that.myForm.patchValue({[id]: newStatus});
    });

    this.onClickCheckBox();
  }


  selectCol(colId, newStatus) {
    let that = this;
    $( ".col_" + colId ).each(function() {
      let id = $(this).attr("id");
      that.myForm.patchValue({[id]: newStatus});
    });

    this.onClickCheckBox();
  }

  selectRow(rowId, newStatus) {
    let that = this;
    $( ".row_" + rowId ).each(function() {
      let id = $(this).attr("id");
      that.myForm.patchValue({[id]: newStatus});
    });

    this.onClickCheckBox();
  }


  onClickTipologia() {
    this.tipologia = this.myForm.getRawValue().tipologia;

    if (this.tipologia != 1) {
      this.myForm.patchValue({'promoPrezzo': ''});
      this.myForm.get('promoPrezzo').clearValidators();
    }
    if (this.tipologia != 2) {
      this.myForm.patchValue({'scontoP': ''});
      this.myForm.get('scontoP').clearValidators();
    }
    if (this.tipologia != 3) {
      this.myForm.patchValue({'scontoE': ''});
      this.myForm.get('scontoE').clearValidators();
    }
    if (!this.tipologia) {
      this.myForm.patchValue({'periodoValidita_dal': ''});
      this.myForm.patchValue({'periodoValidita_al': ''});
      this.myForm.get('periodoValidita_dal').clearValidators();
      this.myForm.get('periodoValidita_al').clearValidators();
    }
    else {
      this.myForm.get('periodoValidita_dal').setValidators([Validators.required]);
      this.myForm.get('periodoValidita_al').setValidators([Validators.required]);

    }

    switch (this.tipologia) {
      case 1:
        this.myForm.get('promoPrezzo').setValidators([Validators.required]);
        break;

      case 2:
        this.myForm.get('scontoP').setValidators([Validators.required]);
        break;

      case 3:
        this.myForm.get('scontoE').setValidators([Validators.required]);
        break;
    }

    this.myForm.get('promoPrezzo').updateValueAndValidity();
    this.myForm.get('scontoE').updateValueAndValidity();
    this.myForm.get('scontoP').updateValueAndValidity();
    this.myForm.get('periodoValidita_dal').updateValueAndValidity();
    this.myForm.get('periodoValidita_al').updateValueAndValidity();

  }

  onClickOperazione() {
    this.operazione = this.myForm.getRawValue().operazione;

    if (this.operazione != 1) {
      this.myForm.patchValue({'nuovoPrezzo': ''});
      this.myForm.patchValue({'validoDal': ''});
      this.myForm.get('nuovoPrezzo').clearValidators();
      this.myForm.get('validoDal').clearValidators();
  }
    if (this.operazione != 2) {
      this.myForm.patchValue({'tipologia': ''});
      this.myForm.get('tipologia').clearValidators();
      this.onClickTipologia();
    }


    if (this.operazione == 1) {
        this.myForm.get('nuovoPrezzo').setValidators([Validators.required]);
        this.myForm.get('validoDal').setValidators([Validators.required]);
    }
    if (this.operazione == 2) {
        this.myForm.get('tipologia').setValidators([Validators.required]);
    }

    this.myForm.get('nuovoPrezzo').updateValueAndValidity();
    this.myForm.get('validoDal').updateValueAndValidity();
    this.myForm.get('tipologia').updateValueAndValidity();

  }

  onClickCheckBox() {
    if (!this.numVarianti) {
      this.oneSelected = true;
    }
    else {
      this.oneSelected = false;
      let that = this;
      $( ".allItems" ).each(function() {
        if($(this).is(":checked")) {
          that.oneSelected = true;
          return;
        }
      });
    }
    this.myForm.patchValue({'operazione': ''});

    this.onClickOperazione();

  }

  getElenco(showLoader = true) {
    if (showLoader) {
      this.formComponentsService.activateLoader(true);
    }

    this.myService.setIdProdotto(this.idPadre)

    this.myService.getElenco()
    .subscribe(
      data => {
        this.dataSource = new MatTableDataSource(data.items);
        this.componentsService.tableFitHeight();

        if (showLoader) {
          this.formComponentsService.activateLoader(false);
        }
      },
      (error) => {
        this.formComponentsService.activateLoader(false);
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  getData() {
      this.myService.setIdProdotto(this.idPadre)
      this.myService.getData()
      .subscribe(
        (data) => {
          this.prezziAttuali = data;

        },
        (error) => {
          this.loading = false;
          this.mainEngineService.errorHandler(error);
          this.router.navigate([this.backUrl]);
        });
        this.formComponentsService.activateLoader(false);

  }


  onTabChanged(event) {
    switch (event.nextId) {
      case 1:
          this.getElenco();
          break;

      case 2:
        this.getData();
        break;
    }
  }


  onDelete(id) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        this.loading = true;
        that.myService.deleteItem(arg)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.getElenco();
          },
          (error) => {
            this.loading = false;
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaElimina(obj, id);
  }

  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      this.notificationsService.show('warning', '', 'Alcuni campi non sono stati compilati correttamente');
      return false;
    }

    this.loading = true;

    this.myService.sendData(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.myForm.reset();
        this.myForm.controls.idPadre.setValue(this.idPadre);
        this.loading = false;
        this.onClickCheckBox();

        this.nav.select(1);
        this.getElenco();
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );

  }

  onCancel() {
    this.nav.select(1);
    this.getElenco();
  }

  onBack() {
    this.componentsService.onBack(this.backUrl);
  }
}
