import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MuseiService } from '../musei.service';
import { Museo } from '../museo';

@Component({
  selector: 'app-musei-elenco',
  templateUrl: './musei-elenco.component.html',
  styleUrls: ['./musei-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MuseiElencoComponent implements OnInit {

  myService: any;
  columns: any[any];
  options: any[any];
  lastId: number = 0;

  constructor(
    private museiService: MuseiService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

    this.myService = this.museiService;

    this.columns = [
      { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo}` },
      { columnDef: 'citta',     header: 'Città',   cell: (element: any) => `${element.citta}`},
      { columnDef: 'numProdottiAssegnati',     header: 'Prod.',   cell: (element: any) => `${element.numProdottiAssegnati}`},
      { columnDef: 'tipologia', header: 'Tipologia',    cell: (element: any) => `${element.tipologia}` },
    ];

    this.options = {
//      fotogallery: {active: 1},
      linkBase: this.museiService.linkBase
      ,customButtons: {
        a5_prodotti: {
          type: 'custom',
          testo: 'Prodotti',
          colorClass: 'info',
          icon: 'bx bx-box',
          actionTooltip: 'Gestione prodotti in vendita',
          routerLink: 'prodotti/elenco',
          hideText: true,
          ML: true
        },
        a4_slider: {
          type: 'custom',
          testo: 'Slider',
          colorClass: 'info',
          icon: 'bx bxs-slideshow',
          actionTooltip: 'Gestione immagini slider',
          routerLink: 'slider',
          hideText: true,
          condizione: 'sliderPresente',
          ML: true
        },
        a3_maxiPulsanti: {
          type: 'custom',
          testo: 'Maxi P.',
          colorClass: 'info',
          icon: 'bx bx-images',
          actionTooltip: 'Gestione maxi pulsanti home museo',
          routerLink: 'maxipulsanti',
          hideText: true,
          condizione: 'maxiButtonsPresente',
          ML: true
        },
      }
    }
  });

  }

  ngOnInit(): void {
  }
}
