import { Component, Input, OnInit, Output, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ModalBasePagamentoComponent } from '../../common/modal-base-pagamento/modal-base-pagamento.component';
import { OrdiniService } from '../../ordini.service';

@Component({
  selector: 'app-modal-gestione-pagamento',
  templateUrl: './modal-gestione-pagamento.component.html',
  styleUrls: ['./modal-gestione-pagamento.component.scss']
})
export class ModalGestionePagamentoComponent implements OnInit {
  @Input() openIdOrdine: number;
  @Output() resetOpenIdOrdine = new EventEmitter<boolean>();
  @ViewChild(ModalBasePagamentoComponent, {static : true}) child:ModalBasePagamentoComponent;

  linkBase: string;

  constructor(
    private service: OrdiniService,
    private mainEngineService: MainEngineService,
    private router: Router,
    private componentsService: ComponentsService
    ) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false; // permette il route allo stesso componente ma con query params differenti
      };

    //    this.linkBase = this.mainEngineService.globalPreviousUrl ? this.mainEngineService.globalPreviousUrl : this.service.linkBase;
    this.linkBase = this.service.linkBase;
  }

  ngOnInit(): void {
  }

  onCancel() {
    this.resetOpenIdOrdine.emit(null);
  }

  onSubmit() {
    this.resetOpenIdOrdine.emit(true);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('openIdOrdine' in changes) {
      if (this.openIdOrdine > -1) {
        this.linkBase = this.service.linkBase + '/lastItem/' + this.openIdOrdine;
        this.child.openModal(this.openIdOrdine);
      }
    }
  }
}


