/******
Versione 1.0.20210401
******/
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { FormInputService } from '../form-input.service';

@Component({
  selector: 'app-campo-data',
  templateUrl: './campo-data.component.html',
  styleUrls: ['./campo-data.component.scss'],
})
export class CampoDataComponent implements OnInit {
  inputClass: string;
  noteClass: string;
  min: string;

  constructor(
    private formComponentsService: FormInputService
    ) {
      this.inputClass = this.formComponentsService.inputClass;
      this.noteClass = this.formComponentsService.noteClass;
  }

  @Input() myForm: FormGroup;
  @Input() config: any;

  get objInput() {
    return this.myForm.get(this.config.id);
  }


  ngOnInit(): void {
    if (!this.config.id) {
      this.config.id = 'data';
    }
    if (!this.config.nome) {
      this.config.nome = 'Data';
    }
    if (!this.config.icona) {
      this.config.icona = 'calendar';
    }
    if (this.config.minToday) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      this.min = yyyy+"-"+mm+"-"+dd;
    }
  }
}
