import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SliderOpzioni } from 'src/app/components/slider/slider-opzioni';

@Component({
  selector: 'app-slider-home',
  templateUrl: './slider-home.component.html',
  styleUrls: ['./slider-home.component.scss']
})
export class SliderHomeComponent implements OnInit {
  apiUrl = '';
  options: SliderOpzioni

  constructor(
    private route: ActivatedRoute,
  ) {
    this.options = new SliderOpzioni;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.options.id = params.id;
        }
        this.options.dimensione = "1920x1080";
        this.options.campoTitolo = true;
        this.options.campoSottotitolo = true;
        this.options.campoLink = true;
        this.options.tipologia = "sliderHomepage";
        this.options.linkBase = "homepage/slider";
      }
    );
  }

  ngOnInit(): void {

  }

}
