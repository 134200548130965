import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ProdottiVariantiService } from '../prodotti-varianti.service';
import { Variante } from '../variante';

@Component({
  selector: 'app-varianti-dettaglio',
  templateUrl: './varianti-dettaglio.component.html',
  styleUrls: ['./varianti-dettaglio.component.scss']
})
export class VariantiDettaglioComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: Variante;
  titoloScheda: string = "Caricamento...";
  arrayData: any[any];
  elencoLink: string;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private service: ProdottiVariantiService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'idPadre' : {'id' : 'idPadre', 'defValue' : 0, 'type': 'NUM'},
      'titolo' : {'id' : 'titolo', 'nome': 'Titolo', 'required': true},
      'immagine' : {'id' : 'immagine', 'nome': 'Immagine', 'immagineAttuale': '', 'type': 'IMG','required': false},
    };

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);

    this.route.params.subscribe(
      (params) => {
        this.myForm.controls.idPadre.setValue(params.idTipologiaVariante);
        this.elencoLink = '/prodotti/' + params.idPadre + '/varianti/' + params.idTipologiaVariante;

        if (params.id != '0') {
          this.service.getItem(params.id)
          .subscribe(data => {
            this.myItem = data;

            this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

            this.myForm.patchValue(this.myItem);
            this.formComponentsService.activateLoader(false);
            this.titoloScheda = "Modifica elemento - '" + this.myItem._titoloScheda + "'";
          });
        }
        else {
          this.titoloScheda = "Inserimento elemento";
          this.formComponentsService.activateLoader(false);
        }
      });

  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.elencoLink]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.elencoLink + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel(this.elencoLink);
  }

  onBack() {
    this.componentsService.onBack(this.elencoLink);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }
}
