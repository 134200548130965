import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { CodiceSconto } from '../codice-sconto';
import { CodiciScontoService } from '../codici-sconto.service';

@Component({
  selector: 'app-codici-sconto-scheda',
  templateUrl: './codici-sconto-scheda.component.html',
  styleUrls: ['./codici-sconto-scheda.component.scss']
})
export class CodiciScontoSchedaComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: CodiceSconto;
  titoloScheda: string = "Caricamento...";
  arrayData: any[any];
  linkBase: string;
  arrayTipologiaSconto: any[any];
  arrayValiditaSconto: any[any];
  tipologiaSconto: string = "";

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private service: CodiciScontoService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayTipologiaSconto = {
      'E': 'Euro',
      'P': 'Percentuale'
    };

    this.arrayValiditaSconto = {
      'A': 'Totale articoli',
      'S': 'Spese di spedizione',
      'T': 'Totale ordine (totale articoli + spese di spedizione)'
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'codice' : {'id' : 'codice', 'nome': 'Codice', 'required': false, 'note': 'Se non indicato, verrà generato in maniera casuale (12 caratteri alfanumerici)'},
      'tipologiaSconto' : {'id' : 'tipologiaSconto', 'nome': 'Tipologia sconto', 'items': this.arrayTipologiaSconto, 'type': 'RAD', 'required': true, 'inline': true, 'onClick': this.onClick_tipologiaSconto.bind(this)},
      'importoE' : {'id' : 'importoE', 'nome': 'Importo in Euro', 'type': 'VLT+', 'required': true},
      'importoP' : {'id' : 'importoP', 'nome': 'Importo in percentuale', 'type': 'NUM+', 'required': true},
      'validitaSconto' : {'id' : 'validitaSconto', 'nome': 'Validità sconto', 'items': this.arrayValiditaSconto, 'type': 'RAD', 'required': true, 'inline': false},
      'importoMinimo' : {'id' : 'importoMinimo', 'nome': 'Importo minimo da raggiungere', 'type': 'VLT+', 'required': false, 'note': 'Si riferisce sempre al totale articoli. Lasciare vuoto se non è previsto un importo minimo'},
      'periodoValidita' : {'id' : 'periodoValidita', 'nome': 'Periodo di validità', 'type': 'PER', 'minToday': true, 'required': false},
      'maxApplicazioniTotali' : {'id' : 'maxApplicazioniTotali', 'nome': 'Numero massimo di applicazioni totali', 'type': 'NUM+', 'required': false, 'note': 'Se inserito, indica quante volte è possibile utilizzare questo buono per tutti gli acquisti di tutti gli utenti'},
      'maxApplicazioniPerUtente' : {'id' : 'maxApplicazioniPerUtente', 'nome': 'Numero massimo di applicazioni per utente', 'type': 'NUM+', 'required': false, 'note': 'Se inserito, indica quante volte un SINGOLO utente può utilizzare questo buono'},
    };
  }

  ngOnInit(): void {

    this.formComponentsService.activateLoader(true);
    this.linkBase = this.service.linkBase;

    this.route.params.subscribe(
      (params) => {
        this.myForm = this.formComponentsService.createForm(this.arrayCampi);

        if (params.id != '0') {
          this.linkBase += '/lastItem/' + params.id;
          this.service.getItem(params.id)
          .subscribe(data => {
            this.myItem = data;
            this.onClick_tipologiaSconto(this.myItem.tipologiaSconto)

            this.formComponentsService.activateLoader(false);
            this.titoloScheda = "Modifica elemento - '" + this.myItem._titoloScheda + "'";
            this.myForm.patchValue(this.myItem);
            this.myForm.markAsPristine();
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
          });
        }
        else {
          this.titoloScheda = "Inserimento elemento";
          this.formComponentsService.activateLoader(false);
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      });

  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.linkBase]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.service.linkBase + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onClick_tipologiaSconto(value) {
    this.tipologiaSconto = value;
    this.myForm.get('importoE').clearValidators();
    this.myForm.get('importoP').clearValidators();
    this.myForm.get('importo' + value).setValidators([Validators.required]);
}

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }

  onSave() {
    this.onSubmit(2);
  }

}
