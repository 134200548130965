import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { CampiSeoService } from 'src/app/components/scheda/campi-seo/campi-seo.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ProdottiService } from '../../prodotti/prodotti.service';
import { Mostra } from '../mostra';
import { MostreService } from '../mostre.service';

@Component({
  selector: 'app-mostre-dettaglio',
  templateUrl: './mostre-dettaglio.component.html',
  styleUrls: ['./mostre-dettaglio.component.scss'],
})
export class MostreDettaglioComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: Mostra;
  titoloScheda: string = "Caricamento...";
  arrayData: any[any];
  linkBase: string;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private mostreService: MostreService,
    private mainEngineService: MainEngineService,
    private prodottiService: ProdottiService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private campiSeoService: CampiSeoService,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'titolo' : {'id' : 'titolo', 'nome': 'Titolo', 'required': true},
      'sottotitolo' : {'id' : 'sottotitolo', 'nome': 'Sottotitolo'},
      'immagine' : {'id' : 'immagine', 'nome': 'Immagine', 'immagineAttuale': '', 'type': 'IMG', 'required': false, 'ratio': 800/500, 'minW': 800, 'minH': 500},
      'citta' : {'id' : 'citta', 'nome': 'Città'},
      'location' : {'id' : 'location', 'nome': 'Location'},
      'periodo' : {'id' : 'periodo', 'nome': 'Periodo'},
      'idProdotto_pp' : {'id' : 'idProdotto_pp', 'nome': 'Prodotto PP', 'required': true},
      'sottotitoloProdottoPP' : {'id' : 'sottotitoloProdottoPP', 'nome': 'Sottotitolo prodotto PP'},
      'link' : {'id' : 'link', 'nome': 'Link', 'type': 'URL'}
    };

    this.arrayCampi = {...this.arrayCampi, ...this.campiSeoService.arrayCampi};
  }

  ngOnInit(): void {

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);
    this.linkBase = this.mostreService.linkBase;


    this.prodottiService.getElencoProdottiPerMostre()
    .subscribe(data => {
      this.arrayData = data.items;

      this.route.params.subscribe(
        (params) => {
          if (params.id != '0') {
            this.linkBase += '/lastItem/' + params.id;
            this.mostreService.getItem(params.id)
            .subscribe(data => {
              this.myItem = data;

              this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

              this.formComponentsService.activateLoader(false);
              this.titoloScheda = "Modifica elemento - '" + this.myItem._titoloScheda + "'";
              this.myForm.patchValue(this.myItem);
              this.myForm.controls.idProdotto_pp.setValue(this.myItem.idProdotto_pp);
              this.myForm.markAsPristine();
            },
            (error) => {
              this.loading = false;
              this.mainEngineService.errorHandler(error);
            });
          }
          else {
            this.titoloScheda = "Inserimento elemento";
            this.formComponentsService.activateLoader(false);
          }
        });
    },
    (error) => {
      this.loading = false;
      this.mainEngineService.errorHandler(error);
    });

  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.mostreService.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.linkBase]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.mostreService.linkBase + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }

  onSave() {
    this.onSubmit(2);
  }

}
