import { Component, Input, OnInit, Output, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ModalBaseSpedizioneComponent } from '../../common/modal-base-spedizione/modal-base-spedizione.component';
import { OrdiniService } from '../../ordini.service';

@Component({
  selector: 'app-modal-gestione-spedizione',
  templateUrl: './modal-gestione-spedizione.component.html',
  styleUrls: ['./modal-gestione-spedizione.component.scss'],
})
export class ModalGestioneSpedizioneComponent implements OnInit {
  @Input() openIdOrdine: number;
  @Output() resetOpenIdOrdine = new EventEmitter<boolean>();
  @ViewChild(ModalBaseSpedizioneComponent, {static : true}) child:ModalBaseSpedizioneComponent;

  linkBase: string;

  constructor(
    private service: OrdiniService,
    private mainEngineService: MainEngineService,
    private router: Router,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };
  }

  ngOnInit(): void {
  }

  onCancel() {
    this.resetOpenIdOrdine.emit(null);
  }

  onSubmit() {
    this.resetOpenIdOrdine.emit(true);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('openIdOrdine' in changes) {
      this.linkBase = this.service.linkBase + '/lastItem/' + this.openIdOrdine;
      this.child.openModal(this.openIdOrdine);
    }
  }
}


