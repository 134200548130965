import { Component, Input, OnInit } from '@angular/core';
import { MaxipulsantiOpzioni } from '../maxipulsanti-opzioni';
import { MaxipulsantiService } from '../maxipulsanti.service';

@Component({
  selector: 'app-maxipulsanti-elenco',
  templateUrl: './maxipulsanti-elenco.component.html',
  styleUrls: ['./maxipulsanti-elenco.component.scss']
})
export class MaxipulsantiElencoComponent implements OnInit {
  @Input() options: MaxipulsantiOpzioni;

  myService: any;
  columns: any[any];
  backUrl: string = '';

  constructor(
    private service: MaxipulsantiService,
  ) {
    this.myService = this.service;

    this.columns = [
      { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo}` },
      { columnDef: 'immagine', header: 'Immagine', type:'img', cell: (element: any) => `${element.immagine}` },
    ];
  }

  ngOnInit(): void {
    this.service.setTipologia(this.options.tipologia);
    if (this.options.idPadre) {
      this.options.linkBase = "/" + this.options.tipologia + "/" + this.options.idPadre + "/maxipulsanti";
      this.service.setIdPadre(this.options.idPadre);
      this.backUrl = "/" + this.options.tipologia + "/lastItem/"+this.options.idPadre;
    }

  }
}
