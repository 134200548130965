import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { AnagraficaService } from '../anagrafica.service';

@Component({
  selector: 'app-modal-dettaglio-anagrafica',
  templateUrl: './modal-dettaglio-anagrafica.component.html',
  styleUrls: ['./modal-dettaglio-anagrafica.component.scss']
})
export class ModalDettaglioAnagraficaComponent implements OnInit {
  @Input() openIdAnagrafica: number;
  @Output() resetOpenIdAnagrafica = new EventEmitter();
  @ViewChild('modal') modal: any;

  modalReference: any;
  dettagliAnagrafica: string = "";
  waitModalContent: boolean = true;

  constructor(
    private mainEngineService: MainEngineService,
    private modalService: NgbModal,
    private service: AnagraficaService
  ) { }

  ngOnInit(): void {
  }

  onCancel() {
    this.waitModalContent = true;
    this.resetOpenIdAnagrafica.emit(null);
    this.modalReference.close();
  }


  visualizzaDettagli() {
    if (this.openIdAnagrafica <= 0) {
      return;
    }
    this.modalReference = this.modalService.open(this.modal, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});
    this.service.getDettagliAnagrafica(this.openIdAnagrafica)
    .subscribe(data => {
      this.dettagliAnagrafica = data;
      this.waitModalContent = false;
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });
  }


  public ngOnChanges(changes: SimpleChanges): void {
    if ('openIdAnagrafica' in changes) {
      if (this.openIdAnagrafica > -1) {
        this.visualizzaDettagli();
      }
    }
  }

}
