export class Prodotto {
  public static SHOP = 0;
  public static CATEGORIA = 1;
  public static SOTTOCATEGORIA = 2;

  public static POTREBBE_INTERESSARTI = {
    '': 'Nessuna correlazione',
     0: 'Stesso shop',
     1: 'Stessa categoria su qualsiasi shop',
     2: 'Stessa sottocategoria su qualsiasi shop',
     3: 'Stessa categoria su stesso shop',
     4: 'Stessa sottocategoria su stesso shop'
  }

  _titoloScheda: string;
  idSottocategoria: any;
}
