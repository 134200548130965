import { Component, OnInit } from '@angular/core';
import { ReservedAccessService } from 'src/app/components/reservedAccess/reserved-access.service';
import { UserLogged } from 'src/app/components/reservedAccess/user-logged';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ExportService } from './export.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {
  urlElencoIscrittiNewsletter: string;
  user: UserLogged;

  constructor(
    private mainEngineService: MainEngineService,
    private service: ExportService,
    private formComponentsService: FormInputService,
    private reservedAccessService: ReservedAccessService
  ) {
    this.formComponentsService.activateLoader(true);

    this.reservedAccessService.getUserBasicDataSubject().subscribe(data => {
      this.user = data;
    });

  }

  ngOnInit(): void {
    this.service.getToken()
    .subscribe(data => {
      this.urlElencoIscrittiNewsletter = this.mainEngineService.baseUrl + "export?type=iscrizioniNewsletter&token=" + data + "&userId=" + this.user.id;
      this.formComponentsService.activateLoader(false);
    },
    (error) => {
      this.formComponentsService.activateLoader(false);
      this.mainEngineService.errorHandler(error);
    });

  }

}
