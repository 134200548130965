import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Prodotto } from '../../prodotti/prodotto';
import { AssegnaProdottiOpzioni } from './assegna-prodotti-opzioni';
import { AssegnaProdottiService } from './assegna-prodotti.service';

@Component({
  selector: 'app-assegna-prodotti',
  templateUrl: './assegna-prodotti.component.html',
  styleUrls: ['./assegna-prodotti.component.scss']
})
export class AssegnaProdottiComponent implements OnInit {
  @Input() options: AssegnaProdottiOpzioni;

  myService: any;
  columns: any[any];
  arrayElementi: Prodotto[];

  constructor(
    private mainEngineService: MainEngineService,
    private service: AssegnaProdottiService,
    private router: Router,

  ) {
    this.myService = this.service;

    this.columns = [
      { columnDef: 'codiceProdotto', header: 'Codice', cell: (element: any) => `${element.codiceProdotto}` },
      { columnDef: 'titolo', header: 'Titolo', cell: (element: any) => `${element.titolo}` },
      { columnDef: 'editore', header: 'Editore', cell: (element: any) => `${element.editore}` },
      { columnDef: 'categoriaElenco', header: 'Categoria', cell: (element: any) => `${element.categoriaElenco }` },
    ];

  }

  mostraAggiungiProdotto() {
    this.router.navigate([this.options.tipologia + "/" + this.options.idPadre + "/prodotti/scelta"])
  }

  ngOnInit(): void {
    this.service.setTipologia(this.options.tipologia);
    this.service.setIdPadre(this.options.idPadre);

    this.options['nascondiPulsanteNuovo'] = true;
    this.options['noAzioni'] = false;
    this.options['linkBase'] = this.options.tipologia + "/" + this.options.idPadre + "/prodotti";
    this.options['linkRefreshElenco'] = this.options.tipologia + "/" + this.options.idPadre + "/prodotti/elenco";
//    this.options['linkBack'] = this.options.tipologia + "/" + this.options.idPadre + "/prodotti";
    this.options['testataButtons'] = {
      aggiungi: {
        type: 'add',
        testo: 'Associa',
        ML: true,
        click: this.mostraAggiungiProdotto.bind(this),

        hideText: true
      }
    };
    this.options['customButtons'] = {
      a1_prezzi: {
        type: 'edit',
        actionTooltip: 'Gestione prodotto',
        customRouterLink: 'prodotti',
        customRouterLinkId: 'idProdotto',
        hideText: true,
        ML: true
      }
    }
  }
}
