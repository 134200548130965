import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Mostra } from './mostra';

@Injectable({
  providedIn: 'root'
})
export class MostreService {
  apiUrl: string;
  linkBase: string = "mostre";
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');
  }

  getElenco(): Observable<Mostra[]> {
    return this.http.get<Mostra[]>(this.apiUrl)
  }

  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + 'op/opzioni')
  }


  getItem(id: number): Observable<Mostra> {
    return this.http.get<Mostra>(this.apiUrl + id)
  }

  deleteItem(id: number) {
    return this.http.delete<Mostra>(this.apiUrl + id)
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  toggleVisibile(id: number) {
    const tipologia = 'visibile';
    return this.http.patch<any>(this.apiUrl + id, {tipologia})
  }

  toggleEvidenza(id: number) {
    const tipologia = 'evidenza';
    return this.http.patch<any>(this.apiUrl + id, {tipologia})
  }

  changePosition(id: number, newPos: number) {
    const tipologia = 'posizione';
    return this.http.patch<any>(this.apiUrl + id, {tipologia, newPos})
  }
}
