import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Ordine } from '../../../ordine';
import { OrdiniService } from '../../../ordini.service';

@Component({
  selector: 'app-modal-sconto',
  templateUrl: './modal-sconto.component.html',
  styleUrls: ['./modal-sconto.component.scss']
})
export class ModalScontoComponent implements OnInit {
  @ViewChild('modal') modal: any;
  @Input() ordine: Ordine;
  @Input() showModal: boolean = false;
  @Output() onSubmitEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() onCancelEmitter: EventEmitter<void> = new EventEmitter<void>();

  arrayCampi: any[any];
  arrayDatiSelect: any[any];
  myForm: FormGroup;
  modalTitle: string;
  modalReference: any;
  idCodiceSconto: number = 0;
  loading = false;
  waitModalContent: boolean = true;

  constructor(
    private service: OrdiniService,
    private formComponentsService: FormInputService,
    private modalService: NgbModal,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
  ) {
  }

  ngOnInit(): void {
    this.arrayCampi = {
      'id' : {'id' : 'id', 'type': 'NUM', 'defValue' : this.ordine.id},
      'action' : {'id' : 'action', 'defValue' : 'codiceSconto', 'type': 'NOSAVE'},
      'idCodiceSconto' : {'id' : 'idCodiceSconto', 'nome': 'Codice sconto', 'required': true, 'onSelect': this.onCodiceSelezionato.bind(this), 'type': 'NUM', 'note' : 'Il sistema verifica solo se il codice è attivo e ne calcola il valore'},
      'importoSconto' : {'id' : 'importoSconto', 'nome': 'Importo sconto', 'type': 'VLT', 'required': true},
    };
  }

  openModal() {
    this.service.getElencoCodiciSconto(this.ordine.id)
    .subscribe(data => {
      this.arrayDatiSelect = data.items;

      this.myForm = this.formComponentsService.createForm(this.arrayCampi);

      this.modalTitle = "Gestione codice sconto";
      this.modalReference = this.modalService.open(this.modal, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});
      this.myForm.patchValue({ 'idCodiceSconto': this.ordine.idCodiceSconto.toString() });
      if (this.ordine.importoSconto != 0) {
        this.myForm.patchValue({ 'importoSconto': this.ordine.importoSconto });
      }
      this.idCodiceSconto = this.ordine.idCodiceSconto;
      this.onCodiceSelezionato(this.idCodiceSconto);
      this.waitModalContent = false;
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });
  }


  onCodiceSelezionato(idItem: any) {
    if (idItem == "99999999") {
      this.myForm.get('importoSconto').setValidators([Validators.required]);
    }
    else {
      this.myForm.get('importoSconto').clearValidators();
    }
    this.myForm.get('importoSconto').updateValueAndValidity();

    this.idCodiceSconto = idItem;
  }


  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
/*
      const invalid = [];
        const controls = this.myForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                console.log(controls[name]);
            }
        }
        console.log(this.myForm.getRawValue());
*/
      return false;
    }

    this.loading = true;

    this.service.sendSconto(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (idOrdine) => {
        this.notificationsService.show('not_OpOk');
        this.waitModalContent = true;
        this.loading = false;
        this.modalReference.close();
        this.onSubmitEmitter.emit(null);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.waitModalContent = true;
    this.onCancelEmitter.emit(null);
    this.modalReference.close();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('showModal' in changes) {
      if (this.showModal == true) {
        this.openModal();
      }
    }
  }

}
