import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Anagrafica } from 'src/app/pages/_ecommerce/anagrafica/anagrafica';
import { AnagraficaService } from 'src/app/pages/_ecommerce/anagrafica/anagrafica.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Ordine } from '../../../ordine';

@Component({
  selector: 'app-tab-anagrafica',
  templateUrl: './tab-anagrafica.component.html',
  styleUrls: ['./tab-anagrafica.component.scss']
})
export class TabAnagraficaComponent implements OnInit {
  @Input() ordine: Ordine;
  @Output() reloadOrder = new EventEmitter<number>();

  myAnagrafica: Anagrafica;
  arrayPulsanti: any[any];
  openModalId: number = -1;
  idAnagrafica: number = 0;

  constructor(
    private service: AnagraficaService,
    private mainEngineService: MainEngineService,
  ) {
    this.arrayPulsanti = {
      'modifica' : {'type' : 'edit'},
    };
   }

  ngOnInit(): void {
    this.idAnagrafica = (this.ordine && this.ordine.idAnagrafica) ? this.ordine.idAnagrafica : 0;
    if (!this.ordine || !this.ordine.id) {
      this.ordine = new(Ordine);
      this.ordine.id = 0;
    }
    if (this.idAnagrafica != 0) {
      this.service.getItem(this.idAnagrafica, this.ordine.id)
      .subscribe(data => {
        this.myAnagrafica = data;
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });
    }
  }

  updateAnagrafica(idAnagrafica) {
    this.ordine.idAnagrafica = parseInt(idAnagrafica);
    this.ngOnInit();
  }

  modalForm() {
    this.openModalId = this.idAnagrafica;
  }

  onSubmit(idOrdine: number = 0) {
    this.onCancel();
    this.reloadOrder.emit(idOrdine);
  }

  onCancel() {
    this.openModalId = -1;
  }
}
