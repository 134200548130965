/******
Versione 1.0.20210401
******/

import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormInputService } from '../form-input.service';

@Component({
  selector: 'app-campo-checkbox',
  templateUrl: './campo-checkbox.component.html',
  styleUrls: ['./campo-checkbox.component.scss']
})
export class CampoCheckboxComponent implements OnInit {
  inputClass: string;
  noteClass: string;

  constructor(
    private formComponentsService: FormInputService
    ) {
      this.inputClass = this.formComponentsService.inputClass;
      this.noteClass = this.formComponentsService.noteClass;
  }

  @Input() myForm: FormGroup;
  @Input() config: any;

  @Output() public onClickEmitter = new EventEmitter<{checked: boolean}>();

  get objInput() {
    return this.myForm.get(this.config.id);
  }


  ngOnInit(): void {
    if (!this.config.id) {
      this.config.id = 'checkbox';
    }
    if (!this.config.nome) {
      this.config.nome = 'Testo';
    }
  }

  triggerOnClick($event) {
    this.onClickEmitter.emit($event.target.checked);
  }
}
