import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { ContenutiService } from 'src/app/service/contenuti.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';

@Component({
  selector: 'app-testi-sezioni',
  templateUrl: './testi-sezioni.component.html',
  styleUrls: ['./testi-sezioni.component.scss']
})
export class TestiSezioniComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: any;
  titoloScheda: string = "Caricamento...";

  THIS_ID: number = 50;

  constructor(
    private formComponentsService: FormInputService,
    private contenutiService: ContenutiService,
    private mainEngineService: MainEngineService,
    private router: Router,
    private componentsService: ComponentsService,
    private notificationsService: NotificationsService
  ) {
    this.arrayCampi = {
      'comeFareOrdine' : {'id' : 'comeFareOrdine', 'nome': 'Come fare un ordine', 'type': 'RTX'},
      'modalitaPagamento' : {'id' : 'modalitaPagamento', 'nome': 'Modalità  di pagamento', 'type': 'RTX'},
      'spedizioniConsegna' : {'id' : 'spedizioniConsegna', 'nome': 'Spedizioni & consegna', 'type': 'RTX'},
      'politicheReso' : {'id' : 'politicheReso', 'nome': 'Politiche di reso', 'type': 'RTX'},
      'venditaIngrosso' : {'id' : 'venditaIngrosso', 'nome': 'Vendita all\'ingrosso', 'type': 'RTX'},
      'collaboraConNoi' : {'id' : 'collaboraConNoi', 'nome': 'Collabora con noi', 'type': 'RTX'},
      'privacyPolicy' : {'id' : 'privacyPolicy', 'nome': 'Privacy Policy', 'type': 'RTX'},
    };
  }

  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);

    this.contenutiService.getItem(this.THIS_ID)
    .subscribe(data => {
      this.myItem = data;
      this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

      this.formComponentsService.activateLoader(false);
      this.titoloScheda = "Modifica elemento";
      this.myForm.patchValue(this.myItem);
      this.myForm.markAsPristine();
    },
    (error) => {
      this.loading = false;
      this.mainEngineService.errorHandler(error);
    });
  }



  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.contenutiService.sendItem(this.THIS_ID, this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.router.navigate(['/']);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel('/');
  }
}
