import { Routes } from '@angular/router';
import { UsersDettaglioComponent } from 'src/app/components/users/users-dettaglio/users-dettaglio.component';
import { UsersElencoComponent } from 'src/app/components/users/users-elenco/users-elenco.component';
import { MostreDettaglioComponent } from 'src/app/pages/mostre/mostre-dettaglio/mostre-dettaglio.component';
import { MostreElencoComponent } from 'src/app/pages/mostre/mostre-elenco/mostre-elenco.component';
import { MostreProdottiComponent } from 'src/app/pages/mostre/mostre-prodotti/mostre-prodotti.component';
import { MuseiDettaglioComponent } from 'src/app/pages/musei/musei-dettaglio/musei-dettaglio.component';
import { MuseiElencoComponent } from 'src/app/pages/musei/musei-elenco/musei-elenco.component';
import { MuseiFotogalleryComponent } from 'src/app/pages/musei/musei-fotogallery/musei-fotogallery.component';
import { MuseiMaxipulsantiComponent } from 'src/app/pages/musei/musei-maxipulsanti/musei-maxipulsanti.component';
import { MuseiProdottiComponent } from 'src/app/pages/musei/musei-prodotti/musei-prodotti.component';
import { MuseiSliderComponent } from 'src/app/pages/musei/musei-slider/musei-slider.component';
import { ProdottiMagazzinoComponent } from 'src/app/pages/prodotti/prodotti-magazzino/prodotti-magazzino.component';
import { ProdottiCategorieComponent } from 'src/app/pages/prodotti/prodotti-categorie/prodotti-categorie.component';
import { ProdottiDettaglioComponent } from 'src/app/pages/prodotti/prodotti-dettaglio/prodotti-dettaglio.component';
import { ProdottiElencoComponent } from 'src/app/pages/prodotti/prodotti-elenco/prodotti-elenco.component';
import { ProdottiFotogalleryComponent } from 'src/app/pages/prodotti/prodotti-fotogallery/prodotti-fotogallery.component';
import { ProdottiVariantiComponent } from 'src/app/pages/prodotti/prodotti-varianti/prodotti-varianti.component';
import { VariantiDettaglioComponent } from 'src/app/pages/prodotti/varianti-dettaglio/varianti-dettaglio.component';
import { VariantiElencoComponent } from 'src/app/pages/prodotti/varianti-elenco/varianti-elenco.component';
import { TemporaryshopDettaglioComponent } from 'src/app/pages/temporaryshop/temporaryshop-dettaglio/temporaryshop-dettaglio.component';
import { TemporaryshopElencoComponent } from 'src/app/pages/temporaryshop/temporaryshop-elenco/temporaryshop-elenco.component';
import { TemporaryshopProdottiComponent } from 'src/app/pages/temporaryshop/temporaryshop-prodotti/temporaryshop-prodotti.component';
import { ProdottiPrezziComponent } from 'src/app/pages/prodotti/prodotti-prezzi/prodotti-prezzi.component';
import { SceltaProdottiComponent } from 'src/app/pages/common/assegna-prodotti/scelta-prodotti/scelta-prodotti.component';
import { SliderHomeComponent } from 'src/app/pages/homePage/slider-home/slider-home.component';
import { ChiSiamoComponent } from 'src/app/pages/chi-siamo/chi-siamo.component';
import { BlogElencoComponent } from 'src/app/pages/blog/blog-elenco/blog-elenco.component';
import { BlogDettaglioComponent } from 'src/app/pages/blog/blog-dettaglio/blog-dettaglio.component';
import { TestiSezioniComponent } from 'src/app/pages/testi-sezioni/testi-sezioni.component';
import { FaqElencoComponent } from 'src/app/pages/faq/faq-elenco/faq-elenco.component';
import { FaqDettaglioComponent } from 'src/app/pages/faq/faq-dettaglio/faq-dettaglio.component';
import { ContattiComponent } from 'src/app/pages/contatti/contatti.component';
import { CostiConsegnaElencoComponent } from 'src/app/pages/_ecommerce/gestioneConsegne/costiConsegna/costi-consegna-elenco/costi-consegna-elenco.component';
import { CostiConsegnaSchedaComponent } from 'src/app/pages/_ecommerce/gestioneConsegne/costiConsegna/costi-consegna-scheda/costi-consegna-scheda.component';
import { TipologieConsegnaElencoComponent } from 'src/app/pages/_ecommerce/gestioneConsegne/tipologieConsegna/tipologie-consegna-elenco/tipologie-consegna-elenco.component';
import { TipologieConsegnaSchedaComponent } from 'src/app/pages/_ecommerce/gestioneConsegne/tipologieConsegna/tipologie-consegna-scheda/tipologie-consegna-scheda.component';
import { ZoneConsegnaElencoComponent } from 'src/app/pages/_ecommerce/gestioneConsegne/zoneConsegna/zone-consegna-elenco/zone-consegna-elenco.component';
import { ZoneConsegnaSchedaComponent } from 'src/app/pages/_ecommerce/gestioneConsegne/zoneConsegna/zone-consegna-scheda/zone-consegna-scheda.component';
import { MetodiPagamentoElencoComponent } from 'src/app/pages/_ecommerce/metodiPagamento/metodi-pagamento-elenco/metodi-pagamento-elenco.component';
import { MetodiPagamentoSchedaComponent } from 'src/app/pages/_ecommerce/metodiPagamento/metodi-pagamento-scheda/metodi-pagamento-scheda.component';
import { OpzioniHomeComponent } from 'src/app/pages/homePage/opzioni-home/opzioni-home.component';
import { SocialsComponent } from 'src/app/components/socials/socials.component';
import { AnagraficaElencoComponent } from 'src/app/pages/_ecommerce/anagrafica/anagrafica-elenco/anagrafica-elenco.component';
import { AnagraficaSchedaComponent } from 'src/app/pages/_ecommerce/anagrafica/anagrafica-scheda/anagrafica-scheda.component';
import { OrdiniElencoComponent } from 'src/app/pages/_ecommerce/ordini/ordini-elenco/ordini-elenco.component';
import { OrdiniSchedaComponent } from 'src/app/pages/_ecommerce/ordini/ordini-scheda/ordini-scheda.component';
import { CodiciScontoElencoComponent } from 'src/app/pages/_ecommerce/codiciSconto/codici-sconto-elenco/codici-sconto-elenco.component';
import { CodiciScontoSchedaComponent } from 'src/app/pages/_ecommerce/codiciSconto/codici-sconto-scheda/codici-sconto-scheda.component';
import { ImpostazioniComponent } from 'src/app/pages/_ecommerce/impostazioni/impostazioni.component';
import { ExportComponent } from 'src/app/pages/export/export.component';
import { ProfiloComponent } from 'src/app/components/profilo/profilo.component';
import { DashboardComponent } from 'src/app/pages/dashboard/dashboard.component';

export const MainLayoutRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'home',
    component: DashboardComponent
  },
  {
    path: 'homepage/slider/:id',
    component: SliderHomeComponent
  },
  {
    path: 'homepage/slider',
    component: SliderHomeComponent
  },
  {
    path: 'homepage/opzioni',
    component: OpzioniHomeComponent
  },
  {
    path: 'chiSiamo',
    component: ChiSiamoComponent
  },
  {
    path: 'contatti',
    component: ContattiComponent
  },
  {
    path: 'testiSezioni',
    component: TestiSezioniComponent
  },
  {
    path: 'blog/lastItem/:id',
    component: BlogElencoComponent
  },
  {
    path: 'blog',
    component: BlogElencoComponent
  },
  {
    path: 'blog/:id',
    component: BlogDettaglioComponent
  },
  {
    path: 'faq/lastItem/:id',
    component: FaqElencoComponent
  },
  {
    path: 'faq',
    component: FaqElencoComponent
  },
  {
    path: 'faq/:id',
    component: FaqDettaglioComponent
  },
  {
    path: 'mostre/lastItem/:id',
    component: MostreElencoComponent
  },
  {
    path: 'mostre',
    component: MostreElencoComponent
  },
  {
    path: 'mostre/:id',
    component: MostreDettaglioComponent
  },
  {
    path: 'mostre/:idPadre/prodotti/:azione',
    component: MostreProdottiComponent
  },
  {
    path: 'musei',
    component: MuseiElencoComponent
  },
  {
    path: 'musei/lastItem/:id',
    component: MuseiElencoComponent
  },
  {
    path: 'musei/:id',
    component: MuseiDettaglioComponent
  },
  {
    path: 'musei/:idPadre/fotogallery',
    component: MuseiFotogalleryComponent
  },
  {
    path: 'musei/:idPadre/slider/:id',
    component: MuseiSliderComponent
  },
  {
    path: 'musei/:idPadre/slider',
    component: MuseiSliderComponent
  },
  {
    path: 'musei/:idPadre/maxipulsanti/:id',
    component: MuseiMaxipulsantiComponent
  },
  {
    path: 'musei/:idPadre/maxipulsanti',
    component: MuseiMaxipulsantiComponent
  },
  {
    path: 'musei/:idPadre/prodotti/:azione/lastItem/:id',
    component: MuseiProdottiComponent
  },
  {
    path: 'musei/:idPadre/prodotti/:azione',
    component: MuseiProdottiComponent
  },
  {
    path: 'temporaryshop',
    component: TemporaryshopElencoComponent
  },
  {
    path: 'temporaryshop/lastItem/:id',
    component: TemporaryshopElencoComponent
  },
  {
    path: 'temporaryshop/:id',
    component: TemporaryshopDettaglioComponent
  },
  {
    path: 'temporaryshop/:idPadre/prodotti/:azione/lastItem/:id',
    component: TemporaryshopProdottiComponent
  },
  {
    path: 'temporaryshop/:idPadre/prodotti/:azione',
    component: TemporaryshopProdottiComponent
  },
  {
    path: 'prodotti',
    component: ProdottiElencoComponent
  },
  {
    path: 'prodotti/lastItem/:id',
    component: ProdottiElencoComponent
  },
  {
    path: 'prodotti/categorie',
    component: ProdottiCategorieComponent
  },
  {
    path: 'prodotti/categorie/lastItem/:id',
    component: ProdottiCategorieComponent
  },
  {
    path: 'prodotti/categorie/:id',
    component: ProdottiCategorieComponent
  },
  {
    path: 'prodotti/categorie/:idCategoria/sottocategorie',
    component: ProdottiCategorieComponent
  },
  {
    path: 'prodotti/categorie/:idCategoria/sottocategorie/lastItem/:id',
    component: ProdottiCategorieComponent
  },
  {
    path: 'prodotti/categorie/:idCategoria/sottocategorie/:id',
    component: ProdottiCategorieComponent
  },
  {
    path: 'prodotti/:id',
    component: ProdottiDettaglioComponent
  },
  {
    path: 'prodotti/:idPadre/fotogallery',
    component: ProdottiFotogalleryComponent
  },
  {
    path: 'prodotti/:idPadre/varianti',
    component: ProdottiVariantiComponent
  },
  {
    path: 'prodotti/:idPadre/varianti/:idTipologiaVariante',
    component: VariantiElencoComponent
  },
  {
    path: 'prodotti/:idPadre/varianti/:idTipologiaVariante/:id',
    component: VariantiDettaglioComponent
  },
  {
    path: 'prodotti/:idPadre/magazzino',
    component: ProdottiMagazzinoComponent
  },
  {
    path: 'prodotti/:idPadre/prezzi',
    component: ProdottiPrezziComponent
  },
  {
    path: 'users',
    component: UsersElencoComponent
  },
  {
    path: 'users/lastItem/:id',
    component: UsersElencoComponent
  },
  {
    path: 'users/:id',
    component: UsersDettaglioComponent
  },
  {
    path: 'socials',
    component: SocialsComponent
  },
  {
    path: 'export',
    component: ExportComponent
  },
  {
    path: 'tipologieConsegna/:idPadre/costiConsegna',
    component: CostiConsegnaElencoComponent
  },
  {
    path: 'tipologieConsegna/:idPadre/costiConsegna/lastItem/:id',
    component: CostiConsegnaElencoComponent
  },
  {
    path: 'tipologieConsegna/:idPadre/costiConsegna/:id',
    component: CostiConsegnaSchedaComponent
  },
  {
    path: 'tipologieConsegna',
    component: TipologieConsegnaElencoComponent
  },
  {
    path: 'tipologieConsegna/lastItem/:id',
    component: TipologieConsegnaElencoComponent
  },
  {
    path: 'tipologieConsegna/:id',
    component: TipologieConsegnaSchedaComponent
  },
  {
    path: 'zoneConsegna',
    component: ZoneConsegnaElencoComponent
  },
  {
    path: 'zoneConsegna/lastItem/:id',
    component: ZoneConsegnaElencoComponent
  },
  {
    path: 'zoneConsegna/:id',
    component: ZoneConsegnaSchedaComponent
  },
  {
    path: 'metodiPagamento',
    component: MetodiPagamentoElencoComponent
  },
  {
    path: 'metodiPagamento/lastItem/:id',
    component: MetodiPagamentoElencoComponent
  },
  {
    path: 'metodiPagamento/:id',
    component: MetodiPagamentoSchedaComponent
  },
  {
    path: 'anagrafica',
    component: AnagraficaElencoComponent
  },
  {
    path: 'anagrafica/lastItem/:id',
    component: AnagraficaElencoComponent
  },
  {
    path: 'anagrafica/:id',
    component: AnagraficaSchedaComponent
  },
  {
    path: 'ordini',
    component: OrdiniElencoComponent
  },
  {
    path: 'ordini/lastItem/:id',
    component: OrdiniElencoComponent
  },
  {
    path: 'ordini/:id',
    component: OrdiniSchedaComponent
  },
  {
    path: 'codiciSconto',
    component: CodiciScontoElencoComponent
  },
  {
    path: 'codiciSconto/lastItem/:id',
    component: CodiciScontoElencoComponent
  },
  {
    path: 'codiciSconto/:id',
    component: CodiciScontoSchedaComponent
  },
  {
    path: 'impostazioni',
    component: ImpostazioniComponent
  },
  {
    path: 'profilo',
    component: ProfiloComponent
  },
];
