import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FaqService } from '../faq.service';

@Component({
  selector: 'app-faq-elenco',
  templateUrl: './faq-elenco.component.html',
  styleUrls: ['./faq-elenco.component.scss']
})
export class FaqElencoComponent implements OnInit {
  myService: any;
  columns: any[any];
  options: any[any];
  lastId: number = 0;

  constructor(
    private faqService: FaqService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(
    (params) => {
      if (params.id) {
        this.lastId = params.id;
      }

      this.myService = this.faqService;

      this.columns = [
        { columnDef: 'domanda', header: 'Domanda',    cell: (element: any) => `${element.domanda}` },
      ];
      this.options = {
        linkBase: this.faqService.linkBase,
      }
    });
  }

ngOnInit(): void {
}
}
