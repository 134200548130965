import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Prodotto } from './prodotto';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';
import { Categoria } from 'src/app/components/elenco/categorie/categoria';

@Injectable({
  providedIn: 'root'
})
export class ProdottiService {
  apiUrl: string;
  linkBase: string = "prodotti";
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;
  arrayRicerca: any[any] = [];
  arrayBackupRicerca: any[any] = [];
  keepBackupRicerca: boolean = false;
  fromRicerca: boolean = false;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + this.linkBase + "/";
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');
  }

  setParametriRicerca(formValue: any[]) {
//    console.log("setParametriRicerca");
    this.arrayRicerca = formValue;
    this.fromRicerca = true;
    this.keepBackupRicerca = false;
  }

  getParametriRicerca() {
    return this.arrayBackupRicerca;
  }

  getElenco(): Observable<any> {
    let dummy = "";
    if (this.keepBackupRicerca) {
      dummy = this.arrayBackupRicerca;
    }
    else {
      if (this.fromRicerca) {
        dummy = this.arrayRicerca;
        this.fromRicerca = false;
        this.keepBackupRicerca = true;
        this.arrayBackupRicerca = this.arrayRicerca;
        this.arrayRicerca = [];
      }
      else {
        dummy = null;
        this.arrayBackupRicerca = [];
      }
    }

    return this.http.patch<any>(this.apiUrl, dummy);
  }


  getElencoProdottiPerMostre(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elencoProdottiPerMostre');
  }

  getElencoProdottiPerOrdine(idSottocategoria: number = 0): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elencoProdottiPerOrdine?idSottocategoria=' + idSottocategoria);
  }

  getProdottoPerOrdine(idProdotto: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/prodottoPerOrdine?id=' + idProdotto);
  }

  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + 'op/opzioni');
  }

  getElencoSitiAppartenenza(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elencositiappartenenza');
  }

  getElencoSottocategorie(): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'op/elencosottocategorie');
  }

  getItem(id: number): Observable<Prodotto> {
    return this.http.get<Prodotto>(this.apiUrl + id)
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  deleteItem(id: number) {
    return this.http.delete<Prodotto>(this.apiUrl + id)
  }


  getElencoCategorie(): Observable<Categoria[]> {
    return this.http.get<Categoria[]>(this.apiUrl + 'op/categorie');
  }
}
