import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { DashboardService } from 'src/app/service/dashboard.service';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  myForm: FormGroup;
  arrayCampi: any[any];
  arrayPulsanti: any[any];
  loading = true;
  stats: any[any];
  provenienzaNazioni: any;

  constructor(
    private formComponentsService: FormInputService,
    private notificationsService: NotificationsService,
    private mainEngineService: MainEngineService,
    private service: DashboardService
  ) {
    this.arrayCampi = {
      'periodo' : {'id' : 'periodo', 'nome': 'Periodo di Calcolo', 'type': 'PER', 'required': false, 'fullWidth' : true},
    };

    this.arrayPulsanti = {
      'calcola' : {'type' : 'cerca', 'testo' : 'Calcola', 'size' : 'L'},
    };
  }

  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);

    var data = new Date();
    var dd = String(data.getDate()).padStart(2, '0');
    var mm = String(data.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = data.getFullYear();
    let periodo_al = yyyy + '-' + mm + '-' + dd;

    var data = new Date(data.setMonth(data.getMonth()-1));
    var dd = String(data.getDate()).padStart(2, '0');
    var mm = String(data.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = data.getFullYear();
    let periodo_dal = yyyy + '-' + mm + '-' + dd;

    let defValue = {'periodo_dal' : periodo_dal, 'periodo_al': periodo_al};

    this.myForm.patchValue(defValue);

    this.ricalcola();
  }

  ricalcola() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    if ((this.myForm.get('periodo_dal').value == '') && (this.myForm.get('periodo_al').value == '')) {
      this.notificationsService.show('error', 'Errore', 'Compilare almeno uno dei 2 campi del periodo');
      return;
    }

    this.loading = true;
    this.formComponentsService.activateLoader(true);

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.stats = response;
        this.provenienzaNazioni = {
          series: [
            {
              name: "Numero di ordini",
              data: this.stats.provenienzaNazione.occorrenza
            }
          ],
          chart: {
            height: 350,
            type: "bar"
          },
          title: {
            text: "Provenienza ordini per stato"
          },
          xaxis: {
            categories: this.stats.provenienzaNazione.stato
          }
        };
        this.loading = false;
        this.formComponentsService.activateLoader(false);
      },
      (error) => {
        this.formComponentsService.activateLoader(false);
        this.mainEngineService.errorHandler(error);
      }
    );
  }

}
