import { MenuItem } from "src/app/components/modules/menu.model";

export const MENU: MenuItem[] = [
    {
      label: 'Dashboard',
      icon: 'fas fa-home',
      link: '/',
      strict: true
    },
    {
      label: 'SEZIONI',
      isTitle: true
    },
    {
      label: 'Homepage',
      icon: 'fas fa-home',
      subItems: [
        {
          label: 'Slider',
          icon: 'far fa-images',
          link: '/homepage/slider',
        },
        {
          label: 'Opzioni',
          icon: 'fas fa-cogs',
          link: '/homepage/opzioni',
        },
      ]
    },
    {
      label: 'Chi siamo',
      icon: 'fas fa-user-friends',
      link: '/chiSiamo',
    },
    {
      label: 'Mostre',
      icon: 'fas fa-list-ul',
      link: '/mostre',
    },
    {
      label: 'Musei',
      icon: 'fas fa-list-ul',
      link: '/musei',
    },
    {
      label: 'Temporary shop',
      icon: 'fas fa-list-ul',
      link: '/temporaryshop',
    },
    {
      label: 'Prodotti',
      icon: 'fas fa-list-ul',
      link: '/prodotti',
    },
    {
      label: 'Blog',
      icon: 'fas fa-blog',
      link: '/blog',
    },
    {
      label: 'Testi sezioni',
      icon: 'fas fa-file-alt',
      link: '/testiSezioni',
    },
    {
      label: 'FAQ',
      icon: 'fas fa-question-circle',
      link: '/faq',
    },
    {
      label: 'Contatti',
      icon: 'fas fa-address-card',
      link: '/contatti',
    },

    /*
    {
      id: 8,
      label: 'BLOG',
      isTitle: true
    },
    {
      id: 9,
      label: 'Articoli',
      icon: 'fas fa-list-ul',
      link: '/blog/articoli',
    },
    {
    id: 10,
    label: 'Gestione',
    icon: 'fas fa-cogs',
    subItems: [
        {
          id: 11,
          label: 'Categorie',
          link: '/blog/categorie',
          parentId: 10
        },
        {
          id: 12,
          label: 'Autori',
          link: '/blog/autori',
          parentId: 10
        },
        {
          id: 13,
          label: 'Tags',
          link: '/blog/tags',
          parentId: 10
        },
      ]
    },
*/
    {
      label: 'GESTIONE',
      isTitle: true
    },
    {
      label: 'Utenti',
      icon: 'fas fa-user-tie',
      link: '/users',
    },
    {
      label: 'Pagine social',
      icon: 'fas fa-share-alt-square',
      link: '/socials',
    },
    {
      label: 'Export',
      icon: 'fas fa-file-export',
      link: '/export',
    },
    {
      label: 'ECOMMERCE',
      isTitle: true
    },
    {
      label: 'Anagrafica',
      icon: 'fas fa-users',
      link: '/anagrafica',
    },
    {
      label: 'Ordini',
      icon: 'fas fa-shopping-cart',
      link: '/ordini',
    },
    {
      label: 'Codici sconto',
      icon: 'fas fa-ticket-alt',
      link: '/codiciSconto',
    },
    {
      label: 'Modalità di consegna',
      icon: 'fas fa-truck',
      link: '/tipologieConsegna',
    },
    {
      label: 'Metodi di pagamento',
      icon: 'far fa-credit-card',
      link: '/metodiPagamento',
    },
    {
      label: 'Impostazioni',
      icon: 'fas fa-cogs',
      link: '/impostazioni',
    },
];

