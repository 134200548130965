import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { Categoria } from 'src/app/components/elenco/categorie/categoria';
import { CampiSeoService } from 'src/app/components/scheda/campi-seo/campi-seo.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ProdottiService } from '../../prodotti/prodotti.service';
import { Prodotto } from '../prodotto';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-prodotti-dettaglio',
  templateUrl: './prodotti-dettaglio.component.html',
  styleUrls: ['./prodotti-dettaglio.component.scss']
})
export class ProdottiDettaglioComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: Prodotto;
  titoloScheda: string = "Caricamento...";
  arrayCategorie: Categoria[];
  linkBase: string;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private prodottiService: ProdottiService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private campiSeoService: CampiSeoService,
    private componentsService: ComponentsService,
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'codice' : {'id' : 'codice', 'nome': 'Codice prodotto', 'readonly': true},
      'EAN13' : {'id' : 'EAN13', 'nome': 'EAN13', 'readonly': true},
      'titolo' : {'id' : 'titolo', 'nome': 'Titolo', 'readonly': true},
      'titoloCustom' : {'id' : 'titoloCustom', 'nome': 'Titolo alternativo', 'note': 'Se inserito, verrà utilizzato al posto del campo "Titolo"'},
      'sottotitolo' : {'id' : 'sottotitolo', 'nome': 'Sottotitolo'},
      'idSottocategoria' : {'id' : 'idSottocategoria', 'nome': 'Categoria', 'required': true, 'type': 'NUM'},
      'immagine' : {'id' : 'immagine', 'nome': 'Immagine', 'immagineAttuale': '', 'type': 'IMG', 'required': false},
      'prezzo' : {'id' : 'prezzo', 'nome': 'Prezzo', 'readonly': true},
      'prezzoCustom' : {'id' : 'prezzoCustom', 'nome': 'Prezzo alternativo', 'note': 'Se inserito, verrà utilizzato al posto del campo "Prezzo"'},
      'descrizione' : {'id' : 'abstract', 'nome': 'Descrizione', 'readonly': true},
      'descrizioneCustom' : {'id' : 'descrizioneCustom', 'nome': 'Descrizione alternativa', 'note': 'Se inserita, verrà utilizzata al posto del campo "Descrizione"', 'type': 'RTX'},
      'dettagli' : {'id' : 'dettagli', 'nome': 'Dettagli', 'type': 'RTX'},
      'note' : {'id' : 'note', 'nome': 'Note', 'type': 'RTX'},
      'autore' : {'id' : 'autore', 'nome': 'Autore', 'readonly': true},
      'editore' : {'id' : 'editore', 'nome': 'Editore', 'readonly': true},
      'pagine' : {'id' : 'pagine', 'nome': 'Pagine'},
      'annoPP' : {'id' : 'annoPP', 'nome': 'Anno di pubblicazione', 'readonly': true},
      'nascondiEditore' : {'id' : 'nascondiEditore', 'nome': 'Nascondi campo editore', 'type': 'BOL'},
      'nascondiEAN' : {'id' : 'nascondiEAN', 'nome': 'Nascondi campo EAN', 'type': 'BOL'},
      'potrebbeInteressarti' : {'id' : 'potrebbeInteressarti', 'nome': 'Potrebbe interessarti', 'note': 'Criterio di correlazione per i prodotti proposti nel "potrebbe interessarti"', 'items': Prodotto.POTREBBE_INTERESSARTI, 'type': 'RAD', 'required': false},
      'piuTipologie' : {'id' : 'piuTipologie', 'nome': 'Mostra "sono disponibili più tipologie"', 'type': 'BOL'},
      'new' : {'id' : 'new', 'nome': 'Mostra "new"', 'type': 'BOL'},
    };

    this.arrayCampi = {...this.arrayCampi, ...this.campiSeoService.arrayCampi};

  }

  ngOnInit(): void {

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);
//    this.linkBase = this.prodottiService.linkBase;
    this.linkBase = this.mainEngineService.globalPreviousUrl ? this.mainEngineService.globalPreviousUrl : this.prodottiService.linkBase;

    this.route.params.subscribe(
      (params) => {
        if (params.id != '0') {
          this.linkBase += '/lastItem/' + params.id;

          forkJoin([
            this.prodottiService.getItem(params.id),
            this.prodottiService.getElencoCategorie()
          ])
          .subscribe(
            ([myItem, arrayCategorie]) => {
              myItem.idSottocategoria = myItem.idSottocategoria.toString();
              this.myItem = myItem;
              this.arrayCategorie = arrayCategorie;

              this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

              this.myForm.patchValue(this.myItem);
              this.formComponentsService.activateLoader(false);
              this.titoloScheda = "Modifica elemento - '" + this.myItem._titoloScheda + "'";
            },
            (error) => {
              this.formComponentsService.activateLoader(false);
              this.mainEngineService.errorHandler(error);
              this.componentsService.onBack(this.prodottiService.linkBase);
            });
        }
        else {
          this.formComponentsService.activateLoader(false);
          this.notificationsService.show('error', 'Errore', 'Operazione non permessa');
          this.componentsService.onBack(this.prodottiService.linkBase);
        }
      });
  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      this.notificationsService.show('warning', 'ATTENZIONE', 'Compilare tutti i campi obbligatori');
      return false;
    }

    this.loading = true;

    this.prodottiService.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.linkBase]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.prodottiService.linkBase + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  onSave() {
    this.onSubmit(2);
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }
}
