import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { CostiConsegna } from '../costi-consegna';
import { CostiConsegnaService } from '../costi-consegna.service';
import { GenericItem } from 'src/app/components/class/generic-item';



@Component({
  selector: 'app-costi-consegna-scheda',
  templateUrl: './costi-consegna-scheda.component.html',
  styleUrls: ['./costi-consegna-scheda.component.scss']
})
export class CostiConsegnaSchedaComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  arrayCampiPrezziAggiuntivi: any[any];
  myItem: CostiConsegna;
  titoloScheda: string = "Caricamento...";
  arrayData: any[any];
  linkBase: string;
  arrayZone: GenericItem[];
  items: FormArray;
  numPrezziAggiuntivi = 3;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private service: CostiConsegnaService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'idZona' : {'id' : 'idZona', 'nome': 'Zona', 'required': true, 'type': 'NUM'},
      'idTipologia' : {'id' : 'idTipologia', 'defValue' : 0, 'type': 'NUM'},
      'prezzoBase' : {'id' : 'prezzoBase', 'nome': 'Prezzo base', 'type': 'VLT', 'required': true},
      'numPrezziAggiuntivi' : {'id' : 'numPrezziAggiuntivi', 'defValue' : this.numPrezziAggiuntivi, 'type': 'HID'},
    };

    for (let i = 0; i < this.numPrezziAggiuntivi; i++) {
      let arrayCampiPrezziAggiuntivi = {
        ['prezzoTipologia_' + i] : {'id' : 'prezzoTipologia_' + i, 'nome': 'Prezzo', 'type': 'VLT', 'inputClass': 'col-12', 'shortInput' : false},
        ['da_' + i]  : {'id' : 'da_' + i, 'nome': 'Totale da (valore compreso)', 'type': 'VLT', 'inputClass': 'col-12', 'shortInput' : false},
        ['a_' + i]  : {'id' : 'a_' + i, 'nome': 'Totale a (valore escluso)', 'type': 'VLT', 'inputClass': 'col-12', 'shortInput' : false},
      }

      this.arrayCampi = {...this.arrayCampi, ...arrayCampiPrezziAggiuntivi};
    }
  }


  ngOnInit(): void {

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);

    this.route.params.subscribe(
      (params) => {
        this.service.setIdPadre(params.idPadre);
        this.linkBase = this.service.linkBase;

        this.myForm.patchValue({'idTipologia': params.idPadre});
        this.myForm.patchValue({'id': params.id});

        this.service.getElencoZone(params.id)
        .subscribe(data => {
          this.arrayZone = data;

          if (params.id != '0') {
            this.linkBase += '/lastItem/' + params.id;
            this.service.getItem(params.id)
            .subscribe(data => {
              this.myItem = data;
              this.myItem.idZona = '' + this.myItem.idZona;

              this.formComponentsService.activateLoader(false);
              this.titoloScheda = "Modifica elemento - '" + this.myItem._titoloScheda + "'";
              this.myForm.patchValue(this.myItem);
              this.formComponentsService.setDisabled(this.myForm, "idZona");
              this.myForm.markAsPristine();
            },
            (error) => {
              this.loading = false;
              this.mainEngineService.errorHandler(error);
            });
          }
          else {
            this.titoloScheda = "Inserimento elemento";
            this.formComponentsService.activateLoader(false);
          }
        },
        (error) => {
          this.loading = false;
          this.mainEngineService.errorHandler(error);
        })
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      });
  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    let arrayIntervalli = Array();

    for (let i = 0; i < this.numPrezziAggiuntivi; i++) {
      if ((!this.formComponentsService.checkEmptyInput(this.myForm, 'prezzoTipologia_' + i)) ||
        (!this.formComponentsService.checkEmptyInput(this.myForm, 'da_' + i)) ||
        (!this.formComponentsService.checkEmptyInput(this.myForm, 'a_' + i))
      ) {

        if (this.formComponentsService.checkEmptyInput(this.myForm, 'prezzoTipologia_' + i)) {
          this.notificationsService.show('error', '', 'Per la variazione #' + (i + 1) + ' non è stato inserito il prezzo');
          return;
        }

        if (this.formComponentsService.checkEmptyInput(this.myForm, 'da_' + i)) {
          this.notificationsService.show('error', '', 'Per la variazione #' + (i + 1) + ' non è stato inserito il valore del "Totale da"');
          return;
        }

        if (!this.formComponentsService.checkEmptyInput(this.myForm, 'a_' + i) &&
          (+this.formComponentsService.getInputValue(this.myForm, 'da_' + i) >= +this.formComponentsService.getInputValue(this.myForm, 'a_' + i))
        ) {
          this.notificationsService.show('error', '', 'Per la variazione #' + (i + 1) + ' l\'intervallo "da" - "a" non è valido');
          return;
        }

        arrayIntervalli.push({'da' :  +this.formComponentsService.getInputValue(this.myForm, 'da_' + i), 'a' : +this.formComponentsService.getInputValue(this.myForm, 'a_' + i)});
      }

      for(let i=0; i < arrayIntervalli.length; i++){
        for(let j=0; j < arrayIntervalli.length; j++){
          if (i == j) {
            continue;
          }
          if (
            (!arrayIntervalli[j].a && !arrayIntervalli[i].a)
            ||
            ((arrayIntervalli[i].da >= arrayIntervalli[j].da) &&
            ((arrayIntervalli[i].da < arrayIntervalli[j].a) || !arrayIntervalli[j].a) )
            ||
            (((arrayIntervalli[i].a > arrayIntervalli[j].da) || !arrayIntervalli[i].a) &&
            ((arrayIntervalli[i].a != false) && ((arrayIntervalli[i].a < arrayIntervalli[j].a) || !arrayIntervalli[j].a)))
          ) {
            this.notificationsService.show('error', '', 'Le variazioni #' + (i + 1) + ' e #' + (j + 1) + ' risultano sovrapposte nei valori dei totali');
            return;
          }
        }

      }
    }

    this.loading = true;


    this.service.sendItem(this.myForm.getRawValue())  // comprende anche gli input disabled
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.linkBase]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.service.linkBase + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }

  onSave() {
    this.onSubmit(2);
  }

}
