import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { CampiSeoService } from 'src/app/components/scheda/campi-seo/campi-seo.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MetodoPagamento } from '../metodo-pagamento';
import { MetodiPagamentoService } from '../metodi-pagamento.service';

@Component({
  selector: 'app-metodi-pagamento-scheda',
  templateUrl: './metodi-pagamento-scheda.component.html',
  styleUrls: ['./metodi-pagamento-scheda.component.scss']
})
export class MetodiPagamentoSchedaComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  arrayCampiPaypal: any[any];
  arrayCampiPagonline: any[any];
  arrayCampiStripe: any[any];
  myItem: MetodoPagamento;
  titoloScheda: string = "Caricamento...";
  arrayData: any[any];
  linkBase: string;
  schedaPayPal: boolean = false;
  schedaPagonline: boolean = false;
  schedaStripe: boolean = false;
  checked_pagonlineUnicredit_SANDBOX: boolean = false;
  checked_stripe_SANDBOX: boolean = false;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private service: MetodiPagamentoService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'titolo' : {'id' : 'titolo', 'nome': 'Denominazione', 'required': true},
      'descrizione' : {'id' : 'descrizione', 'nome': 'Descrizione', 'type': 'TXA'},
      'costo' : {'id' : 'costo', 'nome': 'Costo per il cliente', 'type': 'VLT'},
    };

    this.arrayCampiPaypal = {
      'PayPal_USERNAME' : {'id' : 'PayPal_USERNAME', 'nome': 'API username', 'required': true},
      'PayPal_PASSWORD' : {'id' : 'PayPal_PASSWORD', 'nome': 'API password', 'required': true},
      'PayPal_SIGNATURE' : {'id' : 'PayPal_SIGNATURE', 'nome': 'API signature', 'required': true},
    };

    this.arrayCampiPagonline = {
      'pagonlineUnicredit_SANDBOX' : {'id' : 'pagonlineUnicredit_SANDBOX', 'nome': 'Abilita Sandbox (Da utilizzare solo per debug del sistema)', 'required': false},
      'pagonlineUnicredit_SERVERURL' : {'id' : 'pagonlineUnicredit_SERVERURL', 'nome': 'API Endpoint URL', 'required': true},
      'pagonlineUnicredit_TID' : {'id' : 'pagonlineUnicredit_TID', 'nome': 'API Terminal ID', 'required': true},
      'pagonlineUnicredit_KSIG' : {'id' : 'pagonlineUnicredit_KSIG', 'nome': 'API key', 'required': true},
    };

    this.arrayCampiStripe = {
      'stripe_SANDBOX' : {'id' : 'stripe_SANDBOX', 'nome': 'Abilita Sandbox (Da utilizzare solo per debug del sistema)', 'required': false},
      'stripe_KEY' : {'id' : 'stripe_KEY', 'nome': 'Chiave privata API', 'required': true},
    };
  }

  ngOnInit(): void {

    this.formComponentsService.activateLoader(true);
    this.linkBase = this.service.linkBase;

    this.route.params.subscribe(
      (params) => {
        if (params.id == '1') {
          this.schedaPayPal = true;
          this.arrayCampi = {...this.arrayCampi, ...this.arrayCampiPaypal};
        }
/*
        else if (params.id == '2') {
          this.schedaPagonline = true;
          this.arrayCampi = {...this.arrayCampi, ...this.arrayCampiPagonline};
        }
*/
        else if (params.id == '2') {
          this.schedaStripe = true;
          this.arrayCampi = {...this.arrayCampi, ...this.arrayCampiStripe};
        }
        this.myForm = this.formComponentsService.createForm(this.arrayCampi);

        if (params.id != '0') {
          this.linkBase += '/lastItem/' + params.id;
          this.service.getItem(params.id)
          .subscribe(data => {
            this.myItem = data;

            this.formComponentsService.activateLoader(false);
            this.titoloScheda = "Modifica elemento - '" + this.myItem._titoloScheda + "'";
            this.myForm.patchValue(this.myItem);
            this.myForm.markAsPristine();
            if (params.id == 2) {
              this.checked_stripe_SANDBOX = this.myItem.stripe_SANDBOX;
              this.onClick_stripe_SANDBOX(this.checked_stripe_SANDBOX);
            }
/*
            this.checked_pagonlineUnicredit_SANDBOX = this.myItem.pagonlineUnicredit_SANDBOX;
            this.onClick_pagonlineUnicredit_SANDBOX(this.checked_pagonlineUnicredit_SANDBOX);
*/
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
          });
        }
        else {
          this.titoloScheda = "Inserimento elemento";
          this.formComponentsService.activateLoader(false);
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      });
  }


  onClick_pagonlineUnicredit_SANDBOX(checked) {
    this.checked_pagonlineUnicredit_SANDBOX = checked;
    if (checked) {
      this.myForm.get('pagonlineUnicredit_SERVERURL').clearValidators();
      this.myForm.get('pagonlineUnicredit_KSIG').clearValidators();
      this.myForm.get('pagonlineUnicredit_TID').clearValidators();
    }
    else {
      this.myForm.get('pagonlineUnicredit_SERVERURL').setValidators([Validators.required]);
      this.myForm.get('pagonlineUnicredit_KSIG').setValidators([Validators.required]);
      this.myForm.get('pagonlineUnicredit_TID').setValidators([Validators.required]);
    }
    this.myForm.get('pagonlineUnicredit_SERVERURL').updateValueAndValidity();
    this.myForm.get('pagonlineUnicredit_KSIG').updateValueAndValidity();
    this.myForm.get('pagonlineUnicredit_TID').updateValueAndValidity();
  }

  onClick_stripe_SANDBOX(checked) {
    this.checked_stripe_SANDBOX = checked;
    if (checked) {
      this.myForm.get('stripe_KEY').clearValidators();
    }
    else {
      this.myForm.get('stripe_KEY').setValidators([Validators.required]);
    }
    this.myForm.get('stripe_KEY').updateValueAndValidity();
  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.linkBase]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.service.linkBase + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }

  onSave() {
    this.onSubmit(2);
  }

}
