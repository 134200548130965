import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ProdottiMagazzinoService } from '../prodotti-magazzino.service';

@Component({
  selector: 'app-prodotti-magazzino',
  templateUrl: './prodotti-magazzino.component.html',
  styleUrls: ['./prodotti-magazzino.component.scss', '../../../components/elenco/elenco.component.scss'],
})
export class ProdottiMagazzinoComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  titoloScheda: string = "Caricamento...";
  backUrl = '';
  idPadre: number = 0;
  numVarianti: number = 0;
  data: any;
  arrayPulsanti: any[any];
  titoloGestioneGiacenza = '';
  arrayTitoliGestioneGiacenza: any[any];
  tipologiaAzione: number = 0;
  arrayAddLabelGiacenza: any[any];
  myService: any;
  columns: any[any];
  dataSource: MatTableDataSource<any>;
  displayedColumns: any[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  elencoMovimenti: boolean = false;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService,
    private magazzinoService: ProdottiMagazzinoService,
    private buttonService: ButtonService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.myService = this.magazzinoService;

    this.arrayCampi = {
      'idPadre' : {'id' : 'idPadre', 'type': 'NUM'},
      'tipologiaAzione' : {'id' : 'tipologiaAzione', 'value': 0, 'type': 'HID'},
      'note' : {'id' : 'note', 'nome': 'Note'},
      'data' : {'id' : 'data', 'nome': 'Data', 'type': 'DTA'},
    }
    this.arrayPulsanti = {
      'giacenzaAttuale' : {'type' : 'custom', 'testo' : 'Giacenza attuale', 'colorClass' : 'info', 'icon': 'bx bxs-store', 'actionTooltip' : 'Mostra giacenza attuale', 'ML' : true, 'mainClass' : 'btn-sm'},
      'carico' : {'type' : 'custom', 'testo' : 'Carico', 'colorClass' : 'info', 'icon': 'bx bx-upload', 'actionTooltip' : 'Carico in magazzino', 'ML' : true, 'mainClass' : 'btn-sm'},
      'scarico' : {'type' : 'custom', 'testo' : 'Scarico', 'colorClass' : 'info', 'icon': 'bx bx-download', 'actionTooltip' : 'Scarico da magazzino', 'ML' : true, 'mainClass' : 'btn-sm'},
      'modificaGiacenza' : {'type' : 'custom', 'testo' : 'Modifica manuale', 'colorClass' : 'info', 'icon': ' bx bxs-edit', 'actionTooltip' : 'Modifica manualmente la giacenza', 'ML' : true, 'mainClass' : 'btn-sm'},
      'delete' : {'type' : 'delete', 'hideText': false},
      'cantDelete' : {'type' : 'cantDelete', 'hideText': false},
    };


    this.arrayTitoliGestioneGiacenza = {
      0: 'Giacenza Attuale',
      1: 'Carico magazzino',
      2: 'Scarico magazzino',
      3: 'Modifica manuale',
    }

    this.arrayAddLabelGiacenza = {
      0: '',
      1: '+',
      2: '-',
      3: '=>',
    }
  }

  ngOnInit(): void {
    this.getData();
  }


  setPannelloGiacenza(value) {
    this.tipologiaAzione = value;
    this.myForm.controls.tipologiaAzione.setValue(value);
  }


  getData() {
    this.formComponentsService.activateLoader(true);
    this.route.params.subscribe(
      (params) => {
        this.idPadre = params.idPadre;
        this.backUrl = "/prodotti/lastItem/"+this.idPadre;

        this.magazzinoService.setIdProdotto(this.idPadre)
        this.magazzinoService.getData()
        .subscribe(
          (data) => {
            this.data = data;
            this.titoloScheda = "Gestione magazzino '" + data._titoloScheda + "'";
            var keys = Object.keys(data.items);
            this.numVarianti = keys.length;

            for (let key1 in data.items[1].items) {
              let value1 = data.items[1].items[key1];
              let id1 = value1.id;

              if (this.numVarianti == 2) {
                for (let key2 in data.items[2].items) {
                  let value2 = data.items[2].items[key2];
                  let id2 = value2.id;

                  this.arrayCampi[id1 + '_' + id2] = {'id' : 'valore_' + id1 + '_' + id2, 'nome': 'Quantità', 'type': 'NUM'};
                }
              }
              else {
                this.arrayCampi[id1] = {'id' : 'valore_' + id1, 'nome': 'Quantità', 'type': 'NUM'};
              }
          }
/*
            if (this.options.idPadre != 0) {
              this.arrayCampi['idPadre'] = {'id' : 'idPadre', 'type': 'NUM'};
            }
  */

            this.myForm = this.formComponentsService.createForm(this.arrayCampi);
            this.myForm.controls.idPadre.setValue(this.idPadre);
            this.myForm.controls.data.setValue(new Date());

            this.columns = [
              { columnDef: 'id', header: '#',    cell: (element: any) => `${element.id}` },
              { columnDef: 'data', header: 'Data',    cell: (element: any) => `${element.data}` },
            ];

            for (let i = 1; i <= this.numVarianti; i++) {
              this.columns.push({ columnDef: 'variante' + i, header: this.data.items[i].titolo, cell: (element: any) => `${element['variante' + i]}` });
            }
            this.columns.push({ columnDef: 'variazione', header: 'Var.', class: 'mat-column-right', style: {'width': '80px', 'flex': 'none'}, cell: (element: any) => `${element.variazione}` });
            this.columns.push({ columnDef: 'origine', header: 'Origine', cell: (element: any) => `${element.origine}` });
            this.columns.push({ columnDef: 'note', header: 'Note', cell: (element: any) => `${element.note}` });

            this.displayedColumns = this.columns.map(c => c.columnDef);
            this.displayedColumns.push('actions');
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
            this.router.navigate([this.backUrl]);
          });
          this.formComponentsService.activateLoader(false);
    });
  }


  getElenco(showLoader = true) {
    if (showLoader) {
      this.formComponentsService.activateLoader(true);
    }
    this.magazzinoService.getElenco()
    .subscribe(
      data => {
        this.dataSource = new MatTableDataSource(data.items);

        this.dataSource.paginator = this.paginator;

        this.componentsService.tableFitHeight();

        if (showLoader) {
          this.formComponentsService.activateLoader(false);
        }
      },
      (error) => {
        this.formComponentsService.activateLoader(false);
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  onDelete(id) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        this.loading = true;
        that.magazzinoService.deleteItem(arg)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.getElenco();
          },
          (error) => {
            this.loading = false;
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaElimina(obj, id);
  }

  updateData() {
    this.formComponentsService.activateLoader(true);
    this.magazzinoService.getData()
    .subscribe(
      (data) => {
        this.data = data;
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
        this.router.navigate([this.backUrl]);
      });
      this.formComponentsService.activateLoader(false);
  }


  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      this.notificationsService.show('warning', '', 'Alcuni campi non sono stati compilati correttamente');
      return false;
    }

    this.loading = true;

    this.magazzinoService.sendData(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.myForm.reset();
        this.myForm.controls.idPadre.setValue(this.idPadre);
        this.myForm.controls.data.setValue(new Date());
        this.updateData();
        this.tipologiaAzione = 0;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onTabChanged(event) {
    switch (event.nextId) {
      case 1:
          this.getData();
          this.elencoMovimenti = false;
          break;

      case 2:
        this.getElenco();
        this.elencoMovimenti = true;
        break;
    }

  }

  onCancel() {
    this.componentsService.onCancel(this.backUrl);
  }

  onBack() {
    this.componentsService.onBack(this.backUrl);
  }
}
