import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MostreService } from '../mostre.service';

@Component({
  selector: 'app-mostre-elenco',
  templateUrl: './mostre-elenco.component.html',
  styleUrls: ['./mostre-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MostreElencoComponent implements OnInit {
  myService: any;
  columns: any[any];
  options: any[any];
  lastId: number = 0;

  constructor(
    private mostreService: MostreService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.myService = this.mostreService;

        this.columns = [
          { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo}` },
          { columnDef: 'citta', header: 'Città',   cell: (element: any) => `${element.citta}`},
          { columnDef: 'prodotto_pp', header: 'Prodotto in primo piano', cell: (element: any) => `${element.prodotto_pp}` , hideOnSmall: true},
        ];
        this.options = {
          linkBase: this.mostreService.linkBase,
          customButtons: {
            prodotti: {
              type: 'custom',
              testo: 'Prodotti',
              colorClass: 'info',
              icon: 'bx bx-box',
              actionTooltip: 'Gestione prodotti in vendita',
              routerLink: 'prodotti/elenco',
              hideText: true
            }
          }
        }
      });
    }

  ngOnInit(): void {
  }
}
