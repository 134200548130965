import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { JwtInterceptorService } from './service/jwt-interceptor.service';
import { ErrorInterceptorService } from './service/error-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import * as $ from 'jquery';

import { LocalLoginLayoutComponent } from './custom/layouts/local-login-layout/local-login-layout.component';
import { LocalMainLayoutComponent } from './custom/layouts/local-main-layout/local-main-layout.component';
import { MostreDettaglioComponent } from './pages/mostre/mostre-dettaglio/mostre-dettaglio.component';
import { MostreElencoComponent } from './pages/mostre/mostre-elenco/mostre-elenco.component';
import { MuseiDettaglioComponent } from './pages/musei/musei-dettaglio/musei-dettaglio.component';
import { MuseiElencoComponent } from './pages/musei/musei-elenco/musei-elenco.component';
import { MuseiFotogalleryComponent } from './pages/musei/musei-fotogallery/musei-fotogallery.component';
import { MuseiSliderComponent } from './pages/musei/musei-slider/musei-slider.component';
import { ProdottiMagazzinoComponent } from './pages/prodotti/prodotti-magazzino/prodotti-magazzino.component';
import { ProdottiCategorieComponent } from './pages/prodotti/prodotti-categorie/prodotti-categorie.component';
import { ProdottiDettaglioComponent } from './pages/prodotti/prodotti-dettaglio/prodotti-dettaglio.component';
import { ProdottiElencoComponent } from './pages/prodotti/prodotti-elenco/prodotti-elenco.component';
import { ProdottiFotogalleryComponent } from './pages/prodotti/prodotti-fotogallery/prodotti-fotogallery.component';
import { ProdottiVariantiComponent } from './pages/prodotti/prodotti-varianti/prodotti-varianti.component';
import { VariantiDettaglioComponent } from './pages/prodotti/varianti-dettaglio/varianti-dettaglio.component';
import { VariantiElencoComponent } from './pages/prodotti/varianti-elenco/varianti-elenco.component';
import { AssegnaProdottiComponent } from './pages/common/assegna-prodotti/assegna-prodotti.component';
import { MuseiProdottiComponent } from './pages/musei/musei-prodotti/musei-prodotti.component';
import { TemporaryshopElencoComponent } from './pages/temporaryshop/temporaryshop-elenco/temporaryshop-elenco.component';
import { TemporaryshopDettaglioComponent } from './pages/temporaryshop/temporaryshop-dettaglio/temporaryshop-dettaglio.component';
import { TemporaryshopProdottiComponent } from './pages/temporaryshop/temporaryshop-prodotti/temporaryshop-prodotti.component';
import { MostreProdottiComponent } from './pages/mostre/mostre-prodotti/mostre-prodotti.component';
import { MaxipulsantiElencoComponent } from './pages/common/maxipulsanti/maxipulsanti-elenco/maxipulsanti-elenco.component';
import { MaxipulsantiDettaglioComponent } from './pages/common/maxipulsanti/maxipulsanti-dettaglio/maxipulsanti-dettaglio.component';
import { MuseiMaxipulsantiComponent } from './pages/musei/musei-maxipulsanti/musei-maxipulsanti.component';
import { ProdottiPrezziComponent } from './pages/prodotti/prodotti-prezzi/prodotti-prezzi.component';
import { SceltaProdottiComponent } from './pages/common/assegna-prodotti/scelta-prodotti/scelta-prodotti.component';
import { SliderHomeComponent } from './pages/homePage/slider-home/slider-home.component';
import { ChiSiamoComponent } from './pages/chi-siamo/chi-siamo.component';
import { ComponentsModule } from './components/components.module';
import { NgbDropdownModule, NgbModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { MainLayoutModule } from './components/layouts/main-layout/main-layout.module';
import { MainLayoutComponent } from './components/layouts/main-layout/main-layout.component';
import { BlogElencoComponent } from './pages/blog/blog-elenco/blog-elenco.component';
import { BlogDettaglioComponent } from './pages/blog/blog-dettaglio/blog-dettaglio.component';
import { TestiSezioniComponent } from './pages/testi-sezioni/testi-sezioni.component';
import { FaqElencoComponent } from './pages/faq/faq-elenco/faq-elenco.component';
import { FaqDettaglioComponent } from './pages/faq/faq-dettaglio/faq-dettaglio.component';
import { ContattiComponent } from './pages/contatti/contatti.component';
import { CostiConsegnaElencoComponent } from './pages/_ecommerce/gestioneConsegne/costiConsegna/costi-consegna-elenco/costi-consegna-elenco.component';
import { CostiConsegnaSchedaComponent } from './pages/_ecommerce/gestioneConsegne/costiConsegna/costi-consegna-scheda/costi-consegna-scheda.component';
import { ZoneConsegnaElencoComponent } from './pages/_ecommerce/gestioneConsegne/zoneConsegna/zone-consegna-elenco/zone-consegna-elenco.component';
import { ZoneConsegnaSchedaComponent } from './pages/_ecommerce/gestioneConsegne/zoneConsegna/zone-consegna-scheda/zone-consegna-scheda.component';
import { TipologieConsegnaElencoComponent } from './pages/_ecommerce/gestioneConsegne/tipologieConsegna/tipologie-consegna-elenco/tipologie-consegna-elenco.component';
import { TipologieConsegnaSchedaComponent } from './pages/_ecommerce/gestioneConsegne/tipologieConsegna/tipologie-consegna-scheda/tipologie-consegna-scheda.component';
import { MetodiPagamentoElencoComponent } from './pages/_ecommerce/metodiPagamento/metodi-pagamento-elenco/metodi-pagamento-elenco.component';
import { MetodiPagamentoSchedaComponent } from './pages/_ecommerce/metodiPagamento/metodi-pagamento-scheda/metodi-pagamento-scheda.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { OpzioniHomeComponent } from './pages/homePage/opzioni-home/opzioni-home.component';
import { AnagraficaElencoComponent } from './pages/_ecommerce/anagrafica/anagrafica-elenco/anagrafica-elenco.component';
import { AnagraficaSchedaComponent } from './pages/_ecommerce/anagrafica/anagrafica-scheda/anagrafica-scheda.component';
import { OrdiniElencoComponent } from './pages/_ecommerce/ordini/ordini-elenco/ordini-elenco.component';
import { OrdiniSchedaComponent } from './pages/_ecommerce/ordini/ordini-scheda/ordini-scheda.component';
import { AnagraficaFormComponent } from './pages/_ecommerce/anagrafica/anagrafica-form/anagrafica-form.component';
import { CodiciScontoElencoComponent } from './pages/_ecommerce/codiciSconto/codici-sconto-elenco/codici-sconto-elenco.component';
import { CodiciScontoSchedaComponent } from './pages/_ecommerce/codiciSconto/codici-sconto-scheda/codici-sconto-scheda.component';
import { ImpostazioniComponent } from './pages/_ecommerce/impostazioni/impostazioni.component';
import { ModalGestionePagamentoComponent } from './pages/_ecommerce/ordini/ordini-elenco/modal-gestione-pagamento/modal-gestione-pagamento.component';
import { ModalGestioneSpedizioneComponent } from './pages/_ecommerce/ordini/ordini-elenco/modal-gestione-spedizione/modal-gestione-spedizione.component';
import { ModalVisualizzaComponent } from './pages/_ecommerce/ordini/ordini-elenco/modal-visualizza/modal-visualizza.component';
import { ModalAggiungiArticoloComponent } from './pages/_ecommerce/ordini/ordini-scheda/articoli/modal-aggiungi-articolo/modal-aggiungi-articolo.component';
import { TabElencoArticoliComponent } from './pages/_ecommerce/ordini/ordini-scheda/articoli/tab-elenco-articoli/tab-elenco-articoli.component';
import { TabAnagraficaComponent } from './pages/_ecommerce/ordini/ordini-scheda/anagrafica/tab-anagrafica/tab-anagrafica.component';
import { ModalAnagraficaComponent } from './pages/_ecommerce/ordini/ordini-scheda/anagrafica/modal-anagrafica/modal-anagrafica.component';
import { TabSpedizioneComponent } from './pages/_ecommerce/ordini/ordini-scheda/spedizione/tab-spedizione/tab-spedizione.component';
import { ModalBaseSpedizioneComponent } from './pages/_ecommerce/ordini/common/modal-base-spedizione/modal-base-spedizione.component';
import { ModalSpedizioneComponent } from './pages/_ecommerce/ordini/ordini-scheda/spedizione/modal-spedizione/modal-spedizione.component';
import { ModalBasePagamentoComponent } from './pages/_ecommerce/ordini/common/modal-base-pagamento/modal-base-pagamento.component';
import { TabPagamentoComponent } from './pages/_ecommerce/ordini/ordini-scheda/pagamento/tab-pagamento/tab-pagamento.component';
import { ModalPagamentoComponent } from './pages/_ecommerce/ordini/ordini-scheda/pagamento/modal-pagamento/modal-pagamento.component';
import { TabScontoComponent } from './pages/_ecommerce/ordini/ordini-scheda/sconto/tab-sconto/tab-sconto.component';
import { ModalScontoComponent } from './pages/_ecommerce/ordini/ordini-scheda/sconto/modal-sconto/modal-sconto.component';
import { ModalDettaglioAnagraficaComponent } from './pages/_ecommerce/anagrafica/modal-dettaglio-anagrafica/modal-dettaglio-anagrafica.component';
import { ExportComponent } from './pages/export/export.component';
import { ModalGestioneResoComponent } from './pages/_ecommerce/ordini/ordini-elenco/modal-gestione-reso/modal-gestione-reso.component';
import { ModalBaseResoComponent } from './pages/_ecommerce/ordini/common/modal-base-reso/modal-base-reso.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgApexchartsModule } from 'ng-apexcharts';
@NgModule({
  declarations: [
    AppComponent,
    LocalLoginLayoutComponent,
    LocalMainLayoutComponent,
    MostreDettaglioComponent,
    MostreElencoComponent,
    MuseiDettaglioComponent,
    MuseiElencoComponent,
    MuseiFotogalleryComponent,
    MuseiSliderComponent,
    ProdottiMagazzinoComponent,
    ProdottiCategorieComponent,
    ProdottiDettaglioComponent,
    ProdottiElencoComponent,
    ProdottiFotogalleryComponent,
    ProdottiVariantiComponent,
    VariantiDettaglioComponent,
    VariantiElencoComponent,
    AssegnaProdottiComponent,
    MuseiProdottiComponent,
    TemporaryshopElencoComponent,
    TemporaryshopDettaglioComponent,
    TemporaryshopProdottiComponent,
    MostreProdottiComponent,
    MaxipulsantiElencoComponent,
    MaxipulsantiDettaglioComponent,
    MuseiMaxipulsantiComponent,
    ProdottiPrezziComponent,
    SceltaProdottiComponent,
    SliderHomeComponent,
    ChiSiamoComponent,
    MainLayoutComponent,
    BlogElencoComponent,
    BlogDettaglioComponent,
    TestiSezioniComponent,
    FaqElencoComponent,
    FaqDettaglioComponent,
    ContattiComponent,
    CostiConsegnaElencoComponent,
    CostiConsegnaSchedaComponent,
    ZoneConsegnaElencoComponent,
    ZoneConsegnaSchedaComponent,
    TipologieConsegnaElencoComponent,
    TipologieConsegnaSchedaComponent,
    MetodiPagamentoElencoComponent,
    MetodiPagamentoSchedaComponent,
    OpzioniHomeComponent,
    AnagraficaElencoComponent,
    AnagraficaSchedaComponent,
    OrdiniElencoComponent,
    OrdiniSchedaComponent,
    AnagraficaFormComponent,
    CodiciScontoElencoComponent,
    CodiciScontoSchedaComponent,
    ImpostazioniComponent,
    ModalGestionePagamentoComponent,
    ModalGestioneSpedizioneComponent,
    ModalVisualizzaComponent,
    ModalAggiungiArticoloComponent,
    TabElencoArticoliComponent,
    TabAnagraficaComponent,
    ModalAnagraficaComponent,
    TabSpedizioneComponent,
    ModalBaseSpedizioneComponent,
    ModalSpedizioneComponent,
    ModalBasePagamentoComponent,
    TabPagamentoComponent,
    ModalPagamentoComponent,
    TabScontoComponent,
    ModalScontoComponent,
    ModalDettaglioAnagraficaComponent,
    ExportComponent,
    ModalGestioneResoComponent,
    ModalBaseResoComponent,
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    NgbTooltipModule,
    NgbPopoverModule,
    NgbDropdownModule,
    MainLayoutModule,
    ComponentsModule,
    MatTableModule,
    MatSortModule,
    NgApexchartsModule,
  ],
  providers : [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
