import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TemporaryshopService } from '../temporaryshop.service';

@Component({
  selector: 'app-temporaryshop-elenco',
  templateUrl: './temporaryshop-elenco.component.html',
  styleUrls: ['./temporaryshop-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TemporaryshopElencoComponent implements OnInit {

  myService: any;
  columns: any[any];
  options: any[any];
  lastId: number = 0;

  constructor(
    private service: TemporaryshopService,
    private route: ActivatedRoute,
  ) {
    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

    this.myService = this.service;

    this.columns = [
      { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo}` },
      { columnDef: 'numProdottiAssegnati',     header: 'Prod.',   cell: (element: any) => `${element.numProdottiAssegnati}`},
    ];

    this.options = {
      linkBase: this.service.linkBase
      ,customButtons: {
        prodotti: {
          type: 'custom',
          testo: 'Prodotti',
          colorClass: 'info',
          icon: 'bx bx-box',
          actionTooltip: 'Gestione prodotti in vendita',
          routerLink: 'prodotti/elenco',
          hideText: true
        }
      }
    }
  });

  }

  ngOnInit(): void {
  }
}
