export class SliderOpzioni {
  id: number = -1;
  dimensione: string;
  idPadre: number = 0;
  backUrl: string;
  apiUrl: string;
  tipologia: string;
  campoTitolo: boolean = false;
  campoSottotitolo: boolean = false;
  campoLink: boolean = false;
  linkBase: string;
}
