import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalBaseSpedizioneComponent } from '../../../common/modal-base-spedizione/modal-base-spedizione.component';

@Component({
  selector: 'app-modal-spedizione',
  templateUrl: './modal-spedizione.component.html',
  styleUrls: ['./modal-spedizione.component.scss']
})
export class ModalSpedizioneComponent implements OnInit {
  @Input() idOrdine: number;
  @Output() onSubmitEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() onCancelEmitter: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(ModalBaseSpedizioneComponent, {static : true}) child:ModalBaseSpedizioneComponent;

  constructor() { }

  ngOnInit(): void {
  }

  onCancel() {
    this.onCancelEmitter.emit(null);
  }

  onSubmit() {
    this.onSubmitEmitter.emit(null);
  }

  annullaSpedizione(idOrdine) {
    this.child.annullaSpedizione(idOrdine);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('idOrdine' in changes) {
      this.child.openModal(this.idOrdine);
    }
  }

}
