import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Variante } from './variante';

@Injectable({
  providedIn: 'root'
})
export class ProdottiVariantiService {
  apiUrl: string;
  idTipologiaVariante: number  = 0;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + "gestioneVariantiProdotti/";
  }

  getData(idProdotto: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + "op/getData?idProdotto=" + idProdotto);
  }

  sendData(formValue: any[]) {
    const body = formValue;

    return this.http.put<any>(this.apiUrl , body);
  }

  setIdTipologiaVariante(idTipologiaVariante: number) {
    this.idTipologiaVariante = idTipologiaVariante;
  }

  getElenco(): Observable<Variante[]> {
    return this.http.get<Variante[]>(this.apiUrl + 'elementi/' + this.idTipologiaVariante);
  }

  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + 'op/opzioni?idTipologiaVariante=' + this.idTipologiaVariante);
  }

  getItem(id: number): Observable<Variante> {
    return this.http.get<Variante>(this.apiUrl + id)
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  deleteItem(id: number) {
    return this.http.delete<Variante>(this.apiUrl + id)
  }

  toggleVisibile(id: number) {
    const tipologia = 'visibile';
    return this.http.patch<any>(this.apiUrl + id, {tipologia})
  }

}
