import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MaxipulsantiOpzioni } from '../../common/maxipulsanti/maxipulsanti-opzioni';

@Component({
  selector: 'app-musei-maxipulsanti',
  templateUrl: './musei-maxipulsanti.component.html',
  styleUrls: ['./musei-maxipulsanti.component.scss']
})
export class MuseiMaxipulsantiComponent implements OnInit {
  options: MaxipulsantiOpzioni

  constructor(
    private route: ActivatedRoute,
  ) {
    this.options = new MaxipulsantiOpzioni;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.options.id = params.id;
        }
        this.options.idPadre = params.idPadre;
        this.options.tipologia = "musei";
        this.options.dimensione = "547x351";

        this.options.backUrl = "/" + this.options.tipologia + "/lastItem/"+this.options.idPadre;
      }
    );
  }

  ngOnInit(): void {
  }

}

