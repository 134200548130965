import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProdottiVariantiService } from '../prodotti-varianti.service';

@Component({
  selector: 'app-varianti-elenco',
  templateUrl: './varianti-elenco.component.html',
  styleUrls: ['./varianti-elenco.component.scss']
})
export class VariantiElencoComponent implements OnInit {
  myService: any;
  columns: any[any];
  options: any[any];
  idPadre: number = 0;
  numVariante: number = 0;

  constructor(
    private service: ProdottiVariantiService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe(
      (params) => {

        this.service.setIdTipologiaVariante(params.idTipologiaVariante);

        this.myService = this.service;


        this.columns = [
          { columnDef: 'titolo', header: 'Titolo',    cell: (element: any) => `${element.titolo}` },
          { columnDef: 'immagine', header: 'Immagine', type: 'img',    cell: (element: any) => `${element.immagine}` },
        ];

        this.options = {
          linkBase: this.router.url,
          backUrl: '/prodotti/' + params.idPadre + '/varianti'
        }
      });
    }

  ngOnInit(): void {
  }
}
