import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { Categoria } from 'src/app/components/elenco/categorie/categoria';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { Ordine } from '../ordine';
import { OrdiniService } from '../ordini.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonService } from 'src/app/components/buttons/button.service';

@Component({
  selector: 'app-ordini-scheda',
  templateUrl: './ordini-scheda.component.html',
  styleUrls: ['./ordini-scheda.component.scss']
})
export class OrdiniSchedaComponent implements OnInit {
  loading = false;
  arrayCampi: any[any];
  myItem: Ordine;
  titoloScheda: string = "Caricamento...";
  arrayCategorie: Categoria[];
  arrayProdotti: any[any];
  modalReference: any;
  backUrl = '';
  idOrdine: number = 0;
  arrayPulsanti: any[any];

  constructor(
    private router: Router,
    private mainEngineService: MainEngineService,
    private service: OrdiniService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService,
    private buttonService: ButtonService,
    private formComponentsService: FormInputService,
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'nome': 'id', 'required': true},
    };

    this.arrayPulsanti = {
      'confermaOrdine' : {'type' : 'custom', 'testo' : 'Conferma ordine', 'colorClass' : 'info', 'icon': 'fas fa-check-double', 'actionTooltip' : 'Conferma ordine ed invia email al cliente', 'ML' : false, 'mainClass' : 'btn-md'},
      'annullaOrdine' : {'type' : 'custom', 'testo' : 'Annulla ordine', 'colorClass' : 'warning', 'icon': 'fas fa-ban', 'actionTooltip' : 'Annulla questo ordine', 'ML' : false, 'mainClass' : 'btn-md'},
      'ripristinaOrdine' : {'type' : 'custom', 'testo' : 'Ripristina ordine', 'colorClass' : 'light', 'icon': 'fas fa-arrow-up', 'actionTooltip' : 'Ripristina questo ordine', 'ML' : false, 'mainClass' : 'btn-md'},
      'reinviaEmail' : {'type' : 'custom', 'testo' : 'Reinvia email', 'colorClass' : 'light', 'icon': 'fas fa-envelope', 'actionTooltip' : 'Reinvia email al cliente', 'ML' : true, 'mainClass' : 'btn-md'},
    };
  }

  ngOnInit(): void {
    this.backUrl = this.service.linkBase;

    this.route.params.subscribe(
      (params) => {
        this.idOrdine = params.id;
        if (this.idOrdine != 0) {
          this.formComponentsService.activateLoader(true);
          this.backUrl += '/lastItem/' + this.idOrdine;
          this.service.getItem(this.idOrdine)
          .subscribe(data => {
            this.myItem = data;
            this.titoloScheda = "Gestione ordine # " + this.idOrdine;
            this.formComponentsService.activateLoader(false);
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
          });
        }
        else {
          this.titoloScheda = "Inserimento nuovo ordine";
          this.formComponentsService.activateLoader(false);
          this.myItem = new Ordine;
        }
    });
  }

  reloadOrder(idOrdine: number) {
    if (idOrdine != this.idOrdine) {
      this.router.navigate(['/ordini/' + idOrdine]);
    }
    else {
      this.ngOnInit();
    }
  }


  onConfermaOrdine() {
    const that = this;
    const obj = {
      onSuccess(arg) {
        that.loading = true;
        that.service.confermaOrdine(that.idOrdine)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.componentsService.onBack(that.backUrl);
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenerico(obj, this.idOrdine);
  }



  onAnnullaOrdine(annulla: boolean = true) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        that.loading = true;
        that.service.annullaOrdine(that.idOrdine, annulla)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.componentsService.onBack(that.backUrl);
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenerico(obj, this.idOrdine);
  }


  onReinviaEmail() {
    const that = this;
    const obj = {
      onSuccess(arg) {
        that.loading = true;
        that.service.reinviaEmail(that.idOrdine)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.componentsService.onBack(that.backUrl);
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenerico(obj, this.idOrdine);
  }


  onBack() {
    this.componentsService.onBack(this.backUrl);
  }
}
