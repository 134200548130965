import { Injectable } from '@angular/core';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';

@Injectable({
  providedIn: 'root'
})
export class SceltaProdottiService {
  apiUrl: string;
  addIdPadre: string  = '';
  addTipologia: string  = '';
  idPadre: number = 0;

  arrayRicerca: any[any];

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + "sceltaProdotti/";
  }

  setTipologia(tipologia: string) {
    this.addTipologia = tipologia + "/";
  }

  setIdPadre(idPadre: number) {
    this.addIdPadre = "?idPadre=" + idPadre;
    this.idPadre = idPadre;
  }

  setParametriRicerca(formValue: any[]) {
/*
ean: ""
editore: ""
idPadre: "3"
tipologia: "temporaryshop"
titolo: ""
visibile: "N"
*/
    this.arrayRicerca = formValue;
  }

  getElenco(): Observable<any> {
    return this.http.patch<any>(this.apiUrl + this.addTipologia, this.arrayRicerca)
  }

  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + this.addTipologia + 'op/opzioni' + this.addIdPadre)
  }

  inviaAssociazioni(selezionati: string[]) {
    const body = selezionati+this.addIdPadre+this.addTipologia;

    return this.http.post<any>(this.apiUrl + this.addTipologia , {
      selezionati: selezionati,
      idPadre: this.idPadre
    });
  }

}
