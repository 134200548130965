import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TipologieConsegnaService } from '../tipologie-consegna.service';

@Component({
  selector: 'app-tipologie-consegna-elenco',
  templateUrl: './tipologie-consegna-elenco.component.html',
  styleUrls: ['./tipologie-consegna-elenco.component.scss']
})
export class TipologieConsegnaElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;

  constructor(
    private service: TipologieConsegnaService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'denominazione', header: 'Denominazione',    cell: (element: any) => `${element.titolo}` },
        ];
        this.options = {
          linkBase: this.myService.linkBase,
          testataButtons: {
            zone: {
              type: 'gestione',
              testo: 'Gestione zone',
              ML: true,
              click: this.mostraGestioneZone.bind(this),

              hideText: true
            }
          },
          customButtons: {
            costiConsegna: {
              type: 'custom',
              testo: 'Costi',
              colorClass: 'info',
              icon: 'bx bx-euro',
              actionTooltip: 'Gestione tariffe di consegna',
              routerLink: 'costiConsegna',
              hideText: true
            }
          }
        }
      });
  }

  mostraGestioneZone() {
    this.router.navigate(["/zoneConsegna"])
  }

  ngOnInit(): void {
  }

}
