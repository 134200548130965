import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Ordine } from '../../../ordine';
import { OrdiniService } from '../../../ordini.service';

@Component({
  selector: 'app-tab-sconto',
  templateUrl: './tab-sconto.component.html',
  styleUrls: ['./tab-sconto.component.scss']
})
export class TabScontoComponent implements OnInit {
  @Input() ordine: Ordine;
  @Output() reloadOrder = new EventEmitter<number>();

  arrayPulsanti: any[any];
  statoSconto: string;
  openModalId: number = -1;
  showModal: boolean = false;

  constructor(
    private mainEngineService: MainEngineService,
    private service: OrdiniService,
  ) {
    this.arrayPulsanti = {
      'modifica' : {'type' : 'edit'},
    };
  }

  ngOnInit(): void {
    if (this.ordine && this.ordine.id) {
      this.service.getSconto(this.ordine.id)
      .subscribe(data => {
        this.statoSconto = data;
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });
    }
  }


  modalForm() {
    this.showModal = true;
  }


  onSubmit() {
    this.onCancel();
    this.ngOnInit();
    this.reloadOrder.emit(this.ordine.id);
  }

  onCancel() {
    this.showModal = false;
  }
}
