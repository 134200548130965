import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Injectable({
  providedIn: 'root'
})
export class ProfiloService {
  apiUrl: string;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + "profilo/";
  }

  getItem(): Observable<any> {
    return this.http.get<any>(this.apiUrl)
  }

  sendEmail(body: any) {
    return this.http.post<any>(this.apiUrl + 'op/sendEmail/' , body);
  }

  sendVerificationCode(body: any) {
    return this.http.post<any>(this.apiUrl + 'op/sendVerificationCode/' , body);
  }

  sendPassword(body: any) {
    return this.http.post<any>(this.apiUrl + 'op/sendPassword/' , body);
  }

  cancelRequestEmail() {
    return this.http.patch<any>(this.apiUrl + 'op/cancelRequestEmail/', null);
  }

  resendCodeEmail() {
    return this.http.patch<any>(this.apiUrl + 'op/resendCodeEmail/', null);
  }
}
