import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { CampiSeoService } from 'src/app/components/scheda/campi-seo/campi-seo.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { TipologiaConsegna } from '../tipologia-consegna';
import { TipologieConsegnaService } from '../tipologie-consegna.service';

@Component({
  selector: 'app-tipologie-consegna-scheda',
  templateUrl: './tipologie-consegna-scheda.component.html',
  styleUrls: ['./tipologie-consegna-scheda.component.scss']
})
export class TipologieConsegnaSchedaComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: TipologiaConsegna;
  titoloScheda: string = "Caricamento...";
  arrayData: any[any];
  linkBase: string;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private service: TipologieConsegnaService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'titolo' : {'id' : 'titolo', 'nome': 'Denominazione', 'required': true},
      'descrizione' : {'id' : 'descrizione', 'nome': 'Descrizione', 'type': 'TXA'},
    };
  }

  ngOnInit(): void {

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.formComponentsService.activateLoader(true);
    this.linkBase = this.service.linkBase;


    this.route.params.subscribe(
      (params) => {
        if (params.id != '0') {
          this.linkBase += '/lastItem/' + params.id;
          this.service.getItem(params.id)
          .subscribe(data => {
            this.myItem = data;

            this.formComponentsService.activateLoader(false);
            this.titoloScheda = "Modifica elemento - '" + this.myItem._titoloScheda + "'";
            this.myForm.patchValue(this.myItem);
            this.myForm.markAsPristine();
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
          });
        }
        else {
          this.titoloScheda = "Inserimento elemento";
          this.formComponentsService.activateLoader(false);
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      });

  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.linkBase]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.service.linkBase + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel(this.linkBase);
  }

  onBack() {
    this.componentsService.onBack(this.linkBase);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }

  onSave() {
    this.onSubmit(2);
  }

}
