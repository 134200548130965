import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { Prodotto } from 'src/app/pages/prodotti/prodotto';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { AssegnaProdottiOpzioni } from '../assegna-prodotti-opzioni';
import { AssegnaProdottiService } from '../assegna-prodotti.service';
import { SceltaProdottiService } from './scelta-prodotti.service';

@Component({
  selector: 'app-scelta-prodotti',
  templateUrl: './scelta-prodotti.component.html',
  styleUrls: ['./scelta-prodotti.component.scss']
})
export class SceltaProdottiComponent implements OnInit {
  @Input() options: AssegnaProdottiOpzioni;

  that: any;
  arrayCampi: any[any];
  myForm: FormGroup;
  backUrl: string = '';
  loading: boolean = false;
  opzioni: OpzioniElenco;
  arrayVisibile: any[any];
  arrayPulsanti: any[any];
  mostraElenco: boolean = false;

  myService: any;
  columns: any[any];
  arrayElementi: Prodotto[];

  constructor(
    private formComponentsService: FormInputService,
    private service: SceltaProdottiService,
    private notificationsService: NotificationsService,
    private componentsService: ComponentsService,
    private mainEngineService: MainEngineService
  ) {
    this.that = this;

    this.arrayVisibile = {
      'N': '-',
      '1': 'Si',
      '0': 'No',
    };

    this.arrayCampi = {
      'titolo' : {'id' : 'titolo', 'nome': 'Titolo', 'required': false},
      'editore' : {'id' : 'editore', 'nome': 'Editore', 'required': false},
      'ean' : {'id' : 'ean', 'nome': 'Ean', 'required': false},
      'visibile' : {'id' : 'visibile', 'nome': 'Visibile', 'items': this.arrayVisibile, 'type': 'RAD', 'required': true, 'inline': true},
      'idPadre' : {'id' : 'idPadre', 'type': 'NUM'}
    };

    this.arrayPulsanti = {
      'cerca' : {'type' : 'cerca', 'ML' : true},
      'reset' : {'type' : 'reset'},
    };

    this.service.setParametriRicerca([]);

    this.myService = this.service;

    this.columns = [
      { columnDef: 'codice', header: 'Codice', cell: (element: any) => `${element.codice}` },
      { columnDef: 'titolo', header: 'Titolo', cell: (element: any) => `${element.titolo}` },
      { columnDef: 'editore', header: 'Editore', cell: (element: any) => `${element.editore}` },
      { columnDef: 'assegnatoVisibile', header: 'Visibile', cell: (element: any) => `${element.assegnatoVisibile}` },
      { columnDef: 'selezionato', header: 'Selezionato', cell: (element: any) => `<input class="form-check-input elementoSelezionato" type="checkbox" name="${element.codice}" id="${element.codice}" [value]="1">` },
    ];
  }




  ngOnInit(): void {
    this.myForm = this.formComponentsService.createForm(this.arrayCampi);

    this.service.setTipologia(this.options.tipologia);

    this.service.setIdPadre(this.options.idPadre);
    this.backUrl = "/" + this.options.tipologia + "/" + this.options.idPadre + "/prodotti/elenco";

    this.myForm.controls.idPadre.setValue(this.options.idPadre);

    this.options['nascondiPulsanteNuovo'] = true;
    this.options['noAzioni'] = true;
    this.options['linkBase'] = this.options.tipologia + "/" + this.options.idPadre + "/prodotti";
    this.options['backUrl'] = this.options.tipologia + "/" + this.options.idPadre + "/prodotti/elenco";
    this.options['rowButtons'] = {
      aggiungi: {
        type: 'cerca',
        testo: 'Nuova ricerca',
        ML: false,
        click: this.visualizzaElenco.bind(this),

        hideText: true
      },
      salva: {
        type: 'conferma',
        ML: true,
        click: this.onSubmit.bind(this),

        hideText: true
      }
    };


    this.service.getOpzioni()
    .subscribe(
      (data: OpzioniElenco) => {
        this.opzioni = data;
      }
    );

  }

  onBack() {
    this.componentsService.onBack(this.backUrl);
  }

  onSearch() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      this.notificationsService.show('warning', '', 'Compilare tutti i campi obbligatori');
      return false;
    }

    this.service.setParametriRicerca(this.myForm.getRawValue());
    this.service.getElenco();

    this.mostraElenco = true;
  }

  onReset() {
    this.myForm.reset();
  }


  onSubmit() {
    let selezionati = [];
    $( ".elementoSelezionato" ).each(function() {
      if ($( this ).is(":checked")) {
        selezionati.push($( this ).attr("id"));
      }
    });

    if (!selezionati.length) {
      this.notificationsService.show('warning', '', 'Selezionare almeno un elemento per continuare');
      return;
    }

    this.loading = true;

    this.service.inviaAssociazioni(selezionati)
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        this.onBack();
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  visualizzaElenco() {
    this.mostraElenco = false;
  }

}
