import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { ElementoOrdine } from '../elemento-ordine';
import { Ordine } from '../../../ordine';
import { ArticoliService } from '../articoli.service';

@Component({
  selector: 'app-tab-elenco-articoli',
  templateUrl: './tab-elenco-articoli.component.html',
  styleUrls: ['./tab-elenco-articoli.component.scss']
})
export class TabElencoArticoliComponent implements OnInit {
  @Input() ordine: Ordine;
  @Output() reloadOrder = new EventEmitter<number>();

  openModalArticolo: number = -1;
  elementiOrdine: ElementoOrdine[];
  arrayPulsanti: any[any];

  constructor(
    private service: ArticoliService,
    private notificationsService: NotificationsService,
    private mainEngineService: MainEngineService,
    private buttonService: ButtonService
  ) {
    this.arrayPulsanti = {
      'aggiungiArticolo' : {'type' : 'custom', 'testo' : 'Aggiungi articolo', 'colorClass' : 'primary', 'icon': 'fas fa-cart-plus', 'actionTooltip' : 'Aggiungi articolo al carrello', 'ML' : false, 'mainClass' : 'btn-md'},
      'modificaArticolo' : {'type' : 'edit', 'ML' : true, 'mainClass' : 'btn-xs'},
      'eliminaArticolo' : {'type' : 'delete', 'ML' : true, 'mainClass' : 'btn-xs'},
    };
  }

  ngOnInit(): void {
    if (this.ordine && this.ordine.id) {
      this.service.getElencoElementiOrdine(this.ordine.id)
      .subscribe(data => {
        this.elementiOrdine = data;
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });
    }
  }


  modalForm(idArticolo: number = 0) {
    this.openModalArticolo = idArticolo;
  }


  onSubmit(idOrdine: number) {
    this.openModalArticolo = -1;
    this.ngOnInit();
    this.reloadOrder.emit(idOrdine);
  }

  onCancel(event: any) {
    this.openModalArticolo = -1;
  }


  eliminaArticolo(idArticolo) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        this.loading = true;
        that.service.deleteElementoOrdine(arg)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.ngOnInit();
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaElimina(obj, idArticolo);
  }
}
