import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CostiConsegnaService } from '../costi-consegna.service';

@Component({
  selector: 'app-costi-consegna-elenco',
  templateUrl: './costi-consegna-elenco.component.html',
  styleUrls: ['./costi-consegna-elenco.component.scss']
})
export class CostiConsegnaElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;
  idPadre = 0;

  constructor(
    private service: CostiConsegnaService,
    private route: ActivatedRoute,
  ) {
    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }
        this.idPadre = params.idPadre;

        this.service.setIdPadre(this.idPadre);

        this.columns = [
          { columnDef: 'zona', header: 'Zona',    cell: (element: any) => `${element.zona}` },
          { columnDef: 'prezzi', header: 'Prezzi',    cell: (element: any) => `${element.prezzi}` },
        ];
        this.options = {
          linkBase: this.myService.linkBase,
          backUrl: "/tipologieConsegna/lastItem/" + this.idPadre
        }
      });
  }

  ngOnInit(): void {
  }

}
