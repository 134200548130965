import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OpzioniElenco } from 'src/app/components/elenco/opzioni-elenco';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Injectable({
  providedIn: 'root'
})
export class AssegnaProdottiService {
  apiUrl: string;
  addIdPadre: string  = '';
  addTipologia: string  = '';
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + "assegnaProdotti/";
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');
  }

  setTipologia(tipologia: string) {
    this.addTipologia = tipologia + "/";
  }

  setIdPadre(idPadre: number) {
    this.addIdPadre = "?idPadre=" + idPadre;
  }

  getElenco(): Observable<any> {
    return this.http.get<any>(this.apiUrl + this.addTipologia + this.addIdPadre)
  }

  getOpzioni(): Observable<OpzioniElenco> {
    return this.http.get<OpzioniElenco>(this.apiUrl + this.addTipologia + 'op/opzioni' + this.addIdPadre)
  }

  deleteItem(id: number) {
    return this.http.delete<any>(this.apiUrl + this.addTipologia + id)
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl + this.addTipologia , body);
  }

  toggleEvidenza(id: number) {
    const tipologia = 'evidenza';
    return this.http.patch<any>(this.apiUrl + this.addTipologia + id, {tipologia})
  }

  toggleVisibile(id: number) {
    const tipologia = 'visibile';
    return this.http.patch<any>(this.apiUrl + this.addTipologia + id, {tipologia})
  }
}
