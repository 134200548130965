import { FormGroup, FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Router, RoutesRecognized } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from '../components/service/notification.service';
import { LoaderService } from './loader.service';
import { ConfigParams } from '../components/class/config-params';
import { GenericItem } from '../components/class/generic-item';
import packageInfo  from '../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class MainEngineService {
  VERSION = packageInfo.version;

  globalUrlSubject: BehaviorSubject<string>;
  globalUrl: string;
  globalUrlArray: string[];
  globalPreviousUrlSubject: BehaviorSubject<string>;
  globalPreviousUrl: string;
  globalPreviousUrlArray: string[];
  baseUrl = '';
  commonUrl = 'https://webserver.dreamfactorydesign.com/api/';
  configParamsSubject: BehaviorSubject<ConfigParams>;
  userAnagrafica: any;
  connessioneAssenteObject: BehaviorSubject<boolean>;
  $screenW: BehaviorSubject<number>;


  constructor(
    private router: Router,
    private http: HttpClient,
    private notificationsService: NotificationsService,
    private loader: LoaderService
  ) {
    this.globalUrl = '';
    this.globalUrlSubject = new BehaviorSubject<string>('');
    this.globalPreviousUrl = '';
    this.globalPreviousUrlSubject = new BehaviorSubject<string>('');
    this.configParamsSubject = new BehaviorSubject<ConfigParams>(null);
    this.$screenW = new BehaviorSubject<number>(0);

    if ((document.location.hostname === 'localhost') || (document.location.hostname.includes("192.168"))) {
      this.baseUrl = 'https://admin.sistemamuseoshop.it';
    } else {
      this.baseUrl = document.location.protocol + '//' + document.location.hostname;
    }
    this.baseUrl += '/api/';
  }
/*
  onRouteChange(events: RoutesRecognized[]): void {

    let url: string = events[1].urlAfterRedirects;
*/
onRouteChange(url: string): void {
  if ((url === undefined) || (this.globalUrl === url)) {
      return;
    }
//    url = events[0].urlAfterRedirects;
    if (this.globalUrl.indexOf('lastItem') > 0) {
      this.globalUrl = this.globalUrl.substring(0, this.globalUrl.indexOf('lastItem'));
    }

    this.globalPreviousUrl = this.globalUrl;
    this.globalPreviousUrlArray = this.globalUrl.split('/');
    this.globalPreviousUrlSubject.next(this.globalUrl);

    this.globalUrl = url;
    this.globalUrlArray = url.split('/');
    this.globalUrlSubject.next(url);
  }


  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
}

  getUrlSubject(): Observable<string> {
    return this.globalUrlSubject.asObservable();
  }

  getConfigParams(): Observable<ConfigParams> {
    return this.http.get<ConfigParams>(this.baseUrl + 'getConfigParams/');
  }

  getConfigParamsSubject(): Observable<ConfigParams> {
    return this.configParamsSubject.asObservable();
  }

  setScreenW$(value: number) {
    this.$screenW.next(value);

    if (value < 1400) {
      document.body.setAttribute("data-sidebar-size", "md");
    }
    else {
      document.body.setAttribute("data-sidebar-size", "lg");
    }

  }

  getScreenW$(): Observable<number> {
    return this.$screenW.asObservable();
  }



  errorHandler(e) {
//    console.log(e);
    this.loader.stopSpinner();
    let errorTitle = '';
    let errorMsg = '';
    let errorType = 'error';
    if ((e.error.message !== undefined) && (e.error.message !== '')) {
      errorTitle = (e.error.title !== undefined) && (e.error.title !== '') ? e.error.title : 'Errore di sistema';
      if ((e.error.type !== undefined) && (e.error.type !== '')) {
        errorType = e.error.type;
      }
      errorMsg = e.error.message;
    } else if (e.statusText === undefined) {
      if (e.status === 200) {
        errorTitle = 'Errore imprevisto';
        errorMsg = 'Si è verificato un errore imprevisto. Provare a ripetere l\'ultima operazione effettuata';
      } else {
        errorTitle = 'Errore di comunicazione';
        errorMsg = 'Verificare lo stato della connessione internet e riprovare';
        this.connessioneAssenteObject.next(true);
      }
    } else {
      errorMsg = e.statusText;
      if ((e.name !== undefined) && (e.name !== '')) {
        errorMsg = e.name + ": " + errorMsg;
      }
    }
    this.notificationsService.show(errorType, errorTitle, errorMsg);
  }

  getElencoStati(): Observable<GenericItem[]> {
    return this.http.get<GenericItem[]>(this.commonUrl + 'stati/')
  }

  /*
  getProvince(): Observable<SelectItem[]> {
    return this.http.get<SelectItem[]>(this.commonUrl + 'getProvince/');
  }

  getQuoteParams(): Observable<QuoteParams> {
    return this.http.get<QuoteParams>(this.baseUrl + 'getQuoteParams/');
  }

  getElencoQuotePagate(): Observable<QuotePagate[]> {
    return this.http.get<QuotePagate[]>(this.baseUrl + 'getElencoQuotePagate/');
  }

  getUserAnagrafica(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + 'getUserData/');
  }

  getStatoIscrizione(): Observable<IscrizioneAttuale> {
    return this.http.get<IscrizioneAttuale>(this.baseUrl + 'getStatoIscrizione/');
  }

  getStatoIscrizioneSubject(): Observable<IscrizioneAttuale> {
    return this.statoIscrizioneSubject.asObservable();
  }

  setUserBasicData(user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
    this.userBasicDataObject.next(user);
  }

  getUserBasicData(): void {
    const user = localStorage.getItem('user');
    if (user) {
      this.userBasicDataObject.next(JSON.parse(user));
    }
  }

  getUserBasicDataSubject(): Observable<User> {
    return this.userBasicDataObject.asObservable();
  }

  saveUserToken(token: string) {
    localStorage.setItem('token', token);
  }

  getUserToken(): string {
    return localStorage.getItem('token');
  }

  isUserLoggedIn() {
    return !!this.getUserToken();
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  badSessionLogout(errorMsg) {
    localStorage.clear();
    this.router.navigate(['/login']);
    this.notificationsService.show('error', '', errorMsg);
  }

  confermaPassword(idUser: number, token: string) {
    return this.http.post<any>(this.baseUrl + 'attivaPassword/' , {idUser, token})
    .pipe(map(data => {
      return data
    }))
  }


  confermaEmail(idUser: number, token: string) {
    return this.http.post<any>(this.baseUrl + 'confermaEmail/' , {idUser, token})
    .pipe(map(data => {
      return data
    }))
  }


  recover(email: string) {
    return this.http.post<any>(this.baseUrl + 'recover/' , {email})
    .pipe(map(data => {
      return data
    }))
  }


  login(username: string, password: string) {
    return this.http.post<any>(this.baseUrl + 'signIn/' , {username, password})
    .pipe(map(data => {
      this.saveUserToken(data.jwt);
      this.setUserBasicData(data.user);
      return data.user;
    }));
  }

  signUp(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.baseUrl + 'register/' , body)
    .pipe(map(data => {
      return data;
    }));
  }

  modificaEmail(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.baseUrl + 'modificaEmail/' , body)
    .pipe(map(data => {
      return data;
    }));
  }


  modificaPassword(myForm: FormGroup) {
    const md5 = new Md5();
    myForm.value.passwordAttuale = md5.appendStr(myForm.value.passwordAttuale).end();

    const md52 = new Md5();
    myForm.value.passwordNuova = md52.appendStr(myForm.value.passwordNuova).end();
    delete myForm.value.passwordRipeti;

    return this.http.post<any>(this.baseUrl + 'modificaPassword/' , myForm.value)
    .pipe(map(data => {
      return data;
    }));
  }


  updateAnagrafica(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.baseUrl + 'updateAnagrafica/' , body)
    .pipe(map(data => {
      this.setUserBasicData(data.user);
      return data;
    }));
  }


  updateSchedaPersonale(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.baseUrl + 'updateSchedaPersonale/' , body)
    .pipe(map(data => {
      return data;
    }));
  }


  getSchedaPersonale(): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + 'getSchedaPersonale/');
  }





  finalizePayment(data: any, paymentData: any) {
    return this.http.post<any>(this.baseUrl + 'finalizePayment/' , {data, paymentData})
    .pipe(map(data => {
      return data;
    }));
  }
*/
}
