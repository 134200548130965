import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssegnaProdottiOpzioni } from '../../common/assegna-prodotti/assegna-prodotti-opzioni';
import { MuseiService } from '../musei.service';

@Component({
  selector: 'app-musei-prodotti',
  templateUrl: './../../common/assegna-prodotti/main-assegna-prodotti.component.html',
  styleUrls: ['./musei-prodotti.component.scss']
})
export class MuseiProdottiComponent implements OnInit {
  options: AssegnaProdottiOpzioni
  azione: string = '';

  constructor(
    private route: ActivatedRoute,
    private service: MuseiService
  ) {
    this.options = new AssegnaProdottiOpzioni;
  }


  ngOnInit(): void {
    this.route.params.subscribe(
      (params) => {
        this.options.idPadre = params.idPadre;
        this.options.backUrl = "/musei/lastItem/"+this.options.idPadre;
        this.options.tipologia = "musei";

        if (params.id) {
          this.service.getIdAssegnato(params.id, params.idPadre, this.options.tipologia)
          .subscribe(
            (data) => {
              this.options.lastId = data;
              this.setAzione(params);
          });
        }
        else {
          this.setAzione(params);
        }
      }
    );
  }

  setAzione(params: any) {
    this.azione = params.azione;
  }

}
