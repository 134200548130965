import { Component, OnInit } from '@angular/core';
import { ComponentsService } from 'src/app/components/component.service';
import { CategorieProdottiService } from '../categorie-prodotti.service';
import { SottocategorieProdottiService } from '../sottocategorie-prodotti.service';

@Component({
  selector: 'app-prodotti-categorie',
  templateUrl: './prodotti-categorie.component.html',
  styleUrls: ['./prodotti-categorie.component.scss']
})
export class ProdottiCategorieComponent implements OnInit {
  mostraScheda: boolean;
  myService: any;
  idCategoria: number;
  backUrl: string;

  constructor(
    private componentsService: ComponentsService,
    private categorieProdottiService: CategorieProdottiService,
    private sottocategorieProdottiService: SottocategorieProdottiService
  ) {
    let urlArray = this.componentsService.getActualRouteArray();
    if (urlArray[4] && (urlArray[4] == 'sottocategorie')) {
      this.mostraScheda = (urlArray[5] && (+urlArray[5] === parseInt(urlArray[5]))) ? true: false;
      this.myService = this.sottocategorieProdottiService;
      this.myService.setIdCategoria(+urlArray[3]);
      this.backUrl = this.mostraScheda ? '/prodotti/categorie/'+urlArray[3]+'/sottocategorie' : '/prodotti/categorie';
      this.idCategoria = +urlArray[3];
    }
    else {
      this.mostraScheda = (urlArray[3] && (+urlArray[3] === parseInt(urlArray[3]))) ? true: false;
      this.myService = this.categorieProdottiService;
      this.backUrl = this.mostraScheda ? '/prodotti/categorie' : '/prodotti';
      this.idCategoria = 0;
    }
  }

  ngOnInit(): void {
  }
}
