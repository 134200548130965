import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { ButtonService } from 'src/app/components/buttons/button.service';
import { ElencoComponent } from 'src/app/components/elenco/elenco.component';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { OrdiniService } from '../ordini.service';

@Component({
  selector: 'app-ordini-elenco',
  templateUrl: './ordini-elenco.component.html',
  styleUrls: ['./ordini-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrdiniElencoComponent implements OnInit {
  @ViewChild(ElencoComponent) child:ElencoComponent;

  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;
  mostraFiltriDedicati: boolean = false;
  arrayPulsanti: any[any];
  arrayCampi: any[any];
  myForm: FormGroup;
  arrayAnagrafica: any[any];
  arrayStatiOrdine: any[any];
  openPagamentoIdOrdine: number = -1;
  openSpedizioneIdOrdine: number = -1;
  openVisualizzaIdOrdine: number = -1;
  openResoIdOrdine: number = -1;
  filtraPerAnagrafica: number;

  constructor(
    private service: OrdiniService,
    private route: ActivatedRoute,
    private formComponentsService: FormInputService,
    private notificationsService: NotificationsService,
    private buttonService: ButtonService,
    private mainEngineService: MainEngineService,
  ) {
    let that = this;

    this.filtraPerAnagrafica = this.service.filtraPerAnagrafica;

    this.arrayPulsanti = {
      'cerca' : {'type' : 'cerca', 'ML' : true},
      'reset' : {'type' : 'reset'},
    };

    forkJoin([
      this.service.getElencoAnagraficaOrdini(),
      this.service.getElencoStatiOrdine()
    ])
    .subscribe(
      ([arrayAnagrafica, arrayStatiOrdine]) => {
        this.arrayAnagrafica = arrayAnagrafica.items;
        this.arrayStatiOrdine = arrayStatiOrdine;

        this.arrayCampi = {
          'periodo' : {'id' : 'periodo', 'nome': 'Periodo', 'type': 'PER', 'required': false},
          'idOrdine' : {'id' : 'idOrdine', 'nome': 'Numero ordine'},
          'idAnagrafica' : {'id' : 'idAnagrafica', 'nome': 'Anagrafica'},
          'stato' : {'id' : 'stato', 'nome': 'Stato', 'items': this.arrayStatiOrdine,},
        };

        this.myForm = this.formComponentsService.createForm(this.arrayCampi);
        this.onReset();
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });

    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'anagrafica', header: 'Anagrafica',    cell: (element: any) => `${element.anagrafica}` },
          { columnDef: 'dataOrdine', header: 'Data ordine',    cell: (element: any) => `${element.dataOrdine}` },
          { columnDef: 'totale', header: 'Totale', currency: 'EUR',    cell: (element: any) => `${element.totale}` },
          { columnDef: 'numArticoli', header: 'N. art.',    cell: (element: any) => `${element.numArticoli}` },
          { columnDef: 'pagamento', header: 'Pagamento',    cell: (element: any) => `${element.pagamento}` },
          { columnDef: 'spedizione', header: 'Spedizione',    cell: (element: any) => `${element.spedizione}` },
          { columnDef: 'stato', header: 'Stato',    cell: (element: any) => `${element.stato}` },
        ];
        this.options = {
          linkBase: this.myService.linkBase
          ,filtriDedicati: this.showFiltriDedicati.bind(this)
          ,customButtons: {
            a1_pagamento: {
              type: 'custom',
              testo: '',
              colorClass: 'info',
              icon: 'mdi mdi-currency-eur',
              actionTooltip: 'Gestione pagamento',
              onClickFunction: this.gestionePagamento.bind(this),
              condizione: 'pulsanteModificaPagamento',
              thisPointer: that,
              hideText: true,
              ML: true
            },
            a1_annullaPagamento: {
              type: 'custom',
              testo: '',
              colorClass: 'danger',
              icon: 'mdi mdi-currency-eur-off',
              actionTooltip: 'Annulla pagamento',
              onClickFunction: this.annullaPagamento.bind(this),
              condizione: 'pulsanteAnnullaPagamento',
              thisPointer: that,
              hideText: true,
              ML: true
            },
            a2_spedizione: {
              type: 'custom',
              testo: '',
              colorClass: 'info',
              icon: 'mdi mdi-earth-arrow-right',
              actionTooltip: 'Gestione spedizione',
              onClickFunction: this.gestioneSpedizione.bind(this),
              condizione: 'pulsanteModificaSpedizione',
              thisPointer: that,
              hideText: true,
              ML: true
            },
            a2_annullaSpedizione: {
              type: 'custom',
              testo: '',
              colorClass: 'danger',
              icon: 'mdi mdi-earth-off',
              actionTooltip: 'Annulla spedizione',
              onClickFunction: this.annullaSpedizione.bind(this),
              condizione: 'pulsanteAnnullaSpedizione',
              thisPointer: that,
              hideText: true,
              ML: true
            },
            a0_resoNonAvviato: {
              type: 'custom',
              testo: '',
              colorClass: 'secondary',
              icon: 'mdi mdi-backspace-outline',
              actionTooltip: 'Registra reso',
              onClickFunction: this.gestioneReso.bind(this),
              condizione: 'pulsanteGestioneResoNonAvviato',
              thisPointer: that,
              hideText: true,
              ML: true
            },
            a0_resoAvviato: {
              type: 'custom',
              testo: '',
              colorClass: 'warning',
              icon: 'mdi mdi-backspace-outline',
              actionTooltip: 'Modifica/Annulla reso',
              onClickFunction: this.gestioneReso.bind(this),
              condizione: 'pulsanteGestioneResoAvviato',
              thisPointer: that,
              hideText: true,
              ML: true
            },
            a4_visualizza: {
              type: 'custom',
              testo: 'Visual.',
              colorClass: 'info',
              icon: 'mdi mdi-eye',
              actionTooltip: 'Visualizza ordine',
              onClickFunction: this.visualizzaOrdine.bind(this),
              thisPointer: that,
              hideText: true,
              ML: true
            },
          }
        }
      });
  }

  ngOnInit(): void {
  }

  gestionePagamento(idOrdine) {
    this.openPagamentoIdOrdine = idOrdine;
  }

  annullaPagamento(idOrdine: number) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        this.loading = true;
        that.service.annullaPagamento(idOrdine)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.reloadElenco();
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenerico(obj, idOrdine);
  }


  annullaSpedizione(idOrdine: number) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        this.loading = true;
        that.service.annullaSpedizione(idOrdine)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.reloadElenco();
          },
          (error) => {
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaGenerico(obj, idOrdine);
  }

  reloadElenco() {
    this.child.getElenco();
  }

  gestioneSpedizione(idOrdine) {
    this.openSpedizioneIdOrdine = idOrdine;
  }

  gestioneReso(idOrdine) {
    this.openResoIdOrdine = idOrdine;
  }

  visualizzaOrdine(idOrdine) {
    this.openVisualizzaIdOrdine = idOrdine;
  }

  resetOpenIdOrdine(reload: boolean = false) {
    this.openPagamentoIdOrdine = -1;
    this.openSpedizioneIdOrdine = -1;
    this.openVisualizzaIdOrdine = -1;
    this.openResoIdOrdine = -1;

    if (reload) {
      this.reloadElenco();
    }
  }

  showFiltriDedicati() {
    this.mostraFiltriDedicati = true;
    this.myForm.patchValue(this.myService.getParametriRicerca());
  }


  onBack() {
    this.mostraFiltriDedicati = false;
  }

  onSearch() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      this.notificationsService.show('warning', '', 'Compilare tutti i campi obbligatori');
      return false;
    }

    this.myService.setParametriRicerca(this.myForm.getRawValue());
    this.onReset();
  }

  onReset() {
    this.myForm.reset();
    this.myForm.patchValue({ 'stato': 'ALL' });
    this.myForm.patchValue({ 'idAnagrafica': this.service.filtraPerAnagrafica });

    if (this.service.filtraPerAnagrafica != 0) {
      this.service.filtraPerAnagrafica = 0;
      this.onSearch();
    }
    else {
      this.service.keepBackupRicerca = false;
      this.filtraPerAnagrafica = 0;
      this.mostraFiltriDedicati = false;
    }
  }
}
