import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MetodiPagamentoService } from '../metodi-pagamento.service';

@Component({
  selector: 'app-metodi-pagamento-elenco',
  templateUrl: './metodi-pagamento-elenco.component.html',
  styleUrls: ['./metodi-pagamento-elenco.component.scss']
})
export class MetodiPagamentoElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;

  constructor(
    private service: MetodiPagamentoService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'denominazione', header: 'Denominazione',    cell: (element: any) => `${element.titolo}` },
        ];
        this.options = {
          linkBase: this.myService.linkBase
        }
      });
  }

  ngOnInit(): void {
  }

}
