import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RoutesRecognized } from '@angular/router';
import { fromEvent, Observable } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { ReservedAccessService } from './components/reservedAccess/reserved-access.service';
import { MainEngineService } from './service/main-engine.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  private _router: Subscription;
  $resizeObservable: Observable<Event>;
  $resizeSubscription: Subscription;

  constructor(
    private router: Router ,
    private mainEngineService: MainEngineService,
    private reservedAccessService: ReservedAccessService
    ) {
  }

  triggerResize() {
    const w = document.getElementById('myBody').offsetWidth;
    if (w < 1600) {
      document.body.classList.add('sidebar-mini');
    } else {
      document.body.classList.remove('sidebar-mini');
    }
    this.mainEngineService.setScreenW$(w);
/*
    if (w < 480) {
      $("button.btn").each(function( index ) {
        $(this).addClass("btn-sm");
      });
    }
    else {
      $("button.btn").each(function( index ) {
        $(this).removeClass("btn-sm");
      });
    }
*/
  }


    ngOnInit() {
      this.$resizeObservable = fromEvent(window, 'resize');
      this.$resizeSubscription = this.$resizeObservable.subscribe( evt => {
        this.triggerResize();
      });

      this.reservedAccessService.getUserBasicData();

      this.mainEngineService.getConfigParams()
      .subscribe(data => {
        this.mainEngineService.configParamsSubject.next(data);
      });

      this.router.events.subscribe((url: any) => (
        this.mainEngineService.onRouteChange(url.url)
       ));

       /*
      this.router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.mainEngineService.onRouteChange(events)
      });
      */
    }

    ngAfterViewChecked() {
      this.triggerResize();
    }

}
