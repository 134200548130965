import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ButtonService } from '../buttons/button.service';
import { ElencoService } from './elenco.service';
import { OpzioniElenco } from './opzioni-elenco';
import { ComponentsService } from '../component.service';
import { FormInputService } from '../scheda/formInput/form-input.service';

@Component({
  selector: 'app-elenco',
  templateUrl: './elenco.component.html',
  styleUrls: ['./elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ElencoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  arrayPulsanti: any[any];
  displayedColumns: any[];
  numEvidenza: number;
  numEvidenza_pp: number;
  numEvidenza_sp: number;
  nomeSezione: string;
  direction: string;
  active: string;
  smallTable: boolean = false;

  opzioni: OpzioniElenco;
  dataSource: MatTableDataSource<any>;
  pulsanteNuovo: string = '';
  pulsanteCategoria: string = '';
  testataButtons: string[];
  rowButtons: string[];
  numRowButtons: number = 0;
  filtriDedicati: any;

  @Input() columns: any[any];
  @Input() options: any[any];
  @Input() myService;
  @Input() lastId: number = 0;
  @Input() smartTable: boolean = false; // tabella senza paginazione e ricerca
  @Input() backUrl: string = '';
  @Output() onReloadEmitter = new EventEmitter();

  public isNaN = isNaN;

  constructor(
    private componentsService: ComponentsService,
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private buttonService: ButtonService,
    private router: Router,
    private elencoService: ElencoService
  ) {

    this.arrayPulsanti = {
      'edit' : {'type' : 'edit', 'hideText': true, 'ML' : true},
      'delete' : {'type' : 'delete', 'hideText': true, 'ML' : true},
      'cantDelete' : {'type' : 'cantDelete', 'hideText': true, 'ML' : true},
      'visible' : {'type' : 'visible'},
      'attivo' : {'type' : 'attivo'},
      'evidenza' : {'type' : 'evidenza'},
      'cerca' : {'type' : 'cerca', 'testo' : 'Filtri avanzati', 'hideText' : true, 'maxContent' : true},
      'fotogallery' : {'type' : 'custom', 'testo' : 'Gallery', 'colorClass' : 'info', 'icon': 'dripicons-photo-group', 'actionTooltip' : 'Gestione fotogallery', 'hideText': true, 'ML' : true},
    };

    let urlArray = this.componentsService.getActualRouteArray();
    this.nomeSezione = urlArray[1];

  }

  ngOnInit(): void {
    this.displayedColumns = this.columns.map(c => c.columnDef);

    if (!this.lastId) {
      this.resetParametriTabella();
    }

    this.pulsanteNuovo = ((this.options.nascondiPulsanteNuovo == undefined) || !this.options.nascondiPulsanteNuovo) ? this.options.linkBase+'/0' : '';
    this.testataButtons = (this.options.testataButtons != undefined) ? this.options.testataButtons : '';
    this.rowButtons = (this.options.rowButtons != undefined) ? this.options.rowButtons : [];
    this.filtriDedicati = (this.options.filtriDedicati != undefined) ? this.options.filtriDedicati : '';
    this.numRowButtons = Object.keys(this.rowButtons).length;
    this.getElenco();

    this.mainEngineService.getScreenW$().subscribe((value) =>  {
      if (value < 1000) {
        this.smallTable = true;
      }
      else {
        this.smallTable = false;
      }
    });
  }

  resetElenco() {
    this.lastId = 0;
    this.dataSource = null;

    this.onReloadEmitter.emit(null);
    this.ngOnInit();
    return;
  }

  resetParametriTabella() {
    if (this.smartTable) {
      return;
    }
    this.elencoService.setSearchString(this.myService, '');
    this.elencoService.setCurrentePage(this.myService, 0);
    this.elencoService.setScrollPosition(this.myService, 0);
    this.elencoService.setPageSize(this.myService, 0);

  }

  getElenco(showLoader = true) {
    if (showLoader) {
      this.formComponentsService.activateLoader(true);
    }
    $("#ricercaElenco").val("");
    this.getOpzioni();
    this.myService.getElenco()
    .subscribe(
      data => {
        this.dataSource = new MatTableDataSource(data.items);
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) => (typeof data[sortHeaderId] === 'string' || data[sortHeaderId] instanceof String) ? data[sortHeaderId]?.toLocaleLowerCase() : data[sortHeaderId]; // per ordinare senza case sensitive, ma solo se è una stringa

        this.numEvidenza = 0;

        this.dataSource.data.forEach( (element, index) => {
          if (element.type == undefined) {
            this.dataSource.data[index].type='def';
          }
          if (element.evidenza) {
            this.numEvidenza++;
          }
          if (element.evidenza_pp) {
            this.numEvidenza_pp++;
          }
          if (element.evidenza_sp) {
            this.numEvidenza_sp++;
          }
        });

        if (this.lastId && !this.smartTable) {
          $("#ricercaElenco").val(this.elencoService.getSearchString(this.myService));
          this.applyFilter(this.elencoService.getSearchString(this.myService));
          this.paginator.pageIndex = this.elencoService.getCurrentePage(this.myService);
          this.paginator.pageSize = this.elencoService.getPageSize(this.myService);
          let dummy: any = null;
          if (dummy = this.elencoService.getSortOrder(this.myService)) {
            this.direction = dummy.direction;
            this.active = dummy.active;
          }
          setTimeout(() => {
            document.querySelector(".mat-table").scrollTop = this.elencoService.getScrollPosition(this.myService);
          }, 100);
        }
        this.dataSource.paginator = this.paginator;

        setTimeout(() => {
          this.dataSource.sort = this.sort;
        }, 100);

        this.componentsService.tableFitHeight();


        if (showLoader) {
          this.formComponentsService.activateLoader(false);
        }
      },
      (error) => {
        if (showLoader) {
          this.formComponentsService.activateLoader(false);
        }
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  onPaginateChange(event) {
/*
length: 548
pageIndex: 2
pageSize: 10
previousPageIndex: 1
*/
    document.querySelector(".mat-table").scrollTop = 0;
    this.elencoService.setCurrentePage(this.myService, event.pageIndex);
    this.elencoService.setPageSize(this.myService, event.pageSize);
  }

  getOpzioni() {
    this.myService.getOpzioni()
    .subscribe(
      (data: OpzioniElenco) => {
      if (!data.nascondiPulsanteModifica) {
        data.nascondiPulsanteModifica = false;
      }
      if (!data.evidenza) {
        data.evidenza = 0;
      }
      if (!data.evidenza_pp) {
        data.evidenza_pp = 0;
      }
      if (!data.evidenza_sp) {
        data.evidenza_sp = 0;
      }
      this.opzioni = data;

      this.pulsanteCategoria = this.opzioni.categorie ? this.options.linkBase + '/categorie' : "";

      this.initTable();
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  saveScrollPosition() {
    if (!this.smartTable) {
      this.elencoService.setScrollPosition(this.myService, document.querySelector(".mat-table").scrollTop);
    }
  }

  goToDetail(id) {
    this.saveScrollPosition();
    this.router.navigateByUrl('/' + this.options.linkBase + '/' + id);
  }


  goToGallery(id) {
    this.saveScrollPosition();
    this.router.navigateByUrl('/' + this.options.linkBase + '/' + id + '/fotogallery');
  }


  onDelete(id) {
    const that = this;
    const obj = {
      onSuccess(arg) {
        this.loading = true;
        that.myService.deleteItem(arg)
        .pipe(first())
        .subscribe(
          (response) => {
            that.notificationsService.show('not_OpOk');
            that.getElenco();
          },
          (error) => {
            this.loading = false;
            that.mainEngineService.errorHandler(error);
          }
        );

      }
    };

    this.buttonService.confermaElimina(obj, id);
  }

  toggleVisible(id) {
    this.formComponentsService.activateLoader(true);

    this.myService.toggleVisibile(id)
    .pipe(first())
    .subscribe(
      (response) => {
        this.getElenco(true);
//        this.formComponentsService.activateLoader(false);
      },
      (error) => {
        this.getElenco(true);
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  toggleAttivo(id) {
    this.formComponentsService.activateLoader(true);

    this.myService.toggleAttivo(id)
    .pipe(first())
    .subscribe(
      (response) => {
        this.getElenco(false);
        this.formComponentsService.activateLoader(false);
      },
      (error) => {
        this.getElenco(false);
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  toggleEvidenza(item) {
    if ((typeof this.myService.toggleVisibile !== undefined) && this.opzioni.visibile && !item.visibile) {
      this.notificationsService.show('warning', 'Operazione non valida', 'Non è possibile mettere in evidenza un elemento non visibile');
      return;
    }

    this.formComponentsService.activateLoader(true);

    this.myService.toggleEvidenza(item.id)
    .pipe(first())
    .subscribe(
      (response) => {
        this.getElenco(false);
        this.formComponentsService.activateLoader(false);
      },
      (error) => {
        this.getElenco(false);
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  toggleEvidenza_pp(id) {
    this.formComponentsService.activateLoader(true);

    this.myService.toggleEvidenza_pp(id)
    .pipe(first())
    .subscribe(
      (response) => {
        this.getElenco(false);
        this.formComponentsService.activateLoader(false);
      },
      (error) => {
        this.getElenco(false);
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  toggleEvidenza_sp(id) {
    this.formComponentsService.activateLoader(true);

    this.myService.toggleEvidenza_sp(id)
    .pipe(first())
    .subscribe(
      (response) => {
        this.getElenco(false);
        this.formComponentsService.activateLoader(false);
      },
      (error) => {
        this.getElenco(false);
        this.mainEngineService.errorHandler(error);
      }
    );
  }


  changePosition(id, newPos) {
    this.formComponentsService.activateLoader(true);

    this.myService.changePosition(id, newPos)
    .pipe(first())
    .subscribe(
      (response) => {
        this.getElenco(false);
        this.formComponentsService.activateLoader(false);
      },
      (error) => {
        this.getElenco(false);
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    this.componentsService.tableFitHeight();
  }


  initTable() {
    if (!this.opzioni) {
      return;
    }
    if ((!this.opzioni.nascondiColonnaId) && (this.displayedColumns.indexOf("id") == -1)) {
      this.displayedColumns.unshift('id');
    }
    if (this.opzioni.ordine && (this.opzioni.ordine != 0) && (this.displayedColumns.indexOf("ordine") == -1)) {
      this.displayedColumns.push('ordine');
    }
    if (((typeof this.myService.toggleVisibile !== undefined) && this.opzioni.visibile) && (this.displayedColumns.indexOf("visibile") == -1)) {
      this.displayedColumns.push('visibile');
    }
    if (((typeof this.myService.toggleAttivo !== undefined) && this.opzioni.attivo) && (this.displayedColumns.indexOf("attivo") == -1)) {
      this.displayedColumns.push('attivo');
    }
    if (((typeof this.myService.toggleEvidenza !== undefined) && this.opzioni.evidenza) && (this.displayedColumns.indexOf("evidenza") == -1)) {
      this.displayedColumns.push('evidenza');
    }
    if (((typeof this.myService.toggleEvidenza_pp !== undefined) && this.opzioni.evidenza_pp) && (this.displayedColumns.indexOf("evidenza_pp") == -1)) {
      this.displayedColumns.push('evidenza_pp');
    }
    if (((typeof this.myService.toggleEvidenza_sp !== undefined) && this.opzioni.evidenza_sp) && (this.displayedColumns.indexOf("evidenza_sp") == -1)) {
      this.displayedColumns.push('evidenza_sp');
    }
    if ((this.displayedColumns.indexOf("actions") == -1) && (this.options.noAzioni !== true)) {
      this.displayedColumns.push('actions');
    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    this.elencoService.setSearchString(this.myService, filterValue);
  }

  sortChange(event: any) {
    this.elencoService.setSortOrder(this.myService, event);
    this.paginator.pageIndex = 0;
  }

  onBack() {
    this.router.navigate([this.backUrl]);
  }

  dotToComma(string: string): string {
    return string.replace(".", ",");
  }

  data2EU(d: string): string {
    if ((d.trim() == "") || (d == "0000-00-00")) {
       return "";
    }
    if (d.indexOf("-") < 0) {
      return d;
    }
	  let e = d.split("-");
  	return e[2]+"/"+e[1]+"/"+e[0];
  }
}
