import { Component, Input, OnInit, Output, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { OrdiniService } from '../../ordini.service';

@Component({
  selector: 'app-modal-visualizza',
  templateUrl: './modal-visualizza.component.html',
  styleUrls: ['./modal-visualizza.component.scss']
})
export class ModalVisualizzaComponent implements OnInit {
  @ViewChild('modal_visualizzaOrdine') modal_visualizzaOrdine: any;
  @Input() openIdOrdine: number;
  @Output() resetOpenIdOrdine = new EventEmitter();

  modalReference: any;
  riassuntoOrdine: string = "";
  waitModalContent: boolean = true;

  constructor(
    private service: OrdiniService,
    private mainEngineService: MainEngineService,
    private modalService: NgbModal,
    private router: Router,
    ) {
  }

  ngOnInit(): void {
  }

  onCancel() {
    this.waitModalContent = true;
    this.resetOpenIdOrdine.emit(null);
    this.modalReference.close();
  }

  visualizzaOrdine() {
    if (!this.openIdOrdine) {
      return;
    }
    this.modalReference = this.modalService.open(this.modal_visualizzaOrdine, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});
    this.service.getRiassuntoOrdine(this.openIdOrdine)
    .subscribe(data => {
      this.riassuntoOrdine = data;
      this.waitModalContent = false;
    },
    (error) => {
      this.mainEngineService.errorHandler(error);
    });
  }


  public ngOnChanges(changes: SimpleChanges): void {
    if ('openIdOrdine' in changes) {
      if (this.openIdOrdine > -1) {
        this.visualizzaOrdine();
      }
    }
  }
}


