import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { CampiSeoService } from 'src/app/components/scheda/campi-seo/campi-seo.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { MuseiService } from '../musei.service';
import { Museo } from '../museo';

@Component({
  selector: 'app-musei-dettaglio',
  templateUrl: './musei-dettaglio.component.html',
  styleUrls: ['./musei-dettaglio.component.scss']
})
export class MuseiDettaglioComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: Museo;
  titoloScheda: string = "Caricamento...";
  backUrl = '';
  $shopMinore: BehaviorSubject<boolean>;

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private museiService: MuseiService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private campiSeoService: CampiSeoService,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'shopMinore' : {'id' : 'shopMinore', 'nome': 'Shop minore', 'type': 'BOL0', 'required': false},
      'titolo' : {'id' : 'titolo', 'nome': 'Titolo', 'required': true},
      'sottotitolo' : {'id' : 'sottotitolo', 'nome': 'Sottotitolo'},
      'immagine' : {'id' : 'immagine', 'nome': 'Immagine', 'immagineAttuale': '', 'type': 'IMG', 'required': true},
      'gps' : {'id' : 'gps', 'nome': 'Coordinate GPS', 'type': 'GPS'},
      'citta' : {'id' : 'citta', 'nome': 'Città', 'required': true},
/*
      'indirizzo' : {'id' : 'indirizzo', 'nome': 'Indirizzo'},
      'telefono' : {'id' : 'telefono', 'nome': 'Telefono', 'type': 'NUM'},
      'email' : {'id' : 'email', 'nome': 'Email', 'type': 'EML'},
*/
      'recapiti' : {'id' : 'recapiti', 'nome': 'Recapiti (indirizzo, tel, email...)', 'type': 'TXA'},
      'orariApertura' : {'id' : 'orariApertura', 'nome': 'Orari di apertura', 'type': 'TXA'},
      'link' : {'id' : 'link', 'nome': 'Link', 'type': 'URL'},
      'testoLibero' : {'id' : 'testoLibero', 'nome': 'Testo libero', 'type': 'RTX'},
      'immagineTestoLibero' : {'id' : 'immagineTestoLibero', 'nome': 'Immagine testo libero', 'immagineTestoLiberoAttuale': '', 'type': 'IMG', 'required': false},
      'dataPubblicazione' : {'id' : 'dataPubblicazione', 'nome': 'Data di pubblicazione', 'type': 'DTA', 'minToday': true},
    };

    this.arrayCampi = {...this.arrayCampi, ...this.campiSeoService.arrayCampi};

    this.$shopMinore = new BehaviorSubject(false);

  }

  ngOnInit(): void {

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.backUrl = this.museiService.linkBase;

    this.myForm.controls['shopMinore'].valueChanges.subscribe(change => {
      this.$shopMinore.next(change);

      if (change) { // shopMinore
        this.myForm.get('immagine_presente').clearValidators();
      }
      else {
        this.myForm.get('immagine_presente').setValidators([Validators.required]);
      }
      this.myForm.get('immagine_presente').updateValueAndValidity();
    });

    this.route.params.subscribe(
      (params) => {
        if (params.id != '0') {
          this.formComponentsService.activateLoader(true);
          this.backUrl += '/lastItem/' + params.id;
          this.museiService.getItem(params.id)
          .subscribe(data => {
            this.formComponentsService.activateLoader(false);
            this.myItem = data;
            this.$shopMinore.next(this.myItem.shopMinore);

            this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

            this.myForm.patchValue(this.myItem);
            this.titoloScheda = "Modifica elemento - '" + this.myItem._titoloScheda + "'";
            let that = this;
            setTimeout(function(){ that.myForm.markAsPristine(); }, 1000);

          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
          });
        }
        else {
          this.titoloScheda = "Inserimento elemento";
          this.formComponentsService.activateLoader(false);
        }
      });
  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      this.notificationsService.show('warning', '', 'Alcuni campi non sono stati compilati correttamente');
      return false;
    }

    this.loading = true;

    this.museiService.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.backUrl]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.museiService.linkBase + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel(this.backUrl);
  }

  onBack() {
    this.componentsService.onBack(this.backUrl);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }

  onSave() {
    this.onSubmit(2);
  }

  public isShopCompleto() {
    return (!this.$shopMinore.value);
  }

}
