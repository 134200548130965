import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { ProdottiService } from 'src/app/pages/prodotti/prodotti.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Articolo } from '../articolo';
import { ArticoliService } from '../articoli.service';

@Component({
  selector: 'app-modal-aggiungi-articolo',
  templateUrl: './modal-aggiungi-articolo.component.html',
  styleUrls: ['./modal-aggiungi-articolo.component.scss']
})
export class ModalAggiungiArticoloComponent implements OnInit {
  @ViewChild('modal') modal: any;
  @Input() idOrdine: number;
  @Input() openModalArticolo: number = 0;
  @Output() onSubmitEmitter = new EventEmitter<number>();
  @Output() onCancelEmitter = new EventEmitter<void>();


  arrayCampi: any[any];
  arrayVariante1: any[any];
  arrayVariante2: any[any];
  arrayAllVariante2: any[any];
  myForm: FormGroup;
  arraySottocategorie: any[any];
  numVarianti: number = 0;
  prodottoSelezionato: boolean = false;
  arrayProdotti: any[any];
  loading = false;
  modalReference: any;
  waitModalContent: boolean = true;
  elementoOrdine: Articolo
  modalTitle: string;
  restoreIdAttributo1: number = 0;
  restoreIdAttributo2: number = 0;

  constructor(
    private prodottiService: ProdottiService,
    private formComponentsService: FormInputService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private service: ArticoliService,
    private modalService: NgbModal,
    private componentsService: ComponentsService
  ) {
    this.arrayCampi = {
      'id' : {'id' : 'id'},
      'idOrdine' : {'id' : 'idOrdine'},
      'action' : {'id' : 'action'},
      'idSottocategoria' : {'id' : 'idSottocategoria', 'nome': 'Filtra per categoria', 'required': false, 'onSelect': this.getListProdotti.bind(this)},
      'idProdotto' : {'id' : 'idProdotto', 'nome': 'Prodotto', 'required': true, 'onSelect': this.onProdottoSelezionato.bind(this), 'type': 'NUM'},
      'idAttributo1' : {'id' : 'idAttributo1', 'nome': 'Variante 1', 'required': true, 'onSelect': this.onVariante1Selezionata.bind(this), 'type': 'NUM'},
      'idAttributo2' : {'id' : 'idAttributo2', 'nome': 'Variante 2', 'required': true, 'onSelect': this.onVariante2Selezionata.bind(this), 'note' : "Selezionare prima un valore dalla tendina precedente", 'type': 'NUM'},
      'quantita' : {'id' : 'quantita', 'nome': 'Quantità', 'type': 'NUM', 'required': true, 'note' : 'Il sistema non controlla se la quantità richiesta è superiore alla disponibilità residua'},
    };

  }

  ngOnInit(): void {
  }


  openModal() {
    this.modalReference = this.modalService.open(this.modal, { size: 'xl', windowClass: 'modal-holder', centered: false, backdrop: 'static'});

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.myForm.patchValue({ 'id': this.openModalArticolo });
    this.myForm.patchValue({ 'idOrdine': this.idOrdine });
    this.myForm.patchValue({ 'action': 'aggiungiArticolo' });

    if (this.openModalArticolo == 0) {
      this.modalTitle = "Aggiunta articolo";
      this.prodottiService.getElencoSottocategorie()
      .subscribe(data => {
        this.arraySottocategorie = data.items;
        this.getListProdotti().then(
          (val) => {
            this.waitModalContent = false;
          }
        );
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });
    }
    else {
      this.modalTitle = "Modifica articolo";
      this.service.getItem(this.openModalArticolo)
      .subscribe(data => {
        this.elementoOrdine = data;
        if (data.idAttributo1) {
          this.restoreIdAttributo1 = data.idAttributo1;
          if (data.idAttributo2) {
            this.restoreIdAttributo2 = data.idAttributo2;
          }
        }
        this.myForm.patchValue({ 'quantita': data.quantita });
        this.waitModalContent = false;
        this.myForm.patchValue({ 'idProdotto': data.idProdotto });
        this.onProdottoSelezionato(data.idProdotto).then(
          (val) => {
            if (this.restoreIdAttributo1) {
              this.myForm.patchValue({ 'idAttributo1': this.restoreIdAttributo1 });
              this.restoreIdAttributo1 = 0;
            }
          }
        );
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });
    }
  }

  onVariante1Selezionata(idItem) {
    this.arrayVariante2 = this.arrayAllVariante2[idItem];
    if (this.restoreIdAttributo2) {
      this.myForm.patchValue({ 'idAttributo2': this.restoreIdAttributo2 });
      this.restoreIdAttributo2 = 0;
    }
  }

  onVariante2Selezionata(idItem) {
  }


  onProdottoSelezionato(idItem: number = 0) {
    if (!idItem) {
      idItem = 0;
    }

    if (idItem) {
      var promise = new Promise((resolve, reject) => {
        this.prodottiService.getProdottoPerOrdine(idItem)
        .subscribe(data => {
          this.numVarianti = data.numAttributi;
          this.myForm.get('idAttributo1').clearValidators();
          this.myForm.get('idAttributo2').clearValidators();
          this.myForm.patchValue({ 'idAttributo1': 0 });
          this.myForm.patchValue({ 'idAttributo2': 0 });
          if (this.numVarianti > 0) {
            this.arrayCampi['idAttributo1'].nome = data.attributi[1].titolo;
            this.arrayVariante1 = data.attributi[1].elementi;
            this.myForm.get('idAttributo1').setValidators([Validators.required]);
            if (this.numVarianti > 1) {
              this.arrayCampi['idAttributo2'].nome = data.attributi[2].titolo;
              this.arrayAllVariante2 = data.attributi[2].elementi;
              this.myForm.get('idAttributo2').setValidators([Validators.required]);
            }
          }
          this.prodottoSelezionato = true;
          resolve('done');
        },
        (error) => {
          this.mainEngineService.errorHandler(error);
        });
      });
      return promise;

    }
    else {
      this.prodottoSelezionato = false;
      this.numVarianti = 0;
    }
  }

  getListProdotti(idSottocategoria: number = 0) {
    if (!idSottocategoria) {
      idSottocategoria = 0;
    }
    var promise = new Promise((resolve, reject) => {
      this.prodottiService.getElencoProdottiPerOrdine(idSottocategoria)
      .subscribe(data => {
        this.arrayProdotti = data.items;
        resolve('done');
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
        reject('error');
      });
    });
    return promise;
  }

  onSubmit() {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (idOrdine) => {
        this.notificationsService.show('not_OpOk');
        this.waitModalContent = true;
        this.loading = false;
        this.modalReference.close();
        this.numVarianti = 0;
        this.onSubmitEmitter.emit(idOrdine);
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.waitModalContent = true;
    this.numVarianti = 0;
    this.onCancelEmitter.emit(null);
    this.modalReference.close();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('openModalArticolo' in changes) {
      if (this.openModalArticolo >= 0) {
        this.openModal();
      }
    }
  }


}
