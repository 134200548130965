import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ComponentsService } from 'src/app/components/component.service';
import { CampiSeoService } from 'src/app/components/scheda/campi-seo/campi-seo.service';
import { FormInputService } from 'src/app/components/scheda/formInput/form-input.service';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { NotificationsService } from 'src/app/components/service/notification.service';
import { Temporaryshop } from '../temporaryshop';
import { TemporaryshopService } from '../temporaryshop.service';

@Component({
  selector: 'app-temporaryshop-dettaglio',
  templateUrl: './temporaryshop-dettaglio.component.html',
  styleUrls: ['./temporaryshop-dettaglio.component.scss']
})
export class TemporaryshopDettaglioComponent implements OnInit {
  myForm: FormGroup;
  loading = false;
  arrayCampi: any[any];
  myItem: Temporaryshop;
  titoloScheda: string = "Caricamento...";
  backUrl = '';

  constructor(
    private router: Router,
    private formComponentsService: FormInputService,
    private service: TemporaryshopService,
    private mainEngineService: MainEngineService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private campiSeoService: CampiSeoService,
    private componentsService: ComponentsService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false; // permette il route allo stesso componente ma con query params differenti
    };

    this.arrayCampi = {
      'id' : {'id' : 'id', 'defValue' : 0, 'type': 'HID'},
      'titolo' : {'id' : 'titolo', 'nome': 'Titolo', 'required': true, 'type': 'TXA'},
      'sottotitolo' : {'id' : 'sottotitolo', 'nome': 'Sottotitolo'},
      'immagine' : {'id' : 'immagine', 'nome': 'Immagine', 'immagineAttuale': '', 'type': 'IMG', 'required': true, 'ratio': 800/500, 'minW': 800, 'minH': 500},
      'dataPubblicazione' : {'id' : 'dataPubblicazione', 'nome': 'Data di pubblicazione', 'type': 'DTA', 'minToday': true},
    };

    this.arrayCampi = {...this.arrayCampi, ...this.campiSeoService.arrayCampi};
  }

  ngOnInit(): void {

    this.myForm = this.formComponentsService.createForm(this.arrayCampi);
    this.backUrl = this.service.linkBase;

    this.route.params.subscribe(
      (params) => {
        if (params.id != '0') {
          this.formComponentsService.activateLoader(true);
          this.backUrl += '/lastItem/' + params.id;
          this.service.getItem(params.id)
          .subscribe(data => {
            this.formComponentsService.activateLoader(false);
            this.myItem = data;

            this.myItem = this.formComponentsService.patchImageField(this.myItem, this.myForm, this.arrayCampi);

            this.myForm.patchValue(this.myItem);
            this.titoloScheda = "Modifica elemento - '" + this.myItem._titoloScheda + "'";
          },
          (error) => {
            this.loading = false;
            this.mainEngineService.errorHandler(error);
          });
        }
        else {
          this.titoloScheda = "Inserimento elemento";
          this.formComponentsService.activateLoader(false);
        }
      });
  }

  onSubmit(nextAction: number = 0) {
    if (!this.myForm.valid) {
      this.formComponentsService.validateAllFormFields(this.myForm);
      this.notificationsService.show('warning', '', 'Alcuni campi non sono stati compilati correttamente');
      return false;
    }

    this.loading = true;

    this.service.sendItem(this.myForm.getRawValue())
    .pipe(first())
    .subscribe(
      (response) => {
        this.notificationsService.show('not_OpOk');
        switch(nextAction) {
          case 0: // vai ad elenco
            this.router.navigate([this.backUrl]);
            break;

          case 1: // vai a nuovo
            this.router.navigate([this.service.linkBase + '/0'], {
              queryParams: {refresh: new Date().getTime()}
            });
            break;

          case 2: // no fai nulla
            this.myForm.controls.id.setValue(response);
            this.myForm.markAsPristine();
            this.loading = false;
            break;
        }
      },
      (error) => {
        this.loading = false;
        this.mainEngineService.errorHandler(error);
      }
    );
  }

  onCancel() {
    this.componentsService.onCancel(this.backUrl);
  }

  onBack() {
    this.componentsService.onBack(this.backUrl);
  }

  onSubmitNew() {
    this.onSubmit(1);
  }

  onSave() {
    this.onSubmit(2);
  }
}
