import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MainEngineService } from 'src/app/service/main-engine.service';
import { Ordine } from '../../../ordine';
import { OrdiniService } from '../../../ordini.service';
import { ModalSpedizioneComponent } from '../modal-spedizione/modal-spedizione.component';

@Component({
  selector: 'app-tab-spedizione',
  templateUrl: './tab-spedizione.component.html',
  styleUrls: ['./tab-spedizione.component.scss']
})
export class TabSpedizioneComponent implements OnInit {
  @Input() ordine: Ordine;
  @Output() reloadOrder = new EventEmitter<number>();
  @ViewChild(ModalSpedizioneComponent, {static : true}) child:ModalSpedizioneComponent;

  arrayPulsanti: any[any];
  statoSpedizione: string;
  openModalId: number = -1;

  constructor(
    private mainEngineService: MainEngineService,
    private service: OrdiniService,
  ) {
    this.arrayPulsanti = {
      'modifica' : {'type' : 'edit'},
      'annulla' : {'type' : 'annulla', 'testo' : 'Annulla spedizione', 'actionTooltip' : 'Annulla la registrazione della spedizione', 'ML' : true},
    };
  }

  ngOnInit(): void {
    if (this.ordine && this.ordine.id) {
      this.service.getSpedizione(this.ordine.id)
      .subscribe(data => {
        this.statoSpedizione = data;
      },
      (error) => {
        this.mainEngineService.errorHandler(error);
      });
    }
  }

  modalForm() {
    this.openModalId = this.ordine.id;
  }


  onSubmit() {
    this.onCancel();
    this.ngOnInit();
    this.reloadOrder.emit(this.ordine.id);
  }

  onCancel() {
    this.openModalId = -1;
  }


  resetOpenModal() {
    this.openModalId = -1;
  }

  annullaSpedizione() {
    this.child.annullaSpedizione(this.ordine.id);
  }

}
