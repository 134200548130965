import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Categoria } from 'src/app/components/elenco/categorie/categoria';
import { OpzioniCategoria } from 'src/app/components/elenco/categorie/opzioni-categoria';
import { MainEngineService } from 'src/app/service/main-engine.service';


@Injectable({
  providedIn: 'root'
})
export class SottocategorieProdottiService {
  linkBase: string = "";
  apiUrl: string;
  idCategoria: number;
  $sortOrder: BehaviorSubject<any>;
  $currentPage: BehaviorSubject<number>;
  $pageSize: BehaviorSubject<number>;
  $scrollPosition: BehaviorSubject<number>;
  $searchString: BehaviorSubject<string>;

  constructor(
    private http: HttpClient,
    private mainEngineService: MainEngineService
  ) {
    this.apiUrl = this.mainEngineService.baseUrl + 'sottocategorieProdotti/';
    this.$sortOrder = new BehaviorSubject<any>(null);
    this.$currentPage = new BehaviorSubject<number>(0);
    this.$pageSize = new BehaviorSubject<number>(0);
    this.$scrollPosition = new BehaviorSubject<number>(0);
    this.$searchString = new BehaviorSubject<string>('');
  }

  setIdCategoria(idCategoria: number) {
    this.idCategoria = idCategoria;
    this.linkBase = "prodotti/categorie/"+idCategoria+"/sottocategorie";
  }

  getElenco(): Observable<Categoria[]> {
    return this.http.get<Categoria[]>(this.apiUrl + this.idCategoria);
  }

  getOpzioni(): Observable<OpzioniCategoria> {
    return this.http.get<OpzioniCategoria>(this.apiUrl + this.idCategoria + '/op/opzioni');
  }

  getItem(id: number): Observable<Categoria> {
    return this.http.get<Categoria>(this.apiUrl + this.idCategoria + '/' + id)
  }

  sendItem(formValue: any[]) {
    const body = formValue;

    return this.http.post<any>(this.apiUrl , body);
  }

  changePosition(id: number, newPos: number) {
    const tipologia = 'posizione';
    return this.http.patch<any>(this.apiUrl + this.idCategoria + '/' + id, {tipologia, newPos})
  }

  deleteItem(id: number) {
    return this.http.delete<any>(this.apiUrl + this.idCategoria + '/' + id)
  }
}
