import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FotogalleryOptions } from 'src/app/components/fotogallery/fotogallery-options';
import { MainEngineService } from 'src/app/service/main-engine.service';

@Component({
  selector: 'app-musei-fotogallery',
  templateUrl: './musei-fotogallery.component.html',
  styleUrls: ['./musei-fotogallery.component.scss']
})
export class MuseiFotogalleryComponent implements OnInit {
  apiUrl = '';
  options: FotogalleryOptions;
  idPadre = 0;

  constructor(
    private mainEngineService: MainEngineService,
    private route: ActivatedRoute,
  ) {
    this.options = new FotogalleryOptions;
    this.apiUrl = this.mainEngineService.baseUrl + "fotogallery/musei";

    this.route.params.subscribe(
      (params) => {
        this.idPadre = params.idPadre;
        this.options.backUrl = "/musei/lastItem/"+this.idPadre;
      }
    );
  }

  ngOnInit(): void {
  }

}
