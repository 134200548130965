import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ZoneConsegnaService } from '../zone-consegna.service';


@Component({
  selector: 'app-zone-consegna-elenco',
  templateUrl: './zone-consegna-elenco.component.html',
  styleUrls: ['./zone-consegna-elenco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZoneConsegnaElencoComponent implements OnInit {
  columns: any[any];
  options: any[any];
  lastId: number = 0;
  myService: any;

  constructor(
    private service: ZoneConsegnaService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.myService = this.service;

    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.lastId = params.id;
        }

        this.columns = [
          { columnDef: 'denominazione', header: 'Denominazione',    cell: (element: any) => `${element.titolo}` },
          { columnDef: 'elencoStati', header: 'Elenco stati',    cell: (element: any) => `${element.elencoStati}` },
          { columnDef: 'tariffeAssociate', header: 'Tariffe associate',    cell: (element: any) => `${element.elementiAssociati}` },
        ];
        this.options = {
          linkBase: this.myService.linkBase,
          backUrl: "/tipologieConsegna"
        }
      });
  }

  ngOnInit(): void {
  }

}
